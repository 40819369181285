import { OnInit, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({ providedIn: 'root' })


export class DialogComponent implements OnInit {
  
  constructor() { }
  
  ngOnInit() {
  } 

  Forwardconfirmation(msg:any){
    return Swal.fire({
    title: 'Are you sure to'+' ' + msg + '?',
    text: 'After that you can not take any action on that complaint',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Change it!',
    cancelButtonText: 'No, keep it'
    }).then((result) => {
    if (result.value) {
    return true;
    // For more information about handling dismissals please visit
    // http://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
    'Cancelled',
    'Your '+ msg +' data is not modified :)',
    'error'
    )
    return false;
    }
    })
    }
  
  confirmation(msg:any){
    return Swal.fire({
    title: 'Are you sure?',
    text: 'You change in to '+msg,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Change it!',
    cancelButtonText: 'No, keep it'
    }).then((result) => {
    if (result.value) {
    return true;
    // For more information about handling dismissals please visit
    // http://sweetalert2.github.io/#handling-dismissals
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
    'Cancelled',
    'Your '+ msg +' data is not modified :)',
    'error'
    )
    return false;
    }
    })
    }


  statusChangeConfirmation(msg:any){
    return Swal.fire({
    title: 'Are you sure?',
    text: 'Do you want to change'+msg+'?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Change it!',
    cancelButtonText: 'No, keep it'
    }).then((result) => {
    if (result.value) {
    return true;
    } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
    'Cancelled',
    'Your '+ msg +' is not modified :)',
    'error'
    )
    return false;
    }
    })
    }

    EditItemPopup(msg:any){
      return Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to edit item on this complaint!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
      }).then((result) => {
      if (result.value) {
      return true;
      // For more information about handling dismissals please visit
      // http://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
      'Cancelled',
      'Your '+ msg +' data is not modified :)',
      'error'
      )
      return false;
      }
      })
      }


  delete(msg:any){
    return Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this '+msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        return true;
        // For more information about handling dismissals please visit
        // http://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your '+ msg +' is safe &#x263A;',
          'error'
          )
          return false;
          
        }
      })
    }
    
    success(title:any,msg:any){
      Swal.fire(
        title+'!',
        msg+'.',
        'success'
        )
      }
      
      error(msg:any){
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: msg,
          // footer: '<a href>Why do I have this issue?</a>'
        })
      }
      
    }
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog,MatDialogConfig } from '@angular/material';
import{ TicketActionComponent } from '../ticket-action/ticket-action.component';
import { DialogComponent } from '../../DialogComponent.js';
import {Location} from '@angular/common';

@Component({
  selector: 'app-internal-ticket-detail',
  templateUrl: './internal-ticket-detail.component.html',
  styleUrls: ['./internal-ticket-detail.component.scss']
})
export class InternalTicketDetailComponent implements OnInit {
  loader:any;
  internal_tickets: any=[];
  ecrpt_id:any = '';
  detail: any =[];
  ticket_logs: any=[];
  id: any;
  tokenInfo:any;
  remark_doc_data:any=[];
  data: any=[];
  status_remarks:any=[];
  status_log:any;

  
  constructor(public db:DatabaseService,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog: MatDialog,public dialog1:DialogComponent, private _location: Location,public dialogbox:DialogComponent) { }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
    this.status_log=0;

    this.GetInternalTicketDetail();
  }
  
  backClicked() {
    console.log( 'goBack()...' );
    this._location.back();
  }
  
  GetInternalTicketDetail()
  {
    this.loader = '1';
    this.db.FetchData({'ticket_id': this.ecrpt_id },'complaint/ticket_detail')
    .subscribe((result: any) => {
      console.log(result);
      console.log(this.ecrpt_id);
      this.data=result['data'];
      this.detail = result['data']['detail'];
      this.id=result['data']['detail']['id'];
      this.ticket_logs = result['data']['logs'];
      this.remark_doc_data = result['data']['remark_doc_data'];
      this.status_remarks = result['data']['status_remarks'];
      
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';
    });
  }
  
  openActionDialog(from) 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { ticket_id: this.detail.id,from: from,complaint_id:this.detail.complaint_id,complaint_type:this.detail.complaint_type};
    dialogConfig.width="768px"
    const dialogRef = this.dialog.open(TicketActionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
      {
        this.toast.openError('Ticket is no longer resides in your access!!','');
      }
      
      else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
      {
        if(from=='Remark')
        this.toast.openSucess('Remark Added Successfully','');
        else if(from=='ChangeStatus')
        this.toast.openSucess('Status Changed Successfully','');
        else   
        this.toast.openSucess('Successfully'+' ' +from,'');
        this.GetInternalTicketDetail();
      }
      else if(result && result['message'] && result['message']!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');
      }
    });
  }
  
  
  ticket_Reopen() 
  {
    this.dialogbox.confirmation('Status!').then((result) => {
      console.log(result);
      if(result==true)
      {
        
        const dialogConfig = new MatDialogConfig();
        
        dialogConfig.data = { ticket_id: this.detail.id,from: 'Reopen',complaint_id:this.detail.complaint_id,complaint_type:this.detail.complaint_type};
        dialogConfig.width='500px';
        const dialogRef = this.dialog.open(TicketActionComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(response => {
          console.log(`Dialog result: ${response}`);
          console.log(response);
          
          if(response && response['data']['action_permit'] && response['data']['action_permit']=='0')
          {
            this.toast.openError('Ticket is no longer resides in your access!!','');
          }
          
          else if(response && response['message'] && response['message']=="Success" && response['data'] && response['data']['action_permit'] && response['data']['action_permit']=='1')
          {
            this.toast.openSucess('Ticket ReOpen Successfully','');
            this.GetInternalTicketDetail();
          }
          else if(response && response['message'] && response['message']!="Success")
          {
            this.toast.openError('Something went wrong !!! Try again... ','');
          }
        });
      }
    });
  }
  
  delete_remark(remark_id:any,comp_id:any,index:any)
  {
    console.log("delete clicked");
    console.log("rem:"+remark_id ,"comp_id"+comp_id); 
    this.dialog1.delete('Remark!').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({'complaint_id':comp_id ,'remark_id':remark_id ,'ticket_id':this.detail.id},'complaint/delete_ticket_remark').subscribe((response)=>{
          console.log(response);
          this.remark_doc_data.splice(index,1);
          if(response['message']=='Success'){
            this.toast.openSucess('Remark deleted successfully !!','');
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
  }

  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  
  
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';



@Component({
    selector: 'app-internal-tickets',
    templateUrl: './internal-tickets.component.html'
})
export class InternalTicketsComponent implements OnInit {
    
    tokenInfo:any={};status_name:any='';today_date;
    constructor(public db:DatabaseService,public toast: SnackBarOverview,public dialog:DialogComponent) { }
    
    ngOnInit() {
        
        this.today_date = new Date().toISOString().slice(0,10);
        console.log(this.today_date)
        
        this.tokenInfo = this.db.tokenInfo;
        console.log(this.tokenInfo);
        
        
        if(this.tokenInfo.role_name!='Administrator')
        {
            this.status_name = 'Action_Req';
            if(this.db.ticket_active_tab)
            {
                this.status_name = this.db.ticket_active_tab;
            }
            this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
            
            
        }
        else
        {
            this.GetTicketList(100,0,'',status='All');
        }
        
    }
    
    loader:any = '';  
    start:any = "0";
    pagelimit:any = "100";
    search:any = {};
    rqst_count:any = '';
    total_page:any = ''
    pagenumber:any = '';
    tickets:any = [];
    ticketcount:any = {};
    all_ticket:any=[];
    
    
    search_by_date(date_to:any,date_from:any)
    {
        this.search.to=date_to;
        console.log(this.search.to);
        this.search.from=date_from;
        console.log(this.search.from);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_tic_no(tic_no:any)
    { 
        this.search.tic_no = tic_no; 
        console.log(this.search.tic_no);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_assign_by(assign_by:any)
    { 
        this.search.assign_by = assign_by; 
        console.log(this.search.assign_by);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_level1(level_1:any)
    { 
        this.search.level_1 = level_1; 
        console.log(this.search.level_1);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }

    search_assign_to(assign_to:any)
    { 
        this.search.assign_to = assign_to; 
        console.log(this.search.assign_to);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_status(status:any)
    { 
        this.search.serch_status = status; 
        console.log(this.search.serch_status);
        this.GetTicketList(this.pagelimit,this.start,'',this.status_name);
    }
    
    GetTicketList(pagelimit:any=100,start:any=0,action:any='',status){
        this.loader = "1";
        this.pagelimit = pagelimit;
        this.start = start;
        console.log(this.start);
        console.log(action);
        console.log(status);
        
        this.status_name = status;
        if(action == "refresh")
        {
            this.search = {};
            this.start = 0;
        }
        this.search.status = status;
        this.db.ticket_active_tab = status;
        console.log(this.search);
        
        this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'complaint/internal_ticket_list')
        .subscribe((result:any)=>{
            console.log(result);
            this.tickets = result['data']['list'];
            this.all_ticket=result['data']['list'];
            
            console.log(this.all_ticket);
            
            
            this.rqst_count = result['data']['count'];
            this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
            this.pagenumber = (this.start/this.pagelimit)+1;
            this.ticketcount = result['data']['ticket_count'];
            
            if((this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.user_type=='HEAD' && this.tokenInfo.role_name!='Administrator'))
            {
                console.log("hello");
                console.log(this.status_name);
                console.log(this.tokenInfo.user_type);
                console.log(this.tokenInfo.role_name);
                console.log(this.tokenInfo.logged_in_type);
            }
            this.loader = '';
        },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try again... ','');
            this.loader = '';
        });
    }
    
    delete(index ,id,complaint_id)
    {
        console.log(index);
        console.log(id);
        this.dialog.delete('Ticket !').then((result) => {
            console.log(result);
            if(result)
            {
                this.db.get_rqst('complaint/delete_internal_ticket/'+id+'/'+complaint_id)
                .subscribe(res => {
                    console.log(res);
                    this.tickets.splice(index,1);
                    this.toast.openSucess('Row Deleted Sucessfully ','');      
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try again... ','');      
                });
            }
        });
    }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { PartAssignModalComponent } from '../part-assign-modal/part-assign-modal.component';
import{ CreateTicketComponent } from '../create-ticket/create-ticket.component';
import{ ComplaintActionComponent } from '../complaint-action/complaint-action.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog,MatDialogConfig } from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { DomSanitizer } from '@angular/platform-browser';
import { CreateEmailComponent } from '../create-email/create-email.component';
import { EditComplaintItemComponent } from '../edit-complaint-item/edit-complaint-item.component';
import {Location} from '@angular/common';
import { ComplaintProductDialogComponent } from '../complaint-product-dialog/complaint-product-dialog.component';
import { locateHostElement } from '@angular/core/src/render3/instructions';


@Component({
    selector: 'app-complaints-detail',
    templateUrl: './complaints-detail.component.html'
})
export class ComplaintsDetailComponent implements OnInit {
    name = 'Angular 5';
    fileUrl;
    ecrpt_id:any = '';
    detail: any ={};
    loader:any;
    invoice_info: any =[];
    invoice_no:any = '';
    complaints_detail:any=[];
    branch_info: any=[];
    internal_tickets: any=[];
    internal_tickets_parts: any=[];
    items: any=[];
    logs: any=[];
    remark_doc_data:any=[];
    other_doc_data:any=[];
    hide = true; // for password
    tokenInfo:any={};
    status_log:any;
    checkedItems:any=[];
    comp:any={};
    temp_item_fault_issue=[];
    complaint_type: any;
    comes_from:any='';
    status_remarks: any=[];
    product_issues: any=[];
    active:any={};
    public listing;
    update_claim =0;
    update_claim1 =0;
    tokendata:any;
    pendingat:any ='test1';
    currentlogin:any ='test2';

    claim_status =['Yes','No'];
    
    
    constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog: MatDialog,public dialogbox:DialogComponent,private sanitizer: DomSanitizer, private _location: Location) { 
        this.listing = new Array(6);
        
        console.log(db);
        console.log(router);
        console.log(toast);
        console.log(dialog);
        console.log(dialogbox);
        console.log(_location);
        console.log(sanitizer);
        console.log(db.tokenInfo.name);
        this.currentlogin = db.tokenInfo.name;
        

        
        
        
        this.route.params.subscribe(params=>{
            console.log(params);
            this.ecrpt_id = params.id;
            this.comes_from = params.from;
            console.log(this.ecrpt_id);
        });
    }
    
    ngOnInit() {
        this.tokenInfo = this.db.tokenInfo;

        console.log(this.tokenInfo);
        this.tokendata = this.db.tokenInfo.role_id;
        console.log(this.db.tokenInfo.role_id);
        this.GetComplaintDetail();
        console.log(this.db.UploadUrl);
        this.status_log=0;
    }
    
    step = 0;
    
    formData=new FormData(); 

    backClicked() {
        console.log( 'goBack()...' );
        this._location.back();
    }
    
    setStep(index: number) {
        this.step = index;
    }
    
    nextStep() {
        this.step++;
    }
    
    prevStep() {
        this.step--;
    }

    changeapprovedamount(){
        console.log("changeamount");
        
        this.detail.approved_amount='0';
        this.update_claim1=0;
    }

    

    selectedFile:any=[];
    select_file(event)
    {
        console.log("enter2");
        
        let total_files_size=0;
        console.log(event.target.files.length);
        
        for(var i=0; i<event.target.files.length; i++)
        {
            const file = event.target.files[i];
            console.log('size', file.size);
            console.log('type', file.type);
            let extension  = file.type.split('/').pop(); 
            console.log(extension);
            console.log(typeof(file.size));
            
            total_files_size = total_files_size + parseInt(file.size);
            if(parseInt(file.size)<=5242880)
            {
                if(extension=='png' || extension=='jpeg' || extension=='jpg' || extension=='gif' || extension=='tif' || extension=='pdf')
                {
                    this.selectedFile.push(event.target.files[i]);
                }
                
                else if(extension!='png' && extension!='jpeg' && extension!='jpg' && extension!='gif' && extension!='tif' && extension!='pdf')
                {
                    console.log("not pdf");
                    
                    if(extension=='vnd.openxmlformats-officedocument.wordprocessingml.document' || extension=='vnd.ms-powerpoint')
                    {
                        console.log("allow file");
                        this.selectedFile.push(event.target.files[i]);
                    }
                    else
                    {
                        console.log("error");
                        
                        this.toast.openError('The file type not allowed!!! Try Again...','');
                    }
                    
                }
                else
                {
                    this.toast.openError('The file type not allowed!!! Try Again...','');
                }
            }
            
            else
            {
                this.toast.openError('The file size not allowed more than 5mb !!! Try Again...','');
            }
        }
        
        console.log(total_files_size);
        
        if(total_files_size>5242880)
        {
            this.toast.openError('The files size not allowed more than 5mb !!! Try Again...','');
            this.selectedFile=[];
        }
        
        console.log(this.selectedFile);
        
    }


    
     delFile(name:any,id:any,index:any){
        console.log(name); 
        console.log(index);
        // console.log(name1);
        console.log(id);
console.log();
        
        this.selectedFile = id;
        // this.selectedFile.splice(index,1);
        console.log(this.selectedFile);

        var complaint_id = this.detail.id;
    console.log(this.selectedFile.file_name);
    // console.log(this.db.UploadUrl+"/"+id.file_name);
    // id.file_name = id.file_name.replace(/ /gi,"%20");
    // id.filetest=this.db.UploadUrl+"/"+id.file_name;
    // this.selectedFile.file_name=this.selectedFile.file_name.replace(/ /gi,"%20");
    // console.log(this.selectedFile.file_name);

    // id.filetest=id.filetest.blob(id.filetest);
    // let blob = await fetch(id.filetest).then(r => r.blob());
    // console.log(id.filetest);
    // console.log(blob);
    
    
    
    if(complaint_id)
    {
        this.formData.append("complaint_id",name);
        // this.formData.append("created_by",this.tokenInfo.id);
        // this.formData.append("created_by_type",this.tokenInfo.logged_in_type);
        this.formData.append("file_name",this.selectedFile.file_name);
        console.log(this.formData);

        console.log(this.selectedFile);
        
        // for(var i=0; i<this.selectedFile.length; i++)
        // {
        //     console.log(this.selectedFile[i]);
        //     console.log(this.selectedFile[i].name);
            
            
        //     this.formData.append("image"+i ,this.selectedFile[i],this.selectedFile[i].name);
        // }
        console.log(this.formData);
        this.db.FileData(this.formData,"complaint/delete_complaint_document")
        .subscribe((result:any) =>{
            console.log(result);
        },error=>{
            console.log(error);
        });

        this.GetComplaintDetail();
    }
    

    }

    length12:any;
    dealer_list:any = [];
    
    action_err:boolean=false;
    
    GetComplaintDetail(){
    
        this.selectedFile = [];
        this.formData=new FormData(); 
        
        this.loader = '1';
        this.db.FetchData({'comp_id': this.ecrpt_id },'complaint/complaint_detail')
        .subscribe((result: any) => {
            console.log(result);
            console.log(this.ecrpt_id);
            this.detail = result['data']['detail'];
            
            if(this.detail.dealer_name == null)
            {
                this.toast.openError("Dealer Not Found!","Try Again");
                this.action_err = true;
            }
            
            this.dealer_list = result['data']['dealer_list'];
            console.log(this.detail);
            
            this.internal_tickets = result['data']['internal_tickets'];
            this.internal_tickets_parts = result['data']['internal_tickets_parts'];
            this.items = result['data']['items'];
            
            for(let i=0;i<this.items[0].issue_data.length;i++)
            {
                this.items[0].issue_data[i]['checked'] = true;
            }
            
            this.logs = result['data']['logs'];
            this.status_remarks = result['data']['status_remarks'];
            this.product_issues = result['data']['product_issues'];
            
            // console.log(this.status_remarks[0].system_user_name);
            // console.log(this.status_remarks[0].pending_at_name);
            if(this.logs){
                this.length12=this.logs.length;
            this.pendingat = this.logs[this.length12 - 1].pending_to;
            // this.currentlogin = this.status_remarks[0].system_user_name;
              }
            console.log(this.pendingat);
            console.log(this.currentlogin);
            
            
            
            // status_remarks
            this.remark_doc_data = result['data']['remark_doc_data'];
            this.other_doc_data = result['data']['other_doc_data'];
            
            
            console.log(this.remark_doc_data);
            console.log(this.detail);
            this.complaint_type=this.detail['type'];
            
            this.loader = '';
        }, error => {
            console.log(error);
            this.toast.openError('Something went wrong !!! Try again... ','');
            this.loader = '';
        });
    }
    

    canwrite(){
        console.log(this.pendingat);
        console.log(this.currentlogin);
    
            this.update_claim=1;
        
    }


    Comp_Reopen() 
    {
        this.dialogbox.confirmation('Status!').then((result) => {
            console.log(result);
            if(result==true)
            {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = { complaint_id: this.detail.id,from: 'Reopen',complaint_type: this.detail.type,branch_id: this.detail.branch_id,complaint_status: this.detail.status};
                dialogConfig.width='500px';
                const dialogRef = this.dialog.open(ComplaintActionComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(response => {
                    console.log(`Dialog result: ${response}`);
                    console.log(response);
                    
                    if(response && response['data']['action_permit'] && response['data']['action_permit']=='0')
                    {
                        this.toast.openError('Complaint is no longer resides in your access!!','');
                    }
                    
                    else if(response && response['message'] && response['message']=="Success" && response['data'] && response['data']['action_permit'] && response['data']['action_permit']=='1')
                    {
                        this.toast.openSucess('Complaint ReOpen Successfully','');
                        this.GetComplaintDetail();
                    }
                    else if(response && response['message'] && response['message']!="Success")
                    {
                        this.toast.openError('Something went wrong !!! Try again... ','');
                    }
                });
                
            }
        });
    }
    
    openActionDialog(from) 
    {
        if(from=='Forward')
        {
            this.dialogbox.Forwardconfirmation('Forward').then((result) => {
                console.log(result);
                if(result)
                {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.data = { complaint_id: this.detail.id,from: from,complaint_type: this.detail.type,branch_id: this.detail.branch_id,complaint_status: this.detail.status};
                    dialogConfig.width='500px';
                    const dialogRef = this.dialog.open(ComplaintActionComponent, dialogConfig);
                    dialogRef.afterClosed().subscribe(result => {
                        console.log(`Dialog result: ${result}`);
                        console.log(result);
                        
                        if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
                        {
                            this.toast.openError('Complaint is no longer resides in your access!!','');
                        }
                        
                        else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
                        {
                            if(from=='Service-engineer')
                            this.toast.openSucess('Service Engineer Successfully Assigned','');
                            else if(from=='Remark')
                            this.toast.openSucess('Remark Added Successfully','');
                            else if(from=='ChangeStatus')
                            this.toast.openSucess('Status Changed Successfully','');
                            else
                            this.toast.openSucess('Successfully'+' ' +from,'');
                            this.GetComplaintDetail();
                        }
                        else if(result && result['message'] && result['message']!="Success")
                        {
                            this.toast.openError('Something went wrong !!! Try again... ','');
                        }
                        
                    });
                }
            });
        }
        
        else
        {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = { complaint_id: this.detail.id,from: from,complaint_type: this.detail.type,branch_id: this.detail.branch_id,complaint_status: this.detail.status};
            dialogConfig.width='500px';
            const dialogRef = this.dialog.open(ComplaintActionComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
                console.log(`Dialog result: ${result}`);
                console.log(result);
                
                if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
                {
                    this.toast.openError('Complaint is no longer resides in your access!!','');
                }
                
                else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
                {
                    if(from=='Service-engineer')
                    this.toast.openSucess('Service Engineer Successfully Assigned','');
                    else if(from=='Remark')
                    this.toast.openSucess('Remark Added Successfully','');
                    else if(from=='ChangeStatus')
                    this.toast.openSucess('Status Changed Successfully','');
                    else
                    this.toast.openSucess('Successfully'+' ' +from,'');
                    this.GetComplaintDetail();
                }
                else if(result && result['message'] && result['message']!="Success")
                {
                    this.toast.openError('Something went wrong !!! Try again... ','');
                }
                
            });
        }
    }
    
    
    edit_customer()
    {
        this.loader = '1';
        console.log(this.detail);
        this.db.FetchData({'data': this.detail},'dealer/edit_customer')
        .subscribe((result)=>{
            console.log(result);
            if (result['message'] == "Success") {
                console.log("Updated!");
                this.toast.openSucess('Updated! ','');
                this.GetComplaintDetail();
            }
            else {
                console.log("X something went wrong! ");
                this.toast.openError('Something went wrong !!! Try again... ','');
            }
        }, error => {
            console.log(error);
        }); 
        this.active = {};
    }
    
    num_err:any=false;

    add_document(){
        
    var complaint_id = this.detail.id;
    console.log(this.selectedFile);
    
    if(complaint_id)
    {
        this.formData.append("complaint_id",complaint_id);
        this.formData.append("created_by",this.tokenInfo.id);
        this.formData.append("created_by_type",this.tokenInfo.logged_in_type);
        
        for(var i=0; i<this.selectedFile.length; i++)
        {
            this.formData.append("image"+i ,this.selectedFile[i],this.selectedFile[i].name);
        }
        console.log(this.formData);
        this.db.FileData(this.formData,"complaint/upload_complaint_document")
        .subscribe((result:any) =>{
            console.log(result);
        },error=>{
            console.log(error);
        });

        this.GetComplaintDetail();
    }
                
    }


    edit_dealer()
    {
        console.log(this.detail.cust_contact.length);
        var det_len = new String(this.detail.cust_contact) 
        console.log("Length "+det_len.length)
        
        if(det_len.length == 10)
        {
            this.loader = '1';
            console.log(this.detail);
            this.db.FetchData({'data': this.detail},'dealer/edit_dealer')
            .subscribe((result)=>{
                console.log(result);
                if (result['message'] == "Success") {
                    console.log("Updated!");
                    this.toast.openSucess('Updated! ','');
                    this.GetComplaintDetail();
                }
                else {
                    console.log("X something went wrong! ");
                    this.toast.openError('Something went wrong !!! Try again... ','');
                }
            }, error => {
                console.log(error);
            }); 
            this.active = {};
        }
        else
        {
            this.num_err = true;
        }
    }
    

    updateClaim()
    {
        console.log(this.detail);
       // return false;
       
        
        if(this.detail.type == 'Insurance Claim')
        {
            this.loader = '1';
            console.log(this.detail);
            this.db.FetchData({'data': this.detail},'complaint/updateInsuranceClaim')
            .subscribe((result)=>{
                console.log(result);
                if (result['message'] == "Success") {
                    console.log("Updated!");
                    this.toast.openSucess('Updated! ','');
                    this.update_claim =0;
                    this.update_claim1 =0;
                    this.GetComplaintDetail();
                   
                }
                else {
                    console.log("X something went wrong! ");
                    this.toast.openError('Something went wrong !!! Try again... ','');
                }
            }, error => {
                console.log(error);
            }); 
            this.active = {};
        }
        else
        {
            this.num_err = true;
        }
    }
    
    MobileNumber(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
        this.num_err=false;
    }
    
    
    open_productedit_dialog(product,invoice)
    {
        let dialogRef = this.dialog.open(ComplaintProductDialogComponent,{
            width: '850px',
            data :{
                'product_detail':product,
                'invoice_detail':invoice
            }
        });
        
        dialogRef.afterClosed()
        .subscribe(resp=>{
            console.log(resp);
            this.GetComplaintDetail();
        })
    }
    
    
    editCompItemDialog(index,id,qty,description,cat_code,issue_array,level3_code) 
    {
        console.log(index);
        console.log(id);
        console.log(qty);
        console.log(description);
        console.log(cat_code);
        console.log(issue_array);
        
        const dialogConfig = new MatDialogConfig();
        
        dialogConfig.data = { 
            complaint_id:this.detail.id,
            item_id:id,
            qty: qty,
            cat_code:cat_code,
            level3_code:level3_code,
            
            description: description,
            index:index,
            item_array:this.items,
            complaint_type:this.complaint_type,
            issue_array:issue_array
        };
        
        const dialogRef = this.dialog.open(EditComplaintItemComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            // && result.length>0
            if(result =='Success')
            {
                this.toast.openSucess('Issue Added Successfully','');
                this.GetComplaintDetail();
            }
            else if(result =='Failed'){
                this.toast.openError('Something went wrong!!','');
            }
            
            else if(result =='Error'){
                this.toast.openError('Something went wrong!!','');
            }
        });
    }
    
    openTicketDialog() 
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { complaint_id: this.detail.id,};
        
        const dialogRef= this.dialog.open(CreateTicketComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
            {
                this.toast.openError('Complaint is no longer resides in your access!!','');
            }
            
            else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
            {
                this.toast.openSucess('Ticket Created Successfully','');
                this.GetComplaintDetail();
            }
            else if(result && result['message'] && result['message']!="Success")
            {
                this.toast.openError('Something went wrong !!! Try again... ','');
            }
        });
    }
    
    OpenDialogBox(){
        console.log(" i am in dialog box ");
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { 
            complaint_id: this.detail.id,
            logs:this.logs 
        };
        const dialogRef= this.dialog.open(CreateEmailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            if(result && result=="Success")
            {
                this.toast.openSucess('Email Sent Successfully','');
                this.GetComplaintDetail();
            }
            else if(result && result!="Success")
            {
                this.toast.openError('Something went wrong !!! Try again... ','');
                
            }
        });
        
    }
    
    
    openAssignDialog() 
    {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            branch_id: this.detail.branch_id,
            complaint_id: this.detail.id,
            complaint_type: this.detail.type
            
        };
        const dialogRef= this.dialog.open(PartAssignModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
            {
                this.toast.openError('Complaint is no longer resides in your access!!','');
            }
            
            else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
            {
                this.toast.openSucess('Parts Assigned Successfully','');
                this.GetComplaintDetail();
            }
            else if(result && result['message'] && result['message']!="Success")
            {
                this.toast.openError('Something went wrong !!! Try again... ','');
            }
        });
    }
    
    remark:any;
    add_remark()
    {
        console.log(this.remark);
        console.log(this.detail.id);
        this.db.FetchData({'complaint_id': this.detail.id,'remark':this.remark},'complaint/add_remark').subscribe((response)=>{
            console.log(response);
            this.logs.push(response['data']);
            this.remark='';
        },err=>{
            console.log(err);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
        
    }
    
    delete_internal_ticket(tic_id,index,complaint_id)
    {
        console.log(tic_id);   
        console.log(complaint_id);    
        
        this.dialogbox.delete('Internal Ticket !').then((result) => {
            console.log(result);
            if(result)
            {
                this.db.FetchData({},'complaint/delete_ticket/'+ tic_id + '/'+complaint_id).subscribe((response)=>{
                    console.log(response);
                    if(response['message']=='Success')
                    {
                        this.internal_tickets.splice(index,1);
                        this.toast.openSucess('Ticket Deleted Successfully','');
                    }
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try Again...','');
                });
            }
        });
    }
    
    close_internal_ticket(tic_id,index)
    {
        console.log(tic_id);   
        console.log(index);
        
        this.dialogbox.confirmation('Close !').then((result) => {
            console.log(result);
            if(result)
            {
                this.db.FetchData({},'complaint/close_ticket/'+tic_id).subscribe((response)=>{
                    console.log(response);
                    if(response['message']=='Success')
                    {
                        // this.internal_tickets['index']['status']='Close';
                        this.toast.openSucess('Ticket Closed Successfully','');
                        
                    }
                    
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try Again...','');
                });
            }
        });
    }
    
    delete_remark(id:any,comp_id:any,index:any)
    {
        console.log("delete clicked");
        console.log(id , index); 
        console.log("Complaint Id : "+comp_id);
        
        this.dialogbox.delete('Remark!').then((result) => {
            console.log(result);
            if(result)
            {
                this.db.FetchData({'complaint_id':comp_id,'remark_id':id},'complaint/delete_complaint_remark').subscribe((response)=>{
                    console.log(response);
                    this.remark_doc_data.splice(index,1);
                    if(response['message']=='Success'){
                        this.toast.openSucess('Complaint deleted successfully !!','');
                    }
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try Again...','');
                });
            }
        });
    }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { Router } from '@angular/router';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    // dateInput: 'LL',
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class InvoiceListComponent implements OnInit {
  date = new FormControl(moment());
  loader:any = '';
  start:any = "0";
  pagelimit:any = "20";
  listinvoice:any=[];
  branchlist:any=[];
  search:any = {};
  rqst_count:any='';
  total_page:any='';
  pagenumber:any='';
  totalinvoice:any='';
  sendRequest:any=true;
  searched_data: any=[];
  public href: string = "";
  constructor(public db:DatabaseService,public toast: SnackBarOverview,private router: Router) 
  {
    console.log(this.db.search_data);
    this.searched_data=this.db.search_data;
   }
  
  ngOnInit() {
    this.href = this.router.url;
    console.log(this.router.url);
    // this.refresh();
    console.log(this.db.search_data);

    if(this.db.search_data)
    {
      if(this.db.search_data['branch'])
      {
        this.search.branch=this.db.search_data['branch'];
      }

      if(this.db.search_data['Customer'])
      {
        this.search.Customer=this.db.search_data['Customer'];
      }
      
      if(this.db.search_data['dealer_name'])
      {
        this.search.dealer_name=this.db.search_data['dealer_name'];
      }

      if(this.db.search_data['inv_no'])
      {
        this.search.inv_no=this.db.search_data['inv_no'];
      }
    }
    this.getInvoiceList();
    this.get_branch_list();
  }

  refresh()
  {
      this.search = {};
      this.start = 0;
  }

  search_inv_number(inv_no)
  {
    this.search.inv_no=inv_no;
    console.log(this.search.inv_no);
    this.getInvoiceList();
  }
  search_dealer_name(dealer)
  {
    this.search.dealer=dealer;
    this.getInvoiceList();
  }
  search_customer(cust)
  {
    this.search.cust=cust;
    console.log(this.search.cust);
    this.getInvoiceList(); 
  }
  search_branch(branch)
  {
    this.search.branch=branch;
    console.log(this.search.branch);
    this.getInvoiceList(); 
  }
  search_by_date(date_to:any,date_from:any)
  {
   this.search.to=date_to;
   console.log(this.search.to);
   this.search.from=date_from;
   console.log(this.search.from);
   this.getInvoiceList();
  }

  get_branch_list()
  {
    this.db.FetchData({},'master/branch_list_forsearch').subscribe(resp=>{
      this.branchlist=resp['data'];
    });
  }

  getInvoiceList(pagelimit:any=20,start:any=0,action:any='')
  {
    console.log("i am in getinvoicelist fucntion");
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    console.log(this.search);
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/invoice_list')
    .subscribe(result=>{
      console.log(result);
      if (result['message']=='Success')
      {
        this.sendRequest=false;
        this.listinvoice= result['data']['list'];
      }
      if(this.search!=[])
      {
        this.searched_data=this.search;
        console.log(this.searched_data);
        this.db.search_data=this.searched_data;
        console.log(this.db.search_data);
        this.db.setSearchValue(this.search);
        this.db.getSearchValue();
      }
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.totalinvoice = result['data']['count'];
      this.loader = '';

    },error=>{
      console.log(error);
      this.loader = '';
      this.toast.openError('Something went wrong !!! Try Again...','');  
    });
    
  }

 
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../_services/database.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  token_info:any={};
  constructor(public db:DatabaseService) { 

    this.token_info = this.db.tokenInfo;
    console.log(this.token_info);
    
  }

  ngOnInit() {
   
    console.log(this.token_info);
  }

}

import { Component, OnInit,ElementRef, HostListener, Input  } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';
import { Select2OptionData } from 'ng2-select2';


@Component({
  selector: 'app-branch-user-detail',
  templateUrl: './branch-user-detail.component.html'
})
export class BranchUserDetailComponent implements OnInit {
  
  
  public exampleData: Array<Select2OptionData>;
  public options: Select2Options;
  public value: string[];
  other_branch_array:any=[];
  
  encrypt_id:any = '';
  detail: any = {};
  user_dealers:any=[];
  user_logs:any=[];
  loader:any;
  hide=true;
  tokenInfo:any={};
  user_id:any;
  active: any = {}; 
  complaint: any;
  runn_complaint: any;
  state_list: any=[];
  district_list: any=[];
  flag:any=0;
  
  branch_user_dealers_list:any=[];
  public href: string = "";
  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog:DialogComponent) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
    this.GetStates();
  }
  
  ngOnInit() { 
    this.tokenInfo = this.db.tokenInfo;
    // if(this.tokenInfo.logged_in_type!='System_User')
    // {
    //   this.user_id=this.tokenInfo.id;
    // }
    // else
    // {
    //   this.user_id=this.encrypt_id;
    // }
    this.user_id=this.encrypt_id;
    this.GetUserDetail();
    this.href = this.router.url;
    console.log(this.router.url);
    
    // other branch
    this.value = []; 
    this.options = {
      multiple: true
    }
    
  }
  
  
  @HostListener('click') onClick() {
    
  }
  
  
  // this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.district="0";
  edit_name()    { this.active.name="1"; this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.district="0";  this.active.city="0"; this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_email()   { this.active.email="1"; this.active.name="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.district="0";  this.active.city="0";this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_street()  { this.active.street="1" ;this.active.name="0";this.active.email="0"; this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.district="0";this.active.city="0";this.active.username="0"; this.active.password="0"; this.active.assigned_branch="0";}
  
  edit_mobile()  { this.active.mobile="1";  this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.district="0";this.active.city="0";this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_pincode() { this.active.pincode="1"; this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.status="0"; this.active.state="0";  this.active.district="0";this.active.city="0"; this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_status()  { this.active.status="1"; this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.state="0";  this.active.district="0";this.active.city="0"; this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  // edit_country() { this.active.country="1"; }
  edit_state()  { this.active.state="1";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0";  this.active.district="0";  this.flag="1"; this.active.city="0";this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_district()  { this.active.district="1";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";  this.active.city="0"; this.active.username="0"; this.active.password="0";this.active.assigned_branch="0";}
  
  edit_city()  { this.active.city="1";this.active.district="0";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0"; this.active.username="0"; this.active.password="0"; this.active.assigned_branch="0";}
  
  edit_password(){ this.active.password="1"; this.active.username="0"; this.active.city="0";this.active.district="0";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0"; this.active.assigned_branch="0";}
  
  
  edit_username(){ this.active.username="1"; this.active.password="0";this.active.city="0";this.active.district="0";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0"; this.active.assigned_branch="0";}
  
  edit_other_assigned_branch(){ this.active.assigned_branch="1";this.active.username="0"; this.active.password="0";this.active.city="0";this.active.district="0";this.active.name="0";this.active.email="0"; this.active.street="0" ;this.active.mobile="0";this.active.pincode="0";this.active.status="0"; this.active.state="0";this.get_other_branch_array(); }
  
  
  
  selected_other_branch=[];
  changed_other_branch(event)
  {
    console.log(event);
    this.selected_other_branch=[];
    for(var i=0;i<event.data.length;i++)
    {
      this.selected_other_branch.push({'branch_id': event.data[i]['id'],'branch_name':event.data[i]['text']});
    }
    console.log(this.selected_other_branch);
  }
  
  
  
  get_other_branch_array()
  {
    if(this.detail.branch_code)
    {
      this.db.FetchData({'branch_code':this.detail.branch_code,'selected_branch':this.detail.other_assigned_branch},'Branch/other_branch_list')
      .subscribe((result:any)=>{
        console.log(result);
        this.other_branch_array = result['data'];
        console.log(this.other_branch_array);      
      },error=>{
        console.log(error);
      });
    }
  }
  
  GetUserDetail() 
  {
    this.loader = '1';
    this.db.FetchData({'user_id': this.user_id }, 'BranchUser/branch_user_detail')
    .subscribe((result: any) => {
      console.log(result);
      
      this.detail = result['data']['detail'];
      this.user_dealers = result['data']['dealers'];
      this.user_logs = result['data']['logs'];
      this.complaint= result['data']['total_complaint'];
      this.runn_complaint=result['data']['runn_complaint'];
      this.db.branchuser_name = result['data']['detail']['name'];
      this.db.branchuser_id = this.encrypt_id;
      this.loader = '';
      
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';
    });
  }
  
  updateUserDetail()
  {
    if(this.selected_other_branch && this.selected_other_branch.length>0)
    {
      this.detail.other_assigned_branch = this.selected_other_branch;
    }
    this.loader = '1';
    console.log(this.detail);
    this.db.FetchData({'data': this.detail},'BranchUser/update_branchuser')
    .subscribe((result)=>{
      console.log(result);
      if (result['message'] == "Success") {
        console.log("Updated!");
        this.toast.openSucess('Updated! ','');
        this.GetUserDetail();
      }
      else {
        this.toast.openError('Something went wrong !!! Try again... ','');
      }
    }, error => {
      console.log(error);
    }); 
    this.active = {};
  }
  
  
  delete_assigned_branch(index,branch_id)
  {
    console.log(index);
    
    this.dialog.delete('Assigned Branch ').then((result) => {
      console.log(result);
      if(result)
      {
        let data = {'branch_id':branch_id,'user_id':this.detail.id};
        console.log(data);
        
        this.db.FetchData({'data': data},'BranchUser/delete_user_assigned_branch')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            this.detail.other_assigned_branch.splice(index,1);
            this.toast.openSucess('Assigned Branch  Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
  
  GetStates()
  {   
    this.db.FetchData({},'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
    this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }
  
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  //function for name: it should not contain any number
  charonly(event: any){
    const pattern =/^[a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
import { Select2OptionData } from 'ng2-select2';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html'
})
export class AddServiceComponent implements OnInit {
  addServiceForm: FormGroup;
  submitted = false; //for submit button
  states:any=[];
  branches:any=[];
  districts:any=[];
  flag:any;
  flag1:any;
  isDisabled: boolean;
  tokenInfo:any={};
  department:any = [];
  img=0;
  user_code: any;

  public exampleData: Array<Select2OptionData>;
  public options: Select2Options;
  public value: string[];
  other_branch_array:any=[];


  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) { 
    
    this.GetDepartment();

    
    
  }
  
  ngOnInit() {

      // other branch
      this.value = []; 
      this.options = {
        multiple: true
      }

    this.tokenInfo = this.db.tokenInfo;
    console.log(this.tokenInfo);
    
    
    this.addServiceForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['',Validators.email],
      branch: [''],
      user_code:['',Validators.required],
      user_role:['',Validators.required],
      department:[''],
      username:['',Validators.required],
      city: [''],
      street: [''],
      country: ['India'],
      state: [''],
      district: [''],
      // department:['',Validators.required],
      pincode: [''],
      other_branch: []
    });
    
    if(this.tokenInfo.logged_in_type=='Branch_User')
    {
      // this.addServiceForm.controls['branch'].setValue('');
      this.addServiceForm.controls['user_role'].setValue('RE');
      
      
      this.addServiceForm.controls["branch"].clearValidators();
      this.addServiceForm.controls['branch'].updateValueAndValidity();

      this.get_other_branch_array();
    }
    else
    {
      this.addServiceForm.controls['user_role'].setValue('');
      
      this.addServiceForm.controls["branch"].setValidators([Validators.required]);
      this.addServiceForm.controls['branch'].updateValueAndValidity();
    }
    this.GetStates();
    this.Get_branches();    
  }
  
  selected_other_branch=[];
  changed_other_branch(event)
  {
    console.log(event);
    this.selected_other_branch=[];
    for(var i=0;i<event.data.length;i++)
    {
      this.selected_other_branch.push({'branch_id': event.data[i]['id'],'branch_name':event.data[i]['text']});
    }
    console.log(this.selected_other_branch);
  }



  get_other_branch_array()
  {
    let a =  this.addServiceForm.controls['user_role'].value;
    
    let b =  this.addServiceForm.controls['branch'].value;
    console.log(b);
    console.log(a);

    

    if(a=='RE' && b)
    {
      this.db.FetchData({'branch_code':b},'Branch/other_branch_list')
      .subscribe((result:any)=>{
        console.log(result);
        this.other_branch_array = result['data'];
        console.log(this.other_branch_array);      
      },error=>{
        console.log(error);
      });
    }
  }

  GetDepartment()
  {
    this.db.get_rqst('master/department_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.department = result['data'];
      console.log(this.department);      
    },error=>{
      console.log(error);
    });
  }
  get d() { return this.addServiceForm.controls; }
  
  MobileNumber2(event: any)
  {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  isusernameLoading:any;
  
  UserCode(user_code: any)
  {
    console.log(user_code.value);
    this.user_code=user_code.value;
    this.isusernameLoading=1;
    this.db.FetchData({'user_code':this.user_code,'branch_code':this.addServiceForm.get('branch').value},'BranchUser/check_user_code_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success" && result['data'] && result['data']['exist_data'] && result['data']['exist_data']['user_code'])
      {
        this.flag=true;
        this.isusernameLoading=0;
        this.addServiceForm.controls['username'].setValue('');
      }
      else if(result['data'] && result['data']['emp_master_found_row'] )
      {
        let exist_row = result['data']['emp_master_found_row'];
        this.addServiceForm.controls['name'].setValue(exist_row['name']);
        this.addServiceForm.controls['mobile'].setValue(exist_row['mobile_no']);
        this.addServiceForm.controls['department'].setValue(exist_row['dept_name']);
        this.addServiceForm.controls['email'].setValue(exist_row['email']);
        this.addServiceForm.controls['user_code'].setValue(exist_row['emp_code']);

        let temp_UserName=this.addServiceForm.value.name.substring(0,3).concat(this.addServiceForm.value.user_code);
        this.addServiceForm.controls['username'].setValue(temp_UserName);
        this.isusernameLoading=0;

      }

      else{
        this.flag=false;
        this.isusernameLoading=0;
        console.log(this.addServiceForm.value);
        let temp_UserName= this.addServiceForm.value.name.substring(0,3).concat(this.addServiceForm.value.user_code )
        console.log(temp_UserName);
        this.addServiceForm.controls['username'].setValue(temp_UserName);
      }
      
    },error=>{
      console.log(error);
    });
  }
  UserName(username:any)
  {
    console.log(username);
    console.log(username);
    this.db.FetchData({'username':username},'master/check_username_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flag1=true;
      }
      else{
        this.flag1=false;
      }
      
    },error=>{
      console.log(error);
    }); 
  }
  
  GetStates()
  {
    this.db.get_rqst('branch/get_states')
    .subscribe((result:any)=>{
      console.log(result);
      this.states = result['data'];
      console.log(this.states);
    },error=>{
      console.log(error);
    });
  }
  
  Get_branches()
  {
    this.db.get_rqst('branch/branch_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.branches = result['data']['list'];
      console.log(this.branches);
    },error=>{
      console.log(error);
    });
  }
  
  GetDistrict(state_name)
  {
    console.log(state_name.value);
    this.db.FetchData({'state_name':state_name.value},'branch/get_state_district')
    .subscribe((result:any)=>{
      console.log(result);
      this.districts = result['data'];
      console.log(this.districts);
    },error=>{
      this.toast.openError('Something went wrong !!! Try Again...','');
      console.log(error);
    });
  }
  
  validate(event:any)
  {
    const pattern = /^[0-9a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  charonly(event: any){
    const pattern =/^[a-zA-Z ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  
  selectedFile:any=[];
  urls = new Array<string>();
  selectvalue(data)
  {
    this.img=1;
    console.log("i am in new function");
    console.log(data);
    //to display image 
    let files = data.target.files[0];
    console.log(files);
    let reader = new FileReader();
    reader.readAsDataURL(data.target.files[0]);
    reader.onload =(e: any)=>{
      this.urls=e.target.result;
    }  
    this.selectedFile.push(data.target.files[0]);
    console.log(this.selectedFile);
  }
  
  formData=new FormData(); // for file uploading  
  submitService() 
  {
    
    this.submitted = true; 
    if (this.addServiceForm.invalid) {
      return;
    }
    else
    {
      console.log(this.addServiceForm.value);

      this.addServiceForm.controls['other_branch'].setValue(this.selected_other_branch);
      this.isDisabled=true;
      
      this.db.FetchData({'data':this.addServiceForm.value},'BranchUser/submit')
      .subscribe((result)=>{
        console.log(result);
        if(result['message'] == "Success")
        {
          var insert_id = result['insert_id'];
          if(insert_id)
          {
            if(this.selectedFile && this.selectedFile.length)
            {
              this.formData.append("image" ,this.selectedFile[0],this.selectedFile[0].name);
              console.log(this.formData);
              
              this.formData.append("id",insert_id);
              this.db.FileData(this.formData,"BranchUser/upload_user_profile_image")
              .subscribe((result:any) =>{
                console.log(result);
              },error=>{
                console.log(error);
              });
            }
          }
          this.toast.openSucess('Branch User Created Successfully','');
          if(this.tokenInfo.logged_in_type!='Branch_User'){
            this.router.navigate(['/branch-list']);
          }
          else{
            this.router.navigate(['/branch-user-list']);
            
          }
        }
      },error=>{
        console.log(error);
        this.toast.openError('Something went wrong !!! Try Again...','');
      });
    }
  }
  
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';
import { MatDialog,MatDialogConfig } from '@angular/material';
import{ ComplaintItemActionComponent } from '../complaint-item-action/complaint-item-action.component';
import * as $ from 'jquery';
import { DialogComponent } from '../../DialogComponent.js';
import { IfStmt } from '@angular/compiler';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        // dateInput: 'LL',
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-add-complaints',
    templateUrl: './add-complaints.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class AddComplaintsComponent implements OnInit {
    date = new FormControl(moment());
    filteredInvoice: any=[];
    InvoiceSearchForm: FormGroup;
    LossValue:FormGroup;
    CustomerSearchForm: FormGroup;
    CatCodeSearchForm: FormGroup;
    level1SearchForm: FormGroup;
    level2SearchForm: FormGroup;
    level3SearchForm: FormGroup;
    
    DealerCodeSearchForm: FormGroup;
    BranchCodeSearchForm: FormGroup;  
    
    addComplaintForm: FormGroup;
    addCustomerForm:FormGroup;
    loader:any='';
    flag:any = 0;
    
    invoice_info:any={};
    invoice_item:any=[];
    submitted = false; //for login button
    comp:any={};
    checkedItems:any=[];
    serv_engg_list:any=[];
    complaint_category_list=[];
    tokenInfo:any={};
    norecordfound=0;
    loss_val = 0;
    
    // Customer
    state_list: any=[];
    district_list:any=[];
    city_list:any=[];
    isDisabled: boolean;
    detail: any = [];
    showhiddendata=false;
    exist_item: any;
    type: any;
    // cust_submitted = false; //for login button
    filter_dealer_arr:any=[];
    
    constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog: MatDialog,public dialogbox:DialogComponent) { 
        this.tokenInfo = this.db.tokenInfo;
        this.GetStates();
    }
    
    isInvLoading = false;
    isCustLoading = false;
    isCatcodeLoading = false;
    isDealerLoading = false;
    isBranchLoading = false;
    islevel1Loading =false;
    islevel2Loading =false;
    islevel3Loading =false;
    today_date;
    
    
    ngOnInit() {
        console.log(this.db.dbinvoice_list);
        
        
        this.today_date = new Date().toISOString().slice(0,10);
        console.log(this.today_date)
        
        this.comp.ask_info = 'No';
        
        console.log(this.comp);
        
        this.comp.ask_serv_engg = 'No';
        
        
        if(this.db.dbinvoice_list)
        {
            this.invoicelist= this.db.dbinvoice_list ;
            this.comp.order_info='Yes';
            this.searchpresnt=1;
        }
        else
        {
            this.invoicelist= [] ;
            this.comp.order_info='';
            this.searchpresnt=0;
        }
        console.log(this.invoicelist);
        
        this.GetComplaintCategoryList();
        this.addComplaintForm = this.formBuilder.group({
            invoice_no:[''],
            order_no: [''],
            inv_id:[''],
            type:[''],
            branch_code:[''],
            dealer_code:[''],
            cust_code:[''],
            service_engg:[''],
            service_engg_name:[''],
            terms_condition:[''],
            complaint_items:[''],
            loss_value:['']
        });
        
        this.addCustomerForm = this.formBuilder.group({
            cust_name:['',Validators.required],
            dealer_code:['',Validators.required],
            email:[''],
            cust_mobile:['',Validators.required],
            installation_Date:[''],
            installation_location:['',Validators.required],
            batch_no:['',Validators.required],
            branch_name : ['',Validators.required],
            branch_mobile : [''],
            dealer_name:['',Validators.required],
            dealer_mobile:['']
        });
        
        this.comp.search_filter=0;
        this.GetBranchlist();
        
        /** for default active tab start */
        this.comp.type = 'Logistic';
        this.addComplaintForm.controls['type'].setValue('Logistic');
        /** for default active tab end */
        
        
        /******invoice suggestive search  */
        this.InvoiceSearchForm = this.formBuilder.group({
            search_invoice_no: null
        })
        
        this.InvoiceSearchForm.get('search_invoice_no').valueChanges
        .pipe(debounceTime(300),tap(() => this.isInvLoading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.isInvLoading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            this.filteredInvoice = invoice;
            this.filteredInvoice = this.filteredInvoice.data;
            console.log(this.filteredInvoice);
        });   
        
        /******customer suggestive search  */
        this.CustomerSearchForm = this.formBuilder.group({
            search_cust_name: null
        })
        
        this.CustomerSearchForm.get('search_cust_name').valueChanges
        .pipe(debounceTime(300),tap(() => this.isCustLoading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.isCustLoading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            this.filteredInvoice = invoice;
            this.filteredInvoice = this.filteredInvoice.data;
            console.log(this.filteredInvoice);
        });   
        
        /******cat code suggestive search  */
        this.CatCodeSearchForm = this.formBuilder.group({
            search_cat_code: null
        })
        
        this.CatCodeSearchForm.get('search_cat_code').valueChanges
        .pipe(debounceTime(300),tap(() => this.isCatcodeLoading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.isCatcodeLoading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            console.log(this.comp.type);
            
            if(this.comp.type=='Product' && this.comp.order_info=='No')
            {
                this.invoice_item = invoice;
                this.invoice_item = this.invoice_item.data;
                
                // when invoice not present or search via cat code
                this.get_product_item_unique_level();
            }
            else
            {
                this.filteredInvoice = invoice;
                this.filteredInvoice = this.filteredInvoice.data;
                console.log(this.filteredInvoice);
            }
            
        });   
        
        /******level 1 suggestive search  */
        this.level1SearchForm = this.formBuilder.group({
            product_item_level1: null
        })
        
        this.level1SearchForm.get('product_item_level1').valueChanges
        .pipe(debounceTime(300),tap(() => this.islevel1Loading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.islevel1Loading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            console.log(this.comp.type);
            
            if(this.comp.type=='Product' && this.comp.order_info=='No')
            {
                this.invoice_item = invoice;
                this.invoice_item = this.invoice_item.data;
                
                // when invoice not present or search via cat code
                this.get_product_item_unique_level();
            }
            else
            {
                this.filteredInvoice = invoice;
                this.filteredInvoice = this.filteredInvoice.data;
                console.log(this.filteredInvoice);
            }
            
        });   
        
        /******level 2 suggestive search  */
        this.level2SearchForm = this.formBuilder.group({
            product_item_level2: null
        })
        
        this.level2SearchForm.get('product_item_level2').valueChanges
        .pipe(debounceTime(300),tap(() => this.islevel2Loading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.islevel2Loading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            console.log(this.comp.type);
            
            if(this.comp.type=='Product' && this.comp.order_info=='No')
            {
                this.invoice_item = invoice;
                this.invoice_item = this.invoice_item.data;
                // when invoice not present or search via cat code
                this.get_product_item_unique_level();
            }
            else
            {
                this.filteredInvoice = invoice;
                this.filteredInvoice = this.filteredInvoice.data;
                console.log(this.filteredInvoice);
            }
        });   
        
        
        /******level 3 suggestive search  */
        this.level3SearchForm = this.formBuilder.group({
            product_item_level3: null
        })
        
        this.level3SearchForm.get('product_item_level3').valueChanges
        .pipe(debounceTime(300),tap(() => this.islevel3Loading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.islevel3Loading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            console.log(this.comp.type);
            
            if(this.comp.type=='Product' && this.comp.order_info=='No')
            {
                this.invoice_item = invoice;
                this.invoice_item = this.invoice_item.data;
                // when invoice not present or search via cat code
                this.get_product_item_unique_level();
            }
            else
            {
                this.filteredInvoice = invoice;
                this.filteredInvoice = this.filteredInvoice.data;
                console.log(this.filteredInvoice);
            }
        });   
        
        
        /******Dealer code suggestive search  */
        this.DealerCodeSearchForm = this.formBuilder.group({
            search_dealer_code: null
        })
        
        this.DealerCodeSearchForm.get('search_dealer_code').valueChanges
        .pipe(debounceTime(300),tap(() => this.isDealerLoading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.isDealerLoading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            this.filteredInvoice = invoice;
            this.filteredInvoice = this.filteredInvoice.data;
            console.log(this.filteredInvoice);
        });   
        
        /******Branch code suggestive search  */
        this.BranchCodeSearchForm = this.formBuilder.group({
            search_branch_code: null
        })
        
        this.BranchCodeSearchForm.get('search_branch_code').valueChanges.
        pipe(debounceTime(300),tap(() => this.isBranchLoading = true),
        switchMap(value => this.db.FetchData({comp_value_search: value,comp_type:this.comp.type,user_inv:this.comp.order_info}, 'complaint/suggestive_search')
        .pipe(finalize(() => this.isBranchLoading = false),)))
        .subscribe(invoice => {
            console.log(invoice);
            this.filteredInvoice = invoice;
            this.filteredInvoice = this.filteredInvoice.data;
            console.log(this.filteredInvoice);
        });  
    }
    get e() { return this.addCustomerForm.controls; }
    level1_filter_array:any=[];
    level2_filter_array:any=[];
    level3_filter_array:any=[];
    get_product_item_unique_level()
    {
        // Unique level1
        const level1 = this.invoice_item.map(data => data.level1);
        this.unique_level1_list = level1.filter((x, i, a) => x && a.indexOf(x) === i);
        console.log(this.unique_level1_list);
        this.level1_filter_array=this.unique_level1_list;
        console.log(this.level1_filter_array);
        
        // Unique level2
        const level2 = this.invoice_item.map(data => data.level2);
        this.unique_level2_list = level2.filter((x, i, a) => x && a.indexOf(x) === i);
        console.log(this.unique_level2_list);
        this.level2_filter_array=this.unique_level2_list;
        console.log(this.level2_filter_array);
        
        // Unique level3
        const level3 = this.invoice_item.map(data => data.level3);
        this.unique_level3_list = level3.filter((x, i, a) => x && a.indexOf(x) === i);
        console.log(this.unique_level3_list);
        this.level3_filter_array=this.unique_level3_list;
    }
    
    selectedFile:any=[];
    select_file(event)
    {
        let total_files_size=0;
        console.log(event.target.files.length);
        
        for(var i=0; i<event.target.files.length; i++)
        {
            const file = event.target.files[i];
            console.log('size', file.size);
            console.log('type', file.type);
            let extension  = file.type.split('/').pop(); 
            console.log(extension);
            console.log(typeof(file.size));
            
            total_files_size = total_files_size + parseInt(file.size);
            if(parseInt(file.size)<=5242880)
            {
                if(extension=='png' || extension=='jpeg' || extension=='jpg' || extension=='gif' || extension=='tif' || extension=='pdf')
                {
                    this.selectedFile.push(event.target.files[i]);
                }
                
                else if(extension!='png' && extension!='jpeg' && extension!='jpg' && extension!='gif' && extension!='tif' && extension!='pdf')
                {
                    console.log("not pdf");
                    
                    if(extension=='vnd.openxmlformats-officedocument.wordprocessingml.document' || extension=='vnd.ms-powerpoint')
                    {
                        console.log("allow file");
                        this.selectedFile.push(event.target.files[i]);
                    }
                    else
                    {
                        console.log("error");
                        
                        this.toast.openError('The file type not allowed!!! Try Again...','');
                    }
                    
                }
                else
                {
                    this.toast.openError('The file type not allowed!!! Try Again...','');
                }
            }
            
            else
            {
                this.toast.openError('The file size not allowed more than 5mb !!! Try Again...','');
            }
        }
        
        console.log(total_files_size);
        
        if(total_files_size>5242880)
        {
            this.toast.openError('The files size not allowed more than 5mb !!! Try Again...','');
            this.selectedFile=[];
        }
        
        console.log(this.selectedFile);
        
    }
    
    delFile(name:any,index:any){
        console.log(name); 
        console.log(index);
        this.selectedFile.splice(index,1);
        console.log(this.selectedFile);
    }
    
    charonly(event: any){
        const pattern =/^[a-zA-Z ]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
    }
    
    
    digitonly(event: any){
        const pattern =/^[0-9]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
    }
    
    MobileNumber2(event: any)
    {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
    }
    
    GetStates()
    {
        this.db.FetchData({},'branch/get_states')
        .subscribe((result:any)=>{
            console.log(result);
            this.state_list = result['data'];
            console.log(this.state_list);
        },error=>{
            console.log(error);
        });
    }
    
    GetDistrict(state_name:any)
    {
        this.db.FetchData({'state_name':state_name},'branch/get_state_district')
        .subscribe(result =>{
            console.log(result);
            this.district_list = result['data'];
        },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
            
        });
    }
    
    

    digitonlyLoss(event: any){

       this.loss_val = event.target.value;
      
    }


    geaskorderinfo(val)
    {
        console.log(" i am in geask order function");
        console.log(val);
        console.log(this.invoicelist);
        console.log(this.invoicelist.length);
        this.checkedItems = [];
        if(val=='No')
        {
            console.log("val == No ");
            this.invoicelist=[];
            this.addComplaintForm.controls['service_engg'].setValue(0);
            this.addComplaintForm.controls['service_engg_name'].setValue('');
            
            this.addComplaintForm.controls['terms_condition'].setValue('');
            this.invoice_info={};
            this.invoice_item=[];
            //in case of product
            this.unique_level1_list=[];
            this.unique_level2_list=[];
            this.unique_level3_list=[];
            this.comp.product_item_level1='';
            this.comp.product_item_level2='';
            this.comp.product_item_level3='';
            this.InvoiceSearchForm.controls['search_invoice_no'].setValue(''); //NEW CHANGES
        }
        
        if(this.comp.type=='Product')
        {
            console.log("this.comp.type == Product");
            this.product_type_comp={};
            this.addCustomerForm.controls['dealer_name'].setValue('');
            this.addCustomerForm.controls['branch_name'].setValue('');
            this.invoicelist=[];
            
            if(this.comp.order_info=='No')
            {
                console.log(" this.comp.order_info=='No' ");
                this.get_dealer_data();
                this.serv_engg_list=[];
                this.InvoiceSearchForm.controls['search_invoice_no'].setValue('');
                this.CatCodeSearchForm.controls['search_cat_code'].setValue('');
                this.product_type_comp.product_item_level1='';
                this.product_type_comp.product_item_level2='';
                this.product_type_comp.product_item_level3='';
            }
            else
            {
                console.log(" i am in else");
                console.log(" dealer list empty");
                this.dealer_list=[];
                this.product_type_comp.product_item_level1='';
                this.product_type_comp.product_item_level2='';
                this.product_type_comp.product_item_level3='';
            }
        }

        if(this.comp.type=='Insurance Claim')
        {
            console.log("this.comp.type == Insurance Claim");
            this.product_type_comp={};
            this.addCustomerForm.controls['dealer_name'].setValue('');
            this.addCustomerForm.controls['branch_name'].setValue('');
            this.invoicelist=[];
            
            if(this.comp.order_info=='No')
            {
                console.log(" this.comp.order_info=='No' ");
                this.get_dealer_data();
                this.serv_engg_list=[];
                this.InvoiceSearchForm.controls['search_invoice_no'].setValue('');
                this.CatCodeSearchForm.controls['search_cat_code'].setValue('');
                this.product_type_comp.product_item_level1='';
                this.product_type_comp.product_item_level2='';
                this.product_type_comp.product_item_level3='';
            }
            else
            {
                console.log(" i am in else");
                console.log(" dealer list empty");
                this.dealer_list=[];
                this.product_type_comp.product_item_level1='';
                this.product_type_comp.product_item_level2='';
                this.product_type_comp.product_item_level3='';
            }
        }
    }
    
    dealer_list:any=[];
    get_dealer_data()
    {
        this.db.FetchData({},'complaint/get_dealer_data')
        .subscribe((result:any)=>{
            console.log(result);
            this.dealer_list = result['data'];
            console.log(this.dealer_list);
            this.filter_dealer_arr=this.dealer_list;
            this.loader = '';
        },error=>{
            console.log(error);
        });
    }
    
    set_product_comp_dealer_branch_info(dealer_code)
    {
        console.log("i am in selection change function");
        
        console.log(dealer_code);
        console.log('*** Filer Dealer array ***');
        console.log(this.filter_dealer_arr);
        var exist_info = this.filter_dealer_arr.filter(x=>x.dealer_code == dealer_code)[0];
        console.log(exist_info);
        if(exist_info && exist_info['branch_code'])
        {
            this.product_ServiceEngg(exist_info['branch_code']);
            this.addComplaintForm.controls['branch_code'].setValue(exist_info['branch_code']);
            this.addCustomerForm.controls['branch_name'].setValue(exist_info['branch_name']);
            this.addCustomerForm.controls['branch_mobile'].setValue(exist_info['branch_mobile']);
            this.addCustomerForm.controls['dealer_name'].setValue(exist_info['name']);
            this.addCustomerForm.controls['dealer_mobile'].setValue(exist_info['dealer_mobile']);
            this.addComplaintForm.controls['dealer_code'].setValue(exist_info['dealer_code']);
        }
        console.log(this.addComplaintForm);
    }
    
    filterdealer(data,array,index)
    {
        this.filter_dealer_arr=this.filter(data.toUpperCase(),array,index);
    }
    
    
    GetComplaintCategoryList()
    {
        this.loader = '1';
        
        this.db.FetchData({},'complaint/complaint_category_list')
        .subscribe((result:any)=>{
            console.log(result);
            this.complaint_category_list = result['data'];
            console.log(this.complaint_category_list);
            this.loader = '';
            
            
            if(this.db.coming_from=='back')
            {
                console.log(this.db.coming_from);
                console.log(this.db.complaint_type);
                this.comp.type=this.db.complaint_type;
                console.log(this.comp.type);
                this.loader = '';
            }
            
            else if(this.db.coming_from=='add')
            {
                this.comp.type = this.complaint_category_list[0].category_name;
                this.loader = '';
                console.log(this.complaint_category_list[0].category_name);
                console.log(this.comp.type);
            }
            
            console.log('comp type '+this.comp.type);
        },error=>{
            console.log(error);
            this.loader = '';
            
        });
    }
    
    set_search_filter()
    {
        this.comp.search_filter=!this.comp.search_filter;
    }
    
    next(comp_type)
    {
        console.log(comp_type);
        this.db.complaint_type=comp_type;
        this.addComplaintForm.controls['type'].setValue(comp_type);
        this.comp.type = comp_type;
        this.invoicelist=[];
        this.db.dbinvoice_list=[];
        this.invoice_info={};
        this.invoice_item=[];
        this.checkedItems=[];
        this.flag = '0';
        this.comp.order_info='';
    }
    
    validate(event:any)
    {
        const pattern = /^[0-9a-zA-Z]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
    }
    
    validate_fault_qty(event:any,index:any)
    {
        const pattern = /^[0-9]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar))
        {
            event.preventDefault();
        }
    }
    
    searchpresnt:any=0;
    invoicelist:any=[];
    unique_level1_list:any=[];
    unique_level2_list:any=[];
    unique_level3_list:any=[];
    
    
    Invoice(invoice_no: any)
    {
        this.invoice_info={};
        this.invoice_item=[];
        this.checkedItems=[];
        this.flag = '0';
        
        this.loader=1;
        this.db.FetchData({'invoice_no':invoice_no,'complaint_type':this.comp.type},'complaint/get_invoice_info')
        .subscribe((result:any)=>{
            console.log(result);
            this.loader='';
            if(result['message']=="Success" && result['data']['inv_data'].length>0)
            {
                this.searchpresnt=1;
                this.invoicelist = result['data']['inv_data'];
                this.db.dbinvoice_list = this.invoicelist;
                
                if(this.comp.type=='Product')
                {
                    this.setinvoice_detail(this.invoicelist[0].id,0);
                }
            }
            else
            {
                this.invoicelist=[];
                this.db.dbinvoice_list=[];
            }
        },error=>{
            console.log(error);
            this.loader='';
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
        
        this.product_type_comp.cat_code = '';
        this.product_type_comp.product_item_level1='';
        this.product_type_comp.product_item_level2='';
        this.product_type_comp.product_item_level3='';
        
        
    }
    
    product_type_comp:any={};
    get_product_type_comp_code(value,type)
    {
        console.log(" I AM IN FUNCTION ");
        console.log(this.invoice_item);
        console.log(value);
        console.log(type);
        
        if(type=='cat_code')
        {
            this.type=type;
            console.log(" iam catcode type");
            this.searchpresnt=1;
            this.exist_item = this.invoice_item.filter(x=>x.cat_code==value)[0];
            console.log(this.exist_item);
        }
        
        else if(type=='level1')
        {
            console.log(" iam in level 1");
            this.searchpresnt=1;
            this.exist_item = this.invoice_item.filter(x=>x.level1==value)[0];
            console.log(this.exist_item);
            this.product_type_comp.product_item_level1 = this.exist_item.level1;
        }
        
        else if(type=='level2')
        {
            console.log(" iam in level 2");
            this.searchpresnt=1;
            this.exist_item = this.invoice_item.filter(x=>x.level2==value)[0];
            console.log(this.exist_item);
            
        }
        else if(type=='level3')
        {
            console.log(" i am in level 3");
            this.searchpresnt=1;
            this.exist_item = this.invoice_item.filter(x=>x.level3==value)[0];
            console.log(this.exist_item);
            
        }
        this.product_type_comp.qty = 1;
        this.product_type_comp.qty = this.exist_item.qty;
        
        console.log(this.exist_item.description);
        this.product_type_comp.description = this.exist_item.description;
        console.log(this.product_type_comp.description);
        console.log(this.exist_item);
        this.product_type_comp = Object.assign({},this.exist_item);
        if(this.exist_item.unit_price)
        {
            this.product_type_comp.unit_price = this.exist_item.unit_price;
        }
        else
        {
            this.product_type_comp.unit_price = '';
        }
        
        if(this.exist_item.total_amt)
        {
            this.product_type_comp.total_amt = this.exist_item.total_amt;
        }
        else
        {
            this.product_type_comp.total_amt = '';
        }
        
        console.log(this.invoice_item);
        console.log(this.exist_item);
        
        this.product_type_comp.cat_code = this.exist_item.cat_code;
        this.product_type_comp.product_item_level1 = this.exist_item.level1;
        this.product_type_comp.product_item_level2 = this.exist_item.level2;
        this.product_type_comp.product_item_level3 = this.exist_item.level3;  
        
        this.product_type_comp.product_item_level1_code = this.exist_item.level1_code;
        this.product_type_comp.product_item_level2_code = this.exist_item.level2_code;
        this.product_type_comp.product_item_level3_code = this.exist_item.level3_code;
        this.product_type_comp.product_item_level3 = this.exist_item.level3;
        
        
        
        
        
        console.log(this.product_type_comp);
        
        if(this.comp.order_info=='No')
        {
            this.db.FetchData({cat_code: this.product_type_comp.cat_code,dealer_code: this.addComplaintForm.get('dealer_code').value,cust_name: this.addCustomerForm.get('cust_name').value,cust_mobile: this.addCustomerForm.get('cust_mobile').value}, 'complaint/get_product_comp_existence')
            .subscribe((result:any)=>{
                console.log(result);
                if(result['message']=="Success" && result['data'] && result['data']['complaint_id_encrypt'])
                {
                    this.OpenPopUp(0,result['data']['complaint_id_encrypt']);
                }
            },error=>{
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
        
    }
    
    search_date:any={};
    public onFromDate(event): void {
        console.log("hello");
        this.search_date.from = event.value;
        console.log(this.search_date.from);
        this.getinvoice_via_date();
    }
    
    public onToDate(event): void {
        console.log("hello");
        this.search_date.to = event.value;
        console.log(this.search_date.to);
        this.getinvoice_via_date();
    }
    
    public installation_Date(event): void {
        console.log("hello");
        this.product_type_comp.installation_Date = event.value;
        console.log(this.product_type_comp.installation_Date);
    }
    
    getinvoice_via_date()
    {
        this.db.FetchData({data: this.search_date}, 'complaint/get_invoice_list_viadatepicker')
        .subscribe((result:any)=>{
            console.log(result);
            if(result['message']=="Success" && result['data']['inv_data'].length>0)
            {
                this.invoicelist = result['data']['inv_data'];
                this.db.dbinvoice_list = this.invoicelist;
            }
            else
            {
                this.toast.openError('No Records Found !!! Try Again...','');
                this.norecordfound=1;
                this.invoicelist=[];
                this.db.dbinvoice_list=[];
            }
            console.log(this.invoicelist);
        },error=>{
            this.norecordfound=0;
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
        
    }
    filter_catcode_array:any=[];
    setinvoice_detail(inv_id,index)
    {
        this.flag = 1;
        this.invoice_info={};
        this.invoice_item=[];
        if(this.flag)
        {
            $('.temp_addcomplaint_active').removeClass('active');
            $('#add_complaint'+index).addClass('active');
            
            let exist_inv_detail = this.invoicelist.filter(x=>x.id>=inv_id)[0];
            console.log(exist_inv_detail);
            
            // commented
            // if(exist_inv_detail && exist_inv_detail.items && exist_inv_detail.items.length && exist_inv_detail.complaint_id && exist_inv_detail.complaint_status=='Open' && (this.tokenInfo.logged_in_type=='Customer_Care' || this.tokenInfo.logged_in_type=='Branch_User' || this.tokenInfo.role_name=='Administrator'))
            // {
            //     this.OpenPopUp(index,exist_inv_detail['complaint_id_encrypt']);
            // }
            if(exist_inv_detail && exist_inv_detail.items && exist_inv_detail.items.length && exist_inv_detail.complaint_id && exist_inv_detail.complaint_status=='Open' && this.tokenInfo.logged_in_type=='Dealer')
            {
                this.toast.openError('Complaint already exist!!','');
            }
            
            else if(exist_inv_detail && exist_inv_detail.items && exist_inv_detail.items.length)
            {
                console.log(exist_inv_detail);
                
                $('.tempactive').removeClass('active');
                $('#invoice'+index).addClass('active');
                this.invoice_info = Object.assign({},exist_inv_detail);
                this.addComplaintForm.controls['inv_id'].setValue(exist_inv_detail.id);
                this.addComplaintForm.controls['branch_code'].setValue(exist_inv_detail.branch_code);
                this.addCustomerForm.controls['branch_name'].setValue(exist_inv_detail.branch_name);
                this.addCustomerForm.controls['branch_mobile'].setValue(exist_inv_detail.branch_mobile);
                this.addComplaintForm.controls['dealer_code'].setValue(exist_inv_detail.dealer_code);
                this.addComplaintForm.controls['cust_code'].setValue(exist_inv_detail.cust_code);       
                this.addCustomerForm.controls['dealer_code'].setValue(exist_inv_detail.dealer_code);
                this.addCustomerForm.controls['dealer_name'].setValue(exist_inv_detail.dealer_name);
                this.addCustomerForm.controls['dealer_mobile'].setValue(exist_inv_detail.dealer_mobile);
                
                this.ServiceEngg(exist_inv_detail.branch_id);
                
                this.invoice_item = exist_inv_detail.items;
                this.filter_catcode_array=this.invoice_item;
                let temp_filter_catcode_array=this.invoice_item;
                
                console.log(this.invoice_item );
                console.log(this.filter_catcode_array);
                
                if(this.comp.type == 'Product')
                {
                    this.filter_catcode_array = [];
                    temp_filter_catcode_array.forEach(item => {
                        this.filter_catcode_array.push(item);
                    });
                    
                    this.invoice_item = this.filter_catcode_array;
                    
                    console.log(this.invoice_item);
                    
                    this.addCustomerForm.controls['branch_name'].setValue(exist_inv_detail['branch_name']);
                    this.addCustomerForm.controls['dealer_name'].setValue(exist_inv_detail['dealer_name']);
                    
                    // Unique level1
                    const level1 = this.invoice_item.map(data => data.level1);
                    this.unique_level1_list = level1.filter((x, i, a) => x && a.indexOf(x) === i);
                    
                    console.log(this.unique_level1_list);
                    
                    // Unique level2
                    const level2 = this.invoice_item.map(data => data.level2);
                    this.unique_level2_list = level2.filter((x, i, a) => x && a.indexOf(x) === i);
                    
                    console.log(this.unique_level2_list);
                    
                    
                    // Unique level3
                    const level3 = this.invoice_item.map(data => data.level3);
                    this.unique_level3_list = level3.filter((x, i, a) => x && a.indexOf(x) === i);
                }
                this.toast.openSucess('Invoice Data found....!!!','');
                this.showhiddendata=true;
            }
            else
            {
                if(this.comp.type == 'Logistic')
                {
                    this.toast.openError('Invoice Data not found!!','');
                }
                else if(this.comp.type == 'Logistic' && this.comp.order_info=='No')
                {
                    this.addComplaintForm.controls['service_engg'].setValue(0);
                    this.addComplaintForm.controls['service_engg'].setValue('');
                    this.addCustomerForm.controls['branch_moble'].setValue('');
                }
            }
        }
        else
        {
            $('.temp_addcomplaint_active').removeClass('active');
            this.invoice_info={};
            this.invoice_item=[];
        }
    }
    
    
    OpenPopUp(index,id)
    {
        console.log(index);
        console.log(id);
        this.dialogbox.EditItemPopup('complaint openCompItemDialogitem').then((result) => {
            console.log(result);
            if(result==true)
            {
                this.router.navigate(['/complaints-detail/'+id+'/'+'exist_comp']); 
            }     
        });
    }
    
    get_viewinvdetail(inv_no,index)
    {
        $('.temp_viewinv_active').removeClass('active');
        $('#view_invoice'+index).addClass('active');
    }
    
    emptyengg(){
        this.comp.engg_name="";
        this.comp.engg_mobile="";
        this.comp.service_engg  ="";
        console.log(this.comp);
        
    }

    getask_serv_engginfo(val)
    {
        console.log("i am in getask_serv function");
        console.log(val);
        console.log(this.comp.type);
        console.log(this.comp);
        

        if(val=='No' && (this.comp.type=='Logistic' || this.comp.type=='Insurance Claim' || this.comp.type=='Product'))
        {
            console.log("hello");

            this.addComplaintForm.controls['service_engg'].setValue(0);
            this.addComplaintForm.controls['service_engg'].setValue('');
            
            this.InvoiceSearchForm.controls['search_invoice_no'].setValue(0);
            this.CustomerSearchForm.controls['search_cust_name'].setValue(0);
            this.CatCodeSearchForm.controls['search_cat_code'].setValue(0);
            this.DealerCodeSearchForm.controls['search_dealer_code'].setValue(0);
            this.BranchCodeSearchForm.controls['search_branch_code'].setValue(0);
        }
    }
    
    getaskinfo(val)
    {
        if(val=='No')
        {
            this.addComplaintForm.controls['terms_condition'].setValue('');
        }
    }
    
    getCheckedRow(event,index,invindex,id,return_array)
    {
        console.log(index);
        console.log(return_array);
        
        this.invoice_item[index] = Object.assign({},this.invoice_item[index]);
        if(return_array && return_array.length)
        {
            console.log(this.invoice_item[index]);
            console.log(this.temp_item_fault_issue);
            
            
            this.invoice_item[index]['checked']=true;
            if(this.temp_item_fault_issue.length>0)
            {
                var exist_info = this.checkedItems.filter(x=>x.id>=id)[0];
                console.log(exist_info);
                
                if(exist_info && !exist_info['id'] && this.temp_item_fault_issue.length>0)
                {
                    this.checkedItems.push(this.invoice_item[index]);
                }
                else
                {
                    this.checkedItems[index]=this.invoice_item[index];
                }
                console.log( this.checkedItems);
                
            }
            else
            {
                this.toast.openError('Add At Least One Fault Item !!! Try Again...','');
            }
        }
        else
        {
            this.invoice_item[index]['checked']=false;
            console.log(this.invoicelist);
            
            this.invoicelist[invindex]['items'][index]['items_issue']=[];
            this.checkedItems.splice(index);
        }
        console.log(this.invoice_item[index]['checked']);
        
        this.temp_item_fault_issue=[];
        console.log(this.checkedItems);
    }
    
    
    
    temp_item_fault_issue=[];
    openCompItemDialog(index,item_id,order_qty,item_name,cat_code,item_issue) 
    {
        console.log(this.addComplaintForm);
        console.log('order qty' + order_qty);
        const dialogConfig = new MatDialogConfig();
        console.log(this.checkedItems);
        
        this.addComplaintForm.controls['type'].setValue(this.comp.type);
        
        if(this.comp.type=='Logistic')
        {
            if(this.checkedItems.length>0 && this.checkedItems[index])
            {
                dialogConfig.data = {
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:this.checkedItems[index]['items_issue'],
                    complaint_type:this.addComplaintForm.get('type').value,
                    level3_code:'',
                    level3:'',
                    item_issue:item_issue
                };
            }
            else
            {
                dialogConfig.data = {
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:[],
                    complaint_type:this.addComplaintForm.get('type').value,
                    level3_code:'',
                    level3:'',
                    item_issue:item_issue
                };
            }
        }

        else if(this.comp.type=='Insurance Claim'){
            if(this.checkedItems.length>0 && this.checkedItems[index])
            {
                dialogConfig.data = {
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:this.checkedItems[index]['items_issue'],
                    complaint_type:'Insurance Claim',
                    level3_code:'',
                    level3:'',
                    item_issue:item_issue
                };
            }
            else
            {
                dialogConfig.data = {
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:[],
                    complaint_type:'Insurance Claim',
                    level3_code:'',
                    level3:'',
                    item_issue:item_issue
                };
            }
        }
        
        else if(this.comp.type=='Product')
        {
            if(this.checkedItems.length>0 && this.checkedItems[index])
            {                
                dialogConfig.data = { 
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:this.checkedItems[index]['items_issue'],
                    complaint_type:this.addComplaintForm.get('type').value,
                    level3_code:this.product_type_comp.product_item_level3_code,
                    level3:this.product_type_comp.product_item_level3,
                    item_issue:item_issue
                };
            }
            else
            {
                dialogConfig.data = {
                    item_id: item_id,
                    order_qty: order_qty,
                    item_name: item_name,
                    cat_code: cat_code,
                    exist_issue:[],
                    complaint_type:this.addComplaintForm.get('type').value,
                    level3_code:this.product_type_comp.product_item_level3_code , 
                    level3:this.product_type_comp.product_item_level3,
                    item_issue:item_issue
                };
            }
        }
        
        const dialogRef = this.dialog.open(ComplaintItemActionComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            console.log(result);
            if(result && result.length>0)
            {
                this.temp_item_fault_issue = result;
                console.log(this.checkedItems);
                
                if(this.checkedItems.length>0 &&  this.checkedItems[index])
                {
                    this.checkedItems[index]['items_issue']=[];
                    for(var i=0;i<result.length;i++)
                    {
                        console.log(result[i]);
                        
                        this.checkedItems[index]['items_issue'].push(result[i]);
                    }
                    this.toast.openSucess('Issue Added Successfully','');
                }
                else
                {
                    console.log(index);
                    console.log(item_id);
                    this.getCheckedRow('',index,'',item_id,result);
                    this.checkedItems[index]['items_issue']=[];
                    for(var i=0;i<result.length;i++)
                    {
                        console.log(result[i]);
                        
                        this.checkedItems[index]['items_issue'].push(result[i]);
                    }
                }
                
                console.log(this.checkedItems);
            }
            else if(result && result.length==0)
            {
                this.temp_item_fault_issue = [];
                this.getCheckedRow('',index,'',item_id,' ');
            }
            console.log(this.checkedItems);
        });
    }
    
    
    product_ServiceEngg(branch_code)
    {
        console.log(branch_code);
        this.db.FetchData({'branch_code':branch_code},'complaint/product_service_engg_list')
        .subscribe((result)=>{
            console.log(result);
            this.serv_engg_list=result["data"]["engg_list"];
            console.log(this.serv_engg_list);
        },error=>{
            console.log("error");
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
    }
    
    ServiceEngg(data)
    {
        console.log(data);
        this.db.FetchData({'branch_id':data},'complaint/service_engg_list')
        .subscribe((result)=>{
            console.log(result);
            this.serv_engg_list=result["data"]["engg_list"];
            console.log(this.serv_engg_list);
        },error=>{
            console.log("error");
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
        
    }
    
    branch_list:any=[];
    GetBranchlist()
    {
        console.log();
        this.db.FetchData({},'branch/branch_list')
        .subscribe((result)=>{
            console.log(result);
            this.branch_list=result["data"]["list"];
            console.log(this.branch_list);
        },error=>{
            console.log("error");
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
    }
    
    Getmobile(engg_id)
    {
        console.log(engg_id);
        console.log(this.serv_engg_list);
        
        this.comp.service_engg = engg_id; 
        
        this.comp.engg_mobile = this.serv_engg_list.filter(x=>x.id==engg_id)[0]['mobile'];
        this.comp.engg_name = this.serv_engg_list.filter(x=>x.id==engg_id)[0]['name'];
        
        console.log(this.comp.engg_mobile);
    }
    
    custmobflag:any=0;
    getcust_no(mobile,dealer_code)
    {
        console.log(dealer_code);
        this.db.FetchData({'mobile':mobile,'dealer_code':dealer_code},'dealer/check_customer_mobile_existence')
        .subscribe((result:any)=>{
            console.log("first");
            console.log(result);
            if(result['message']=="Success")
            {
                console.log('Customer already exists!');
                this.custmobflag=1;
            }
            else
            {
                console.log("do not Exists!");
                this.custmobflag=0;
                if(this.comp.order_info=='No')
                {
                    this.db.FetchData({cat_code: this.product_type_comp.cat_code,dealer_code: this.addComplaintForm.get('dealer_code').value,cust_name: this.addCustomerForm.get('cust_name').value,cust_mobile: mobile}, 'complaint/get_complain_exist')
                    .subscribe((result:any)=>{
                        console.log(result);
                        if(result['message']=="Success" && result['data'] && result['data']['complaint_id_encrypt'])
                        {
                            this.OpenPopUp(0,result['data']['complaint_id_encrypt']);
                        }
                        
                    },error=>{
                        this.toast.openError('Something went wrong !!! Try Again...','');
                    });
                }
            }
        },error=>{console.log(error);
        }
        );
    }
    
    getcust_name(name,dealer_code)
    {
        if(this.comp.order_info=='No')
        {
            this.db.FetchData({cat_code: this.product_type_comp.cat_code,dealer_code: this.addComplaintForm.get('dealer_code').value,cust_name: name,cust_mobile: this.addCustomerForm.get('cust_mobile').value}, 'complaint/get_product_comp_existence')
            .subscribe((result:any)=>{
                console.log(result);
                if(result['message']=="Success" && result['data'] && result['data']['complaint_id_encrypt'])
                {
                    this.OpenPopUp(0,result['data']['complaint_id_encrypt']);
                }
                
            },error=>{
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
    }
    delete_cart_item(index)
    {
        console.log(index);
        this.dialogbox.delete('Item !').then((result) => {
            console.log(result);
            if(result)
            {
                this.checkedItems[0]['items_issue'].splice(index,1);
            }
        });
    }
    
    formData=new FormData(); 
    insert:any=true;
    submitComplaint()
    {
        console.log(this.LossValue);
        console.log(this.addCustomerForm.value);
        console.log(this.comp);
        console.log(this.tokenInfo.logged_in_type);
        this.addCustomerForm.value['dealer_code'] = this.addComplaintForm.value['dealer_code'];

        if(this.loss_val){
            this.addComplaintForm.controls['loss_value'].setValue(this.loss_val);
        }
        
        if(this.tokenInfo.logged_in_type=='Dealer' && this.comp.type=='Product')
        {
            this.addComplaintForm.controls['branch_code'].setValue(this.tokenInfo.branch_code);
            this.addComplaintForm.controls['dealer_code'].setValue(this.tokenInfo.logged_in_code);
            this.addCustomerForm.controls['dealer_code'].setValue(this.tokenInfo.logged_in_code);
            
        }
        
        this.submitted = true;
        if(this.submitted ==true && this.insert == true)
        {
            var j=0;
            console.log(this.checkedItems);  
            console.log(this.addComplaintForm.value); 
            console.log(this.addCustomerForm.value); 
            
            // stop here if form is invalid            
            if(this.comp.type=='Logistic' || this.comp.type=='Insurance Claim')
            {
                this.addComplaintForm.controls['complaint_items'].setValue("");

                console.log(this.addComplaintForm.invalid);
                console.log(this.addCustomerForm.invalid);
                
                if (this.addComplaintForm.invalid ) {
                    console.log("*****invalid data*****");
                    return;
                }
                else
                {
                    this.add_complaint();
                }
            }
            else if(this.comp.type=='Insurance Claim')
            {
                this.addComplaintForm.controls['complaint_items'].setValue("");

                console.log(this.addComplaintForm.invalid);
                console.log(this.addCustomerForm.invalid);
                
                if (this.addComplaintForm.invalid ) {
                    console.log("*****invalid data*****");
                    return;
                }
                else
                {
                    this.add_complaint();
                }
            }
            else if(this.comp.type=='Product')
            {
                if (this.addComplaintForm.invalid || this.addCustomerForm.invalid ) {
                    console.log("*****invalid data*****");
                    return;
                }
                else
                {
                    this.add_complaint();
                }
            }
            this.insert = false;
        } 
    }
    
    add_complaint()
    {
        //if valid value display in console      
        console.log(this.checkedItems);
        if(this.comp.service_engg)
        {
            this.addComplaintForm.controls['service_engg'].setValue(this.comp.service_engg);
            this.addComplaintForm.controls['service_engg_name'].setValue(this.comp.engg_name );
        }
        else
        {
            this.addComplaintForm.controls['service_engg'].setValue(0);
            this.addComplaintForm.controls['service_engg_name'].setValue('');
        }
        
        if(this.comp.terms_condition)
        {
            this.addComplaintForm.controls['terms_condition'].setValue(this.comp.terms_condition);
        }
        else
        {
            this.addComplaintForm.controls['terms_condition'].setValue('');
        }
        
        console.log(this.comp);
        if(this.comp.type=='Insurance Claim'){
            this.comp.order_info ='Yes';
        }
        if(((this.comp.type=='Insurance Claim'  || this.comp.type=='Logistic' || this.comp.type=='Product') && this.comp.order_info=='Yes'))
        {
            console.log("m in if");
            let final_checkeditems = [];
            if(this.comp.type=='Logistic' || this.comp.type=='Insurance Claim' )
            {
                this.checkedItems.forEach(item => {
                    final_checkeditems.push(item)
                    console.log(item);
                });
                this.addComplaintForm.controls['complaint_items'].setValue(final_checkeditems);
            }
            else
            {
                let temp_product_array = {};
                let temp_checked_items= [];
                
                temp_product_array['level1'] = this.product_type_comp.product_item_level1;
                temp_product_array['level2']= this.product_type_comp.product_item_level2;
                temp_product_array['level3'] = this.product_type_comp.product_item_level3;
                temp_product_array['batch_no'] = this.addCustomerForm.get('batch_no').value;
                
                temp_product_array['level1_code'] = this.product_type_comp.product_item_level1_code;
                temp_product_array['level2_code']= this.product_type_comp.product_item_level2_code;
                temp_product_array['level3_code'] = this.product_type_comp.product_item_level3_code;
                
                temp_product_array['unit_price'] = '';
                temp_product_array['total_amt']  = '';
                temp_product_array['cat_code']  = this.product_type_comp.cat_code;
                temp_product_array['description']  = this.product_type_comp.description;
                temp_product_array['items_issue']  = this.checkedItems[0].items_issue;
                
                console.log(this.checkedItems);
                console.log(this.checkedItems[0].items_issue);
                
                temp_checked_items.push(temp_product_array);
                this.addComplaintForm.controls['complaint_items'].setValue(temp_checked_items);
            }
        }
        else if(this.comp.type=='Product' && this.comp.order_info=='No')
        {
            let temp_product_array = {};
            let temp_checked_items= [];
            
            temp_product_array['level1'] = this.product_type_comp.product_item_level1;
            temp_product_array['level2']= this.product_type_comp.product_item_level2;
            temp_product_array['level3'] = this.product_type_comp.product_item_level3;
            temp_product_array['batch_no'] = this.addCustomerForm.get('batch_no').value;
            
            temp_product_array['level1_code'] = this.product_type_comp.product_item_level1_code;
            temp_product_array['level2_code']= this.product_type_comp.product_item_level2_code;
            temp_product_array['level3_code'] = this.product_type_comp.product_item_level3_code;
            
            temp_product_array['unit_price'] = '';
            temp_product_array['total_amt']  = '';
            if(this.product_type_comp.cat_code!='')
            {
                temp_product_array['cat_code']  = this.product_type_comp.cat_code;
            }
            else
            {
                temp_product_array['cat_code'] = this.CatCodeSearchForm.get('search_cat_code').value;
            }
            temp_product_array['description']  = this.product_type_comp.description;
            temp_product_array['items_issue']  = this.checkedItems[0].items_issue;
            
            temp_checked_items.push(temp_product_array);
            this.addComplaintForm.controls['complaint_items'].setValue(temp_checked_items);
        }   

        console.log(this.addComplaintForm.value);
        console.log(this.addCustomerForm.value);
        
        
        this.db.FetchData({'data':this.addComplaintForm.value,'customer':this.addCustomerForm.value},'complaint/add_complaint')
        .subscribe((result)=>{
            console.log(result); 
            if(result['message']=="Success")
            {
                if(result['complaint_id'] && this.selectedFile && this.selectedFile.length)
                {
                    var complaint_id = result['complaint_id'];
                    if(complaint_id)
                    {
                        this.formData.append("complaint_id",complaint_id);
                        this.formData.append("created_by",this.tokenInfo.id);
                        this.formData.append("created_by_type",this.tokenInfo.logged_in_type);
                        
                        for(var i=0; i<this.selectedFile.length; i++)
                        {
                            this.formData.append("image"+i ,this.selectedFile[i],this.selectedFile[i].name);
                        }
                        console.log(this.formData);
                        this.db.FileData(this.formData,"complaint/upload_complaint_document")
                        .subscribe((result:any) =>{
                            console.log(result);
                        },error=>{
                            console.log(error);
                        });
                    }
                }
                
                this.toast.openComplaint_Sucess('Complaint CCRP00'+ result['complaint_id']+' '+'Added Sucessfully','');
                if(this.tokenInfo.logged_in_type=='Dealer')
                {
                    this.router.navigate(['/dealer-main-complaint-list']); 
                }
                else
                {
                    this.router.navigate(['/complaints-list']); 
                }
            }
        },error=>{
            console.log("error");
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
    }
    
    new_array:any=[];
    fliterCatCode(data,code_array,key)
    {
        this.filter_catcode_array=this.filter(data.toUpperCase(),code_array,key);
        console.log(this.filter_catcode_array);
    }
    
    filter(search_data,search_array,index)
    {
        this.new_array=[];
        for(let i=0;i<search_array.length;i++)
        {
            if(search_array[i][index].toUpperCase().search(search_data)!==-1)
            {
                this.new_array.push(search_array[i]);
            }
        }
        return this.new_array;
    }
    fliterLevel1(data,level1_array)
    {
        this.level1_filter_array=this.filter_levels(data.toUpperCase(),level1_array);
        console.log(this.level1_filter_array);
    }
    
    fliterLevel2(data,level2_array)
    {
        this.level2_filter_array=this.filter_levels(data.toUpperCase(),level2_array)
        console.log(this.level2_filter_array);
    }
    fliterLevel3(data,level3_array)
    {
        this.level3_filter_array=this.filter_levels(data.toUpperCase(),level3_array)
        console.log(this.level3_filter_array);
    }
    filter_levels(search_data,search_array)
    {
        this.new_array=[];
        for(let i=0;i<search_array.length;i++)
        {
            if(search_array[i].toUpperCase().search(search_data)!==-1)
            {
                this.new_array.push(search_array[i]);
            }
        }
        return this.new_array;
    }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html'
})
export class CustomerListComponent implements OnInit {

  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  Cust_Data:any=[];
  data:any=[];
  dealer_name:string='';
  tokenInfo:any={};
  dealer_id:any;
  sendRequest:any=true;
  

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent) {
    this.dealer_name = this.db.dealer_name;

    this.tokenInfo = this.db.tokenInfo;
    if(this.tokenInfo.logged_in_type=='Dealer')
    {
      this.dealer_id=this.tokenInfo.id;
    }
    else
    {
      this.dealer_id=this.db.dealer_id;
    }
  
   }

  ngOnInit() {
    this.getCustomerList(this.dealer_id);
  }

  search_master(master:any)
  {
    this.search.master=master;
    console.log(this.search.master);
    this.sendRequest=false;

    this.getCustomerList(this.dealer_id);
  }

  getCustomerList(dealer_id , pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = '1';
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(dealer_id);

    
    if(action == "refresh")
    {
        this.search = {};
        this.start = 0;
    }
    this.db.FetchData({'dealer_id':dealer_id,'search':this.search},'dealer/get_dealer_customer')
    .subscribe((result: any) => {
      console.log(result);
      
      this.Cust_Data = result['data']['customers'];
      console.log(this.Cust_Data);
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';      
    });
  }

  delete_customer(index:any,id:any)
  {
    console.log(id);    
    this.dialog.delete('Customer !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'dealer/delete_customer/'+id).subscribe((response)=>{
          console.log(response);
          this.Cust_Data.splice(index,1);
          this.toast.openSucess('Customer Deleted Sucessfully ','');      

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.Cust_Data);
  }

}

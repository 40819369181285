import { Component, OnInit,ElementRef, HostListener, Input  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-system-user-detail',
  templateUrl: './system-user-detail.component.html'
})
export class SystemUserDetailComponent implements OnInit {
  addSystemUserForm: FormGroup;
  encrypt_id:any = '';
  detail: any = {};
  member_list:any=[];
  loader:any = '';  
  department: any=[];
  flag:any=0;
  system_logs:any=[];
  hide=true;
  role_list:any=[];
  token_info:any={};
  constructor(private formBuilder: FormBuilder,public db:DatabaseService, public route:ActivatedRoute, public router:Router,public toast: SnackBarOverview,private eRef: ElementRef) { 
    this.GetDepartment();
    this.GetRolelist();
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
  }
  
  ngOnInit() {
    this.addSystemUserForm = this.formBuilder.group({
      name: [''],
      code: [''],
      type:[''],
      mobile_no:[''],
      email:[''],
      department:[''],
      username:[''],
      // ,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      password:[''],
      role_id:[]

    });
    this.GetSystemUserDetail();
    this.token_info=this.db.tokenInfo;

  }
  get e() { return this.addSystemUserForm.controls; }
  
  @HostListener('click') onClick() {
  }
  
  GetDepartment()
  {
    this.db.FetchData({},'master/department_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.department = result['data'];
      console.log(this.department);
    },error=>{
      console.log(error);
    });
  }
  
  GetSystemUserDetail() {
    this.loader = "1";
    
    this.db.FetchData({'user_id': this.encrypt_id }, 'user/user_detail')
    .subscribe((result: any) => {
      console.log(result);
      this.detail = result['data']['detail'];
      this.member_list = result['data']['member_list'];
      
      this.system_logs = result['data']['logs'];
      this.loader = '';
      this.db.sys_user_id = this.encrypt_id;
      this.db.sys_user_name = this.detail['name'];
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      
    });
  }
  GetRolelist()
      {
        this.db.get_rqst('master/get_master_roles')
        .subscribe((result:any)=>{
          console.log(result);
          this.role_list = result['data'];
          console.log(this.role_list);
        },error=>{
          console.log(error);
        });
      }
  active: any = {}; 
  // for update..  
  // this.active.mobile_no = "0"; this.active.email = "0"; this.active.department = "0"; this.active.designation = "0";
  edit_mobile_no() { this.active = {}; this.active.mobile_no = "1";}
  edit_email() { this.active = {}; this.active.email = "1";}
  edit_department() { this.active = {};  this.active.department = "1"; this.flag="1";}
  edit_designation() {this.active = {};this.active.designation = "1";}
  edit_username() { this.active = {};this.active.username = "1"; }
  edit_password() {this.active = {}; this.active.password = "1";}
  edit_user_role(){this.active = {}; this.active.role = "1";}
  edit_user_type(){ this.active = {}; this.active.type = "1";}

  passerr:any=false;

  update_user() 
  {
    if (this.addSystemUserForm.invalid)
    {
      console.log("**invalid data***");
      this.passerr = true;
      return;
    }
    else
    { 
      this.flag='0';
      console.log(this.detail);
      this.db.FetchData({ 'data': this.detail }, 'user/update_user')
      .subscribe((result: any) => {
        console.log(result);
        if (result['message'] == "Success") {
          console.log("Updated!");
          this.passerr = false;
          this.toast.openSucess('Updated Sucessfully ','');
          this.GetSystemUserDetail();
        }
        else {
          this.toast.openError('Something went wrong !!! Try again... ','');        
        }
      }, error => {
        console.log(error);
      });
      this.active = {};
    }    
  }
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    // String.fromCharCode(65, 66, 67);  // returns "ABC"
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  delete_serv_engg(user_id){
    console.log(user_id);
    // this.db.get_rqst('user/delete_user/'+user_id)
    // .subscribe(res => {
    //   console.log(res);
    //   this.toast.openSucess('Row Deleted Sucessfully ','');    
    //   this.router.navigate(['/system-user-list']);
      
    // },err=>{
    //   console.log(err);
    //   this.toast.openError('Something went wrong !!! Try again... ','');      
    // });
  }
  
}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-add-complaint-category-dialog',
  templateUrl: './add-complaint-category-dialog.component.html',
  styleUrls: ['./add-complaint-category-dialog.component.scss']
})
export class AddComplaintCategoryDialogComponent implements OnInit {
  addMasterCatForm: FormGroup;
  
  role_id:any;
  get_category_array:any=[];
  result_data: any=[];
  product_levels: any=[];
  product_is_there: boolean=false;
  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public toast: SnackBarOverview,public dialogbox:DialogComponent,private dialogRef:MatDialogRef<AddComplaintCategoryDialogComponent>,@Inject(MAT_DIALOG_DATA)data) {
    this.role_id = data.role_id;
    this.get_category_array = data.category_array;
    console.log(this.role_id);
    console.log(this.get_category_array);
    
  }
  
  ngOnInit() {
    this.addMasterCatForm = this.formBuilder.group({
      role_id: [this.role_id,Validators.required],
      category_array: [[],Validators.required],
    });
    this.getProductLevel();
    
  }
  get f() { return this.addMasterCatForm.controls; }
  
  selected_product_level:any=[];
  changed_product_level(event)
  {
    
    console.log(event.value);
    this.selected_product_level=[];
    for(var i=0;i<event.value.length;i++)
    {
      let filterRowPermission = this.product_levels.filter(x => x.id == event.value[i])[0];
      console.log(filterRowPermission);
      this.selected_product_level.push({'level_id': filterRowPermission['id'],'level_name':filterRowPermission['text']});
    }
    console.log(this.selected_product_level);
  }
  
  
  saveCategory(selectedArr)
  {
    console.log( this.addMasterCatForm.get('category_array').value);
    console.log(selectedArr.value);
    console.log(this.addMasterCatForm['category_array']);
    
    let temp_perm = [];
    console.log(temp_perm);
    for(var i=0;i<selectedArr.value.length;i++)
    {
      let filterRowPermission = this.get_category_array.filter(x => x.category_id == selectedArr.value[i])[0];
      console.log(filterRowPermission);
      
      if(selectedArr.value[i]=='2')
      {
        temp_perm.push({'comp_category_id': filterRowPermission['category_id'],'comp_category_name':filterRowPermission['category_name'],'product_level':this.selected_product_level});
      }
      
      else
      {
        temp_perm.push({'comp_category_id': filterRowPermission['category_id'],'comp_category_name':filterRowPermission['category_name'],'product_level':[]});
      }
    }
    this.addMasterCatForm.controls['category_array'].setValue(temp_perm);
    if (this.addMasterCatForm.invalid) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { 
      console.log(this.addMasterCatForm.value);
      
      this.db.FetchData({'data':this.addMasterCatForm.value},'master/add_master_role_comp_category')
      .subscribe((result:any)=>{
        console.log(result);
        this.result_data=result;
        this.dialogRef.close(this.result_data);
      },error=>{console.log(error);
      });
    }
  }
  
  getProductLevel()
  {
    this.db.FetchData({},'master/get_product_level')
    .subscribe((resp:any)=>{
      console.log(resp);
      this.product_levels = resp['data'];
    });
  }
  data:any=[];
  // check for category array has product(2) or not
  CheckForProductCat(array)
  {
    console.log(array.value);
    this.data=array.value;
    console.log(this.product_is_there);
    
    if(array.value.find(ob => ob === '2'))
    this.product_is_there=true;
    else
    this.product_is_there=false;
    
  }
}

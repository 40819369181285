import { Component, OnInit,ElementRef, HostListener, Input  } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
    selector: 'app-branch-detail',
    templateUrl: './branch-detail.component.html'
})
export class BranchDetailComponent implements OnInit {
    
    addBranchForm: FormGroup;
    encrypt_id:any = '';
    detail: any = {};
    details:any = {};  //for service engineer
    branch_service_engg: any =[];
    branch_dealers:any=[];
    complaint:any;
    branch_logs:any=[];
    loader:any;
    service_engg:any={};
    hide=true;
    status: string;
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    district_list: any=[];
    state_list: any=[];
    
    constructor(private formBuilder: FormBuilder,public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog:DialogComponent) { 
        this.route.params.subscribe(params=>{
            console.log(params);
            this.encrypt_id = params.id;
            console.log(this.encrypt_id);
        });
        this.GetStates();
        
    }
    
    ngOnInit() {
        this.addBranchForm = this.formBuilder.group({
            name: [''],
            code: [''],
            phone:[''],
            street:[''],
            city:[''],
            state:[''],
            district:[''],
            country:[''],
            pincode:[''],
            region_name:['']
            
        });
        this.GetBranchDetail();
    }
    
    @HostListener('click') onClick() {
        
    }
    get e() { return this.addBranchForm.controls; }
    
    active: any = {}; 
    actives:any = {};
    
    edit_phone() {this.active = {}; this.active.phone = "1";}
    edit_contact_person() {this.active = {}; this.active.contact_person = "1"; }
    edit_street() { this.active = {}; this.active.street = "1";}
    edit_city()  { this.active = {}; this.active.city="1";}
    edit_pincode() { this.active = {}; this.active.pincode="1";}
    edit_state()  { this.active = {}; this.active.state="1";}
    edit_district()  { this.active = {}; this.active.district="1";}
    
    // edit_email() { this.active.phone = "0"; this.active.street = "0"; this.active.email = "1"; this.active.contact_person = "0";  }
    
    
    edit_engg(index)
    { 
        console.log(this.branch_service_engg[index]);
        if(this.branch_service_engg[index])
        {  
            
            this.branch_service_engg[index]['active']=1;  
        }
        
        this.active=1;
        console.log("EDIT"); 
    }
    edit_engg_name(){ this.actives.name ="1"; }
    edit_engg_mobile(){ this.actives.name = "1"; }
    cancel(index){ this.actives.name = "0"; this.actives.mobile = "0"; this.actives.email = "0"; this.actives.street = "0"; console.log("CANCEL"); this.branch_service_engg[index]['active']=0;
} 
GetBranchDetail() 
{
    this.loader = '1';
    this.db.FetchData({'branch_id': this.encrypt_id }, 'branch/branch_detail')
    .subscribe((result: any) => {
        console.log(result);
        this.detail = result['data']['detail'];
        this.branch_service_engg = result['data']['engg'];
        this.branch_dealers = result['data']['dealers'];
        this.complaint = result['data']['complaint'];
        this.branch_logs = result['data']['logs'];
        this.db.branch_id = this.encrypt_id;
        this.db.branch_name = this.detail['name'];
        this.loader = '';
    }, error => {
        console.log(error);
        this.toast.openError('Something went wrong !!! Try again... ','');
        this.loader = '';
    });
}

GetStates()
{   
    this.db.FetchData({},'branch/get_states')
    .subscribe(result=>{
        console.log(result);
        this.state_list=result['data']; 
    });
}
getdistrict(state_name:any)
{    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
    this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
        console.log(result);
        this.district_list=result['data'];
    });
}

function(status,index,id,role)
{
    this.dialog.confirmation('Status!').then((result) => {
        console.log(result);
        if(result)
        {
            if(status=='Active')
            this.branch_service_engg[index]['status'] = 'Deactive';
            else if(status=='Deactive')
            this.branch_service_engg[index]['status'] = 'Active';
            
            this.db.FetchData({'user_id':id,'status':status,'user_role':role},'BranchUser/change_status').subscribe((response)=>{
                console.log(response);
                if(response['message']=='Success')
                {
                    this.toast.openSucess('Status Changed Successfully','');
                }
            },err=>{
                console.log(err);
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
    });
}

public toggle(event,status,index,id) 
{
    console.log('toggle', event.checked);
    // this.useDefault = event.checked;
    this.dialog.confirmation('Status!').then((result) => {
        console.log(result);
        console.log(id);
        if(result==true)
        {
            console.log("if");
            if(event.checked==true)
            this.branch_service_engg[index]['status'] = 'Active';
            else if(event.checked==false)
            this.branch_service_engg[index]['status'] = 'Deactive';
            this.db.FetchData({'user_id':id,'status':this.branch_service_engg[index]['status'],'user_role':this.branch_service_engg[index]['user_role'] },'BranchUser/change_status').subscribe((response)=>{
                console.log(response);
                if(response['message']=='Success')
                {
                    this.toast.openSucess('Status'+' '+ this.branch_service_engg[index]['status']+ ' '+ 'Successfully','');
                }
            },err=>{
                console.log(err);
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
        else
        {
            console.log("else");
            console.log(status);
            this.branch_service_engg[index]['status'] = status;
            if(status=='Active')
            event.checked = true;
            else
            event.checked = false;
            console.log('toggle', event.checked);
            console.log(this.branch_service_engg[index]['status']);
        }
    });
}



update_branch() 
{
    if (this.addBranchForm.invalid)
    {
        console.log("**invalid data***");
        return;
    }
    else
    { 
        this.loader = '1';
        console.log(this.detail);
        this.db.FetchData({ 'data': this.detail }, 'branch/update_branch')
        .subscribe((result: any) => {
            console.log(result);
            if (result['message'] == "Success") {
                console.log("Updated!");
                this.toast.openSucess('Updated! ','');
                this.GetBranchDetail();
            }
            else {
                console.log("X something went wrong! ");
                this.toast.openError('Something went wrong !!! Try again... ','');
            }
        }, error => {
            console.log(error);
        });
        this.active = {};
    }    
}

update_engg(index:any) {
    console.log("Update");
    
    // this.active=0;
    this.branch_service_engg[index]['active']=0;
    console.log(index);
    console.log(this.branch_service_engg);
    
    // let filteredData = this.branch_service_engg[index];
    
    let filteredData = Object.assign({},this.branch_service_engg[index]);
    console.log(filteredData);
    this.loader = '1';
    console.log(this.details);
    this.db.FetchData({ 'data': filteredData }, 'BranchUser/update_branch_user')
    .subscribe((result: any) => {
        console.log(result);
        if (result['message'] == "Success") {
            console.log("Updated!");
            this.toast.openSucess('Updated! ','');
        }
        else {
            console.log("X something went wrong! ");
            this.toast.openError('Something went wrong !!! Try again... ','');
        }
        this.loader = '';
        
    }, error => {
        console.log(error);
        this.loader = '';
        
    });
    this.active = {};
}    

MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
}

//function for name: it should not contain any number
charonly(event: any){
    const pattern =/^[a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
}

delete(branch_id){
    console.log(branch_id);
    this.dialog.delete('Branch !').then((result) => {
        console.log(result);
        if(result)
        {
            this.db.get_rqst('branch/delete_branch/'+branch_id)
            .subscribe(res => {
                console.log(res);
                this.toast.openSucess('Branch Deleted Successfully','');   
                this.router.navigate(['/branch-list']);
            },err=>{
                console.log(err);
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
    });
}

delete_serv_engg(user_id,index){
    
    console.log("Delete");
    console.log("Engg_id:");
    console.log(user_id);
    this.dialog.delete('Branch User!').then((result) => {
        console.log(result);
        if(result)
        {
            this.db.get_rqst('BranchUser/delete_branch_user/'+user_id)
            .subscribe((result:any)=>{
                console.log(result);
                this.branch_service_engg.splice(index,1)
                this.toast.openSucess('Branch User Deleted Successfully','');     
            },error=>{
                console.log(error);
                this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
    });
}
getuserlist(){
    console.log("I m fetching user list");
    // this.db.FetchData({'branch_id':branch_id},'BranchUser/get_branchuser_dealers')
    // .subscribe((result: any) => {
    //   console.log(result);
    //   this.branch_dealers_list = result['data']['dealers'];
    //   console.log(this.branch_dealers_list);
    
    //   this.loader = '';
    // }, error => {
    //   console.log(error);
    //   this.toast.openError('Something went wrong !!! Try again... ','');
    //   this.loader = '';      
    // });
    
}

statusChange(status,id,index){
    console.log(status);
    console.log(id);
    this.dialog.statusChangeConfirmation('status').then((result) => {
        console.log(result); //true
        if(result)
        {
            this.db.FetchData({'serv_engg_id': id,'status':status,"branch":this.detail.branch_code},'BranchUser/chenge_user_status')
            .subscribe(res => {
                console.log(res);
                if(res['message']=='Success')
                {
                    this.toast.openSucess('Status Changed Sucessfully ','');      
                    this.GetBranchDetail();
                }
            }
            ,err=>{
                console.log(err);
                this.toast.openError('Something went wrong !!! Try again... ','');      
            }
            );
        }
    });
}
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import moment from 'moment';
import { SnackBarOverview } from '../Toast';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
    
    constructor(public db:DatabaseService,public toast: SnackBarOverview) { }
    
    output_filter:any = {date_created:true,created_by:true,complaint_id:true,type:true,dealer_and_customer_info:false,branch:false,no_of_days:false,pending_at:false,total_item:false,invoice_no:true,status:false,level1:false,internal_tickets:false,installation_location :false};
    data:any = {};
    settings:any=false;
    loader:any=false;

    highestPartCount: any = 0;
    highestRemarkCount: any =0;


    ngOnInit() {
        this.get_status_list();
        this.get_branch_user();
        this.get_branch();
        this.get_department();
        this.department_data('')
        this.get_issue();
        this.get_level1();
    }
    
    status_list:any=[]; 
    get_status_list()
    {
        this.db.FetchData({},'complaint/get_status_list')
        .subscribe(resp=>{
            console.log(resp);
            this.status_list = resp['data'];
        });
    }
    
    
    branch_list:any=[]; 
    get_branch()
    {
        this.db.FetchData({},'branch/branch_list')
        .subscribe((result:any)=>{
            console.log(result);
            this.branch_list = result['data']['list'];
        });
    }
    
    user_list:any=[];
    get_branch_user()
    {
        this.db.FetchData({"branch_code" : this.data.complaint_branch,"user_type" : this.data.branch_user_type},"report/get_branch_system_user")
        .subscribe(resp=>{
            console.log(resp);
            this.user_list = resp['data'];
            if(this.user_list.length == 0)
            {
                this.toast.openError("Branch Users not found!","")
            }
        });
    }
    
    department_list:any = [];
    get_department()
    {
        this.db.FetchData({},'master/department_list')
        .subscribe((resp:any)=>{
            console.log(resp);
            this.department_list = resp['data']
        });
    }
    
    department_data(row)
    {
        if(row)
        {
            this.data.department_name=row.department_name;
        }
        else
        {
            this.data.department_name='ALL';
        }
        this.get_department_user();
    }
    
    depart_user:any=[];
    get_department_user()
    {
        this.data.department_user = '';
        this.db.FetchData({"department":this.data.department_name,"user_type":this.data.department_user_type},"report/get_department_user")
        .subscribe(resp=>{
            console.log(resp);
            this.depart_user = resp['data'];
            if(this.depart_user.length == 0)
            {
                this.toast.openError("Department Users not found !","");
            }
        });
    }
    
    issues_list:any=[];
    get_issue()
    {
        this.db.FetchData({"complaint_type":this.data.complaint_type},"report/get_issues")
        .subscribe(resp=>{
            console.log(resp);
            this.issues_list = resp['data'];
        })
    }
    
    
    level1_list:any=[];
    get_level1()
    {
        this.db.FetchData({},"report/get_level1")
        .subscribe(resp=>{
            console.log(resp);
            this.level1_list = resp['data'];
        })
    }
    
    complaint_data:any=[];
    get_report()
    {
        this.loader = true;
        console.log(this.data);
        this.data.date_from = moment(this.data.date_from).format("YYYY-MM-DD");
        this.data.date_to = moment(this.data.date_to).format("YYYY-MM-DD");
        this.db.FetchData(this.data,"report/export_report")
        .subscribe(resp=>{
            console.log(resp);
            this.loader = false;
            if(resp['data'] == 'Dealer does not exist')
            {
                this.complaint_data=[];
                this.toast.openError("Dealer does not exist","Error!");
                return;
            }
            else if(resp['data'] == 'Customer does not exist')
            {
                this.complaint_data=[];
                this.toast.openError("Customer does not exist","Error!");
                return;
            }
            else if(resp['data'].length == 0 || resp['data'] == 'No issue wise' || resp['data'] == 'No other product wise')
            {
                this.complaint_data=[];
                
                this.toast.openError("No record found","Error!");
                return;
            }
            
            this.complaint_data = resp['data'];
            this.highestPartCount = resp['highestPartCount'];
            this.highestRemarkCount = resp['highestRemarkCount'];
        })
    }
    
    submit_filter()
    {
        console.log(this.output_filter);
        this.settings = false;
    }
    
    reset_filter()
    {
        this.output_filter = { date_created:false,created_by:false,complaint_id:false,type:false,dealer_and_customer_info:false,branch:false,no_of_days:false,pending_at:false,total_item:false,invoice_no:false,status:false,level1:false,internal_tickets:false,installation_location :false };
    }
    
    
    index_arr:any=[];
    excel_data:any=[];  
    exportAsXLSX():void {
        // this.loader = true;
        console.log(this.output_filter);
        if(this.output_filter.date_created)
        {
            this.index_arr.push("Date Created");
        }
        if(this.output_filter.created_by)
        {
            this.index_arr.push("Created By");
        }
        if(this.output_filter.complaint_id)
        {
            this.index_arr.push("Complaint ID");
        }
        if(this.output_filter.type)
        {
            this.index_arr.push("Type");
        }
        if(this.output_filter.dealer_info)
        {
            this.index_arr.push("Dealer Info");
        }
        if(this.output_filter.dealer_info)
        {
            this.index_arr.push("Dealer Mobile");
        }
        if(this.output_filter.customer_info)
        {
            this.index_arr.push("Customer Info");
        }
        if(this.output_filter.customer_info)
        {
            this.index_arr.push("Customer Mobile");
        }
        if(this.output_filter.branch)
        {
            this.index_arr.push("Branch");
        }
        if(this.output_filter.no_of_days)
        {
            this.index_arr.push("No.of days");
        }
        if(this.output_filter.pending_at)
        {
            this.index_arr.push("Pending AT");
        }
        if(this.output_filter.total_item)
        {
            this.index_arr.push("Total Item");
        }
        if(this.output_filter.invoice_no)
        {
            this.index_arr.push("Invoice No.");
        }
        if(this.output_filter.level1)
        {
            this.index_arr.push("Level 1");
        }
        if(this.output_filter.internal_tickets)
        {
            this.index_arr.push("Internal Tickets");
        }
        if(this.output_filter.installation_location)
        {
            this.index_arr.push("Installation Location");
        }
        if(this.output_filter.status)
        {
            this.index_arr.push("Status");
        }
        if(this.output_filter.remark)
        {
            this.index_arr.push("Status Remark");
        }
        if(this.output_filter.product_name)
        {
            this.index_arr.push("Product Name");
        }    
        if(this.output_filter.cat_code)
        {
            this.index_arr.push("Cat Code");
        }
        if(this.output_filter.fault_qty)
        {
            this.index_arr.push("Fault Qty");
        }
        if(this.output_filter.fault_issue)
        {
            this.index_arr.push("Fault reason");
        }


        if(this.output_filter.fault_reason)
        {
            this.index_arr.push("Fault Remark");
        }


        if(this.output_filter.complaint_remarks)
        {

            for (let index = 0; index < this.highestRemarkCount; index++) {
                this.index_arr.push("Complaint Remarks" + (index + 1));
            }

        }

        if(this.output_filter.inst_part_name)
        {

            for (let index = 0; index < this.highestPartCount; index++) {

                    this.index_arr.push("Part Name" + (index + 1));
                    this.index_arr.push("Part Cat Code" + (index + 1));
                    this.index_arr.push("Part Qty" + (index + 1));
            }
           
        }

        if(this.index_arr.length > 0)
        this.excel_data[this.excel_data.length] = this.index_arr;
        this.index_arr=[]; 
        
        for(let i=0;i<this.complaint_data.length;i++)
        {      
            if(this.output_filter.date_created)
            {
                this.index_arr.push(this.complaint_data[i].date_created);
            }
            if(this.output_filter.created_by)
            {
                this.index_arr.push(this.complaint_data[i].created_by_name);
            }
            if(this.output_filter.complaint_id)
            {
                this.index_arr.push(this.complaint_data[i].complaint_id);
            }
            if(this.output_filter.type)
            {
                this.index_arr.push(this.complaint_data[i].type);
            }
            if(this.output_filter.dealer_info)
            {
                this.index_arr.push(this.complaint_data[i].dealer_name ? this.complaint_data[i].dealer_name : ''+this.complaint_data[i].dealer_mobile ? ' - '+this.complaint_data[i].dealer_mobile : ''+this.complaint_data[i].dealer_street ? ', '+this.complaint_data[i].dealer_street : ''+this.complaint_data[i].dealer_district ? ', '+this.complaint_data[i].dealer_district : ''+this.complaint_data[i].dealer_pincode ? ', '+this.complaint_data[i].dealer_pincode : ''+this.complaint_data[i].dealer_pincode ? ' - '+this.complaint_data[i].dealer_pincode : '');
            }
            if(this.output_filter.dealer_info)
            {
                this.index_arr.push(this.complaint_data[i].dealer_mobile);
            }
            if(this.output_filter.customer_info)
            {
                this.index_arr.push(this.complaint_data[i].customer_name ? this.complaint_data[i].customer_name : ''+this.complaint_data[i].customer_mobile ? ' - '+this.complaint_data[i].customer_mobile : ''+this.complaint_data[i].customer_street ? ', '+this.complaint_data[i].customer_street : ''+this.complaint_data[i].customer_district ? ', '+this.complaint_data[i].customer_district : ''+this.complaint_data[i].customer_pincode ? ', '+this.complaint_data[i].customer_pincode : ''+this.complaint_data[i].customer_pincode ? ' - '+this.complaint_data[i].customer_pincode : '');
            }
            if(this.output_filter.customer_info)
            {
                this.index_arr.push(this.complaint_data[i].customer_mobile);
            }
            if(this.output_filter.branch)
            {
                this.index_arr.push(this.complaint_data[i].branch);
            }
            if(this.output_filter.no_of_days)
            {
                this.index_arr.push(this.complaint_data[i].no_of_days);
            }
            if(this.output_filter.pending_at)
            {
                this.index_arr.push(this.complaint_data[i].pending_at);
            }
            if(this.output_filter.total_item)
            {
                this.index_arr.push(this.complaint_data[i].total_item);
            }
            if(this.output_filter.invoice_no)
            {
                this.index_arr.push(this.complaint_data[i].invoice_no);
            }
            if(this.output_filter.level1)
            {
                this.index_arr.push(this.complaint_data[i].level1);
            }
            if(this.output_filter.internal_tickets)
            {
                this.index_arr.push(this.complaint_data[i].internal_tickets);
            }
            if(this.output_filter.installation_location)
            {
                this.index_arr.push(this.complaint_data[i].product_installation_location);
            }
            if(this.output_filter.status)
            {
                this.index_arr.push(this.complaint_data[i].status);
            }
            if(this.output_filter.remark)
            {
                this.index_arr.push(this.complaint_data[i].remark);
            }
            if(this.output_filter.product_name)
            {
                this.index_arr.push(this.complaint_data[i].product_name);
            }    
            if(this.output_filter.cat_code)
            {
                this.index_arr.push(this.complaint_data[i].cat_code);
            }
            if(this.output_filter.fault_qty)
            {
                this.index_arr.push(this.complaint_data[i].fault_qty);
            }
            if(this.output_filter.fault_issue)
            {
                this.index_arr.push(this.complaint_data[i].fault_issue);
            }
            if(this.output_filter.fault_reason)
            {
                this.index_arr.push(this.complaint_data[i].fault_reason);
            }


            if(this.output_filter.complaint_remarks)
            {
                // this.index_arr.push(this.complaint_data[i].complaint_remarks);

                        for (let index1 = 0; index1 < this.complaint_data[i].complaintRemarksArr.length; index1++) {
                            this.index_arr.push( this.complaint_data[i].complaintRemarksArr[index1] ); 
                        }
    
                        for (let index1 = 0; index1 < this.highestRemarkCount - this.complaint_data[i].complaintRemarksArr.length; index1++) {
                        
                              this.index_arr.push( '' ); 
                                
                        }
                
            }

            if(this.output_filter.inst_part_name)
            {
                  // this.index_arr.push(this.complaint_data[i].part_name);

                    for (let index1 = 0; index1 < this.complaint_data[i].partArrData.length; index1++) {

                                this.index_arr.push(this.complaint_data[i].partArrData[index1]['partName']);
                                this.index_arr.push(this.complaint_data[i].partArrData[index1]['partCode']);
                                this.index_arr.push(this.complaint_data[i].partArrData[index1]['partQty']);
                    }

                    for (let index1 = 0; index1 <  this.highestPartCount - this.complaint_data[i].partArrData.length; index1++) {

                                this.index_arr.push('');
                                this.index_arr.push('');
                                this.index_arr.push('');
                    }

            }

            // if(this.output_filter.inst_part_cat_code)
            // {
            //     this.index_arr.push(this.complaint_data[i].part_cat_code);
            // }
            
            this.excel_data[this.excel_data.length] = this.index_arr;
            this.index_arr=[];      
        }
        // this.excel_data.splice(1,0);
        this.db.exportAsExcelFile(this.excel_data, 'BCH Report');   
        this.excel_data=[];
    }
    
    MobileNumber(event: any)
    {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
    
    reset_form()
    {
        this.data = {};
    }
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import {FormControl, Validators,FormGroup,FormBuilder, Form} from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {
  form:any={};
  isDisabled: boolean;
  flag=0;
  loader:any=0;


  constructor(public db:DatabaseService,public formbuilder:FormBuilder,public router:Router,public toast: SnackBarOverview) { }

  ngOnInit() {
    this.isDisabled=false;  
    
  }
  user_data:any=[];
  submit()
  {
    this.loader=1;
  console.log(this.form);
  this.db.DealerVerification({'data':this.form},'login/dealer_verification')
      .subscribe((result:any)=>{
        // console.log("hello")
        console.log(result);
        this.loader=0;

        if(result.data['username'])
        {
          this.user_data=result.data;
          console.log(this.user_data);
          this.db.set_data = this.user_data;
          this.router.navigate(['/forgot-password']);
        }

        else
        {
          this.toast.openError('Dealer Info not matched !!! Try Again...','');
        }
        
      });
      
  }
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // getdealerCode(mobile)
  // {
  //   console.log("first block");
    
  //   this.db.FetchData({'mobile':mobile},'login/check_dealer_mobile_existence')
  //   .subscribe((result:any)=>{
  //     console.log(result);
  //     if(result['message']=="Success")
  //     {
  //       this.flag=1;
  //     }
  //     else{
  //       this.flag=0;
  //     }
      
      
  //   },error=>{console.log(error);
  //   }
  //   );
  // }

  charonly(event: any){const pattern =/^[a-zA-Z ]+$/;let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) {event.preventDefault();} }
}

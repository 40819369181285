import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-master',
  templateUrl: './tab-master.component.html',
  styleUrls: ['./tab-master.component.scss']
})
export class TabMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

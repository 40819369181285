import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-branch-complaints-list',
  templateUrl: './branch-complaints-list.component.html'
})
export class BranchComplaintsListComponent implements OnInit {
  loader:any;
  branch_complaints_list: any =[];
  branch_name:string='';
  encrypt_id:any = '';
  branchuser_name:any;
  branchuser_id:any;
  public href: string = "";

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog:DialogComponent) { 

  }

  ngOnInit() {
    this.href = this.router.url;
    if(this.href=='/branch-user-complaints-list')
    {
      // console.log("if");
      // console.log(this.db.branchuser_id);
      // console.log(this.db.branchuser_name);
      this.branchuser_name = this.db.branchuser_name;
      this.branchuser_id = this.db.branchuser_id;
      this.GetBranchUserComplaints(this.db.branchuser_id);
    }
    else
    {
      // console.log("else");
      this.branch_name = this.db.branch_name;
      // console.log(this.branch_name);
      // console.log(this.db.branch_id);
      this.GetBranchComplaints(this.db.branch_id);
    }
    // console.log(this.router.url);
    // this.GetBranchComplaints(this.db.branch_id);
  }

  GetBranchComplaints(branch_id){
    this.loader = '1';
    console.log(branch_id);
    
    this.db.FetchData({'branch_id':branch_id},'branch/get_branch_complaints')
    .subscribe((result: any) => {
      console.log(result);
      this.branch_complaints_list = result['data']['complaints'];
      console.log(this.branch_complaints_list);
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';      
    });
  }
  GetBranchUserComplaints(user_id) 
  {
    this.loader = '1';
    this.db.FetchData({'user_id':user_id},'BranchUser/get_branchuser_complaints')
    .subscribe((result: any) => {
      console.log(result);
      this.branch_complaints_list = result['data']['complaints'];
      console.log(this.branch_complaints_list);
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';      
    });
  }
  delete_comp(id:any,index:any)
  {
    console.log(id);    
    this.dialog.delete('Complaint !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'branch/delete_complaint/'+id).subscribe((response)=>{
          console.log(response);
          if(response['message']=='Success'){
          this.branch_complaints_list.splice(index,1);
          this.toast.openSucess('Complaint Deleted Sucessfully ','');      
          }

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.branch_complaints_list);
  }

}
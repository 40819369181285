import { Component, OnInit ,Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.scss']
})
export class CreateEmailComponent implements OnInit {
  createEmailForm: FormGroup;
  complaint_id: any;
  submitted = false;
  logs:any=[];
  loader:any='';

  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<CreateEmailComponent>,@Inject(MAT_DIALOG_DATA)data) { 
    // this.GetDepartment();
     this.complaint_id = data.complaint_id;
     this.logs=data.logs;
     console.log(this.complaint_id); 
  }

  ngOnInit() {
    this.createEmailForm = this.formBuilder.group({
      email: ['', Validators.required],
      // subject: ['', Validators.required],
      message: [''],
    });
  }
  get f() { return this.createEmailForm.controls; }

  Submit()
  {
    this.loader='1';
    this.submitted = true; 
    console.log(this.createEmailForm.value);

    // stop here if form is invalid
    if (this.createEmailForm.invalid) {
      console.log("*****invalid data*****");
      this.loader='';
      return;
    }
    else
    { 
      console.log(this.createEmailForm.value);
       this.db.FetchData({'data':this.createEmailForm.value ,'complaint_id':this.complaint_id ,'logs_data':this.logs},'complaint/complaint_logs_mail_action')
       .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!!@#");
          this.loader='';
          this.dialogRef.close(result['message']);
          
        }
      },error=>{
        console.log("error");
        console.log(error);
       this.loader='';
       });
    }

  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth_service';
import { DatabaseService } from '../_services/database.service';
import * as jwt_decode from "jwt-decode";


@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router,public auth: AuthService,public db: DatabaseService) { }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('access_token')) {
      this.db.token = localStorage.getItem('access_token');
      this.auth.auth.token = this.db.token;

      let tokenInfo = this.getDecodedAccessToken(this.db.token); // decode token
      let expireDate = tokenInfo.exp; // get token expiration dateTime
      // console.log(expireDate); // undefined
      console.log(tokenInfo); // show decoded token object in console
      this.db.tokenInfo = tokenInfo;
      
      console.log(this.db.tokenInfo);
      return true;
    }
    
    else
    {
      this.router.navigate([''], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-add-permission-dialog',
  templateUrl: './add-permission-dialog.component.html',
  styleUrls: ['./add-permission-dialog.component.scss']
})
export class AddPermissionDialogComponent implements OnInit {
  addMasterPermForm: FormGroup;
  role_com_cat_id:any;
  role_id:any;
  permission_array:any=[];
  per_arr_for_disable:any;
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public toast: SnackBarOverview,public dialogbox:DialogComponent,private dialogRef:MatDialogRef<AddPermissionDialogComponent>,@Inject(MAT_DIALOG_DATA)data) {
    
    this.role_id = data.role_id;
    this.role_com_cat_id = data.role_com_cat_id;
    this.permission_array = data.permission_array;
    
    console.log(this.role_id);
    console.log(this.role_com_cat_id);
  }
  ngOnInit() {
    this.addMasterPermForm = this.formBuilder.group({
      role_id: [this.role_id,Validators.required],
      role_complaint_category_id: [this.role_com_cat_id,Validators.required],
      permission_array: ['',Validators.required],
    });
  }
  
  get f() { return this.addMasterPermForm.controls; }
  
  
  getpermissionvalue(permission){
    if(permission.value){
      this.per_arr_for_disable=permission.value;
    }
  }
  
  // savePermission(permission_array)
  // {
  //   console.log(permission_array);
  //   let temp_perm = [];
  //   for(var i=0;i<permission_array.length;i++)
  //   {
  //     let filterRowPermission = this.permission_array.filter(x => x.action_name == permission_array[i])[0]['action_id'];
  //     temp_perm.push({'action_id': filterRowPermission,'action_name':permission_array[i]});
  //     console.log(temp_perm);
  //   }
  //   console.log(temp_perm);
  //   this.addMasterPermForm.controls['permission_array'].setValue(temp_perm);
  
  //   if (this.addMasterPermForm.invalid) {
  //     console.log("*****invalid data*****");
  //     return;
  //   }
  //   else
  //   { 
  //     console.log(this.addMasterPermForm.value);
  //     this.db.FetchData({'data':this.addMasterPermForm.value},'master/add_master_role_permission')
  //     .subscribe((result:any)=>{
  //       console.log("first");
  //       console.log(result);
  //       this.dialogRef.close(result);
  //     },error=>{console.log(error);
  //     });
  //   }
  // }
  
  
  savePermission(selectedArr)
  {
    
    console.log( this.addMasterPermForm.get('permission_array').value);
    console.log(selectedArr.value);
    console.log(this.permission_array);
    let temp_perm = [];
    for(var i=0;i<selectedArr.value.length;i++)
    {
      
      let filterRowPermission = this.permission_array.filter(x => x.action_name == selectedArr.value[i])[0]['action_id'];
      
      temp_perm.push({'action_id': filterRowPermission,'action_name':selectedArr.value[i]});
      console.log(temp_perm);
    }
    
    console.log(temp_perm);
    this.addMasterPermForm.controls['permission_array'].setValue(temp_perm);
    
    if (this.addMasterPermForm.invalid) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { 
      console.log(this.addMasterPermForm.value);
      this.db.FetchData({'data':this.addMasterPermForm.value},'master/add_master_role_permission')
      .subscribe((result:any)=>{
        console.log("first");
        console.log(result);
        this.dialogRef.close(result);
      },error=>{console.log(error);
      });
    }
  }
  
}

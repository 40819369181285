import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';


@Component({
    selector: 'app-complaint-action',
    templateUrl: './complaint-action.component.html',
    styleUrls: ['./complaint-action.component.scss']
})
export class ComplaintActionComponent implements OnInit {
    ActionForm: FormGroup;
    submitted = false;
    department_list: any=[];
    user_list:any=[];
    assign_type: any=[];
    detail: any ='';
    complaint_id: any;
    coming_from:any;
    complaint_type:any;
    branch_id:any;
    serv_engg_list:any=[];
    tokenInfo:any={};
    comp_status_list:any=[];
    status_remark_access:any;
    complaint_status:any;
    
    loader:any='';
    
    
    constructor(public toast: SnackBarOverview,private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<ComplaintActionComponent>,@Inject(MAT_DIALOG_DATA)data) { 
        this.complaint_id = data.complaint_id;
        this.coming_from = data.from;
        this.branch_id=data.branch_id;
        this.complaint_type = data.complaint_type;
        this.complaint_status = data.complaint_status;
        
        console.log(this.complaint_id); 
        console.log(this.coming_from); 
        this.tokenInfo = this.db.tokenInfo;
    }
    
    ngOnInit() {
        
        this.ActionForm = this.formBuilder.group({
            department: [''],
            coming_from:[''],
            user_id: [''],
            user_name: [''],
            description: [''],
            complaint_id: [ this.complaint_id,Validators.required ],
            service_engg:[''],
            status: [''],
            status_remark:[''],
            remark_type:[''],
            // user_dependency:[]
        });
        if(this.coming_from=='Service-engineer')
        {
            this.ServiceEngg();
            this.ActionForm.controls["service_engg"].setValidators([Validators.required]);
            this.ActionForm.controls['service_engg'].updateValueAndValidity();
            
            this.ActionForm.controls['description'].clearValidators();
            this.ActionForm.controls['description'].updateValueAndValidity();
            
            this.ActionForm.controls['user_id'].clearValidators();
            this.ActionForm.controls['user_id'].updateValueAndValidity();
            
            this.ActionForm.controls['status'].clearValidators();
            this.ActionForm.controls['status'].updateValueAndValidity();
            
            this.ActionForm.controls['status_remark'].clearValidators();
            this.ActionForm.controls['status_remark'].updateValueAndValidity();
            
            this.ActionForm.controls['remark_type'].clearValidators();
            this.ActionForm.controls['remark_type'].updateValueAndValidity();
        }
        
        else if(this.coming_from=='Remark')
        {
            this.ActionForm.controls["description"].setValidators([Validators.required]);
            this.ActionForm.controls['description'].updateValueAndValidity();
            
            this.ActionForm.controls["remark_type"].setValidators([Validators.required]);
            
            if(this.tokenInfo.logged_in_type=='Dealer')
            {
                this.ActionForm.controls["remark_type"].setValue('Initiator'); //---------------------NEW CHANGE
                this.ActionForm.controls['remark_type'].updateValueAndValidity();
                
            }
            
            else
            {
                this.ActionForm.controls["remark_type"].setValue('Internal'); //---------------------NEW CHANGE
                this.ActionForm.controls['remark_type'].updateValueAndValidity();
            }
            
            this.ActionForm.controls['user_id'].clearValidators();
            this.ActionForm.controls['user_id'].updateValueAndValidity();
            
            
            this.ActionForm.controls['service_engg'].clearValidators();
            this.ActionForm.controls['service_engg'].updateValueAndValidity();
            
            
            this.ActionForm.controls['status'].clearValidators();
            this.ActionForm.controls['status'].updateValueAndValidity();
            
            this.ActionForm.controls['status_remark'].clearValidators();
            this.ActionForm.controls['status_remark'].updateValueAndValidity();
        }
        
        else if(this.coming_from=='ChangeStatus' || this.coming_from=='Reopen')
        {
            this.get_complaint_status();
            if(this.coming_from=='Reopen')
            {
                this.ActionForm.controls['status'].setValue('ReOpen'); 
            }
            
            this.ActionForm.controls["status"].setValidators([Validators.required]);
            this.ActionForm.controls['status'].updateValueAndValidity();
            
            this.ActionForm.controls['user_id'].clearValidators();
            this.ActionForm.controls['user_id'].updateValueAndValidity();
            
            this.ActionForm.controls['service_engg'].clearValidators();
            this.ActionForm.controls['service_engg'].updateValueAndValidity();
            
            this.ActionForm.controls['description'].clearValidators();
            this.ActionForm.controls['description'].updateValueAndValidity();
            
            this.ActionForm.controls['remark_type'].clearValidators();
            this.ActionForm.controls['remark_type'].updateValueAndValidity();
        }
        
        else
        {
            this.getUser();
            
            this.ActionForm.controls["user_id"].setValidators([Validators.required]);
            this.ActionForm.controls['user_id'].updateValueAndValidity();
            
            this.ActionForm.controls['description'].clearValidators();
            this.ActionForm.controls['description'].updateValueAndValidity();
            
            this.ActionForm.controls['service_engg'].clearValidators();
            this.ActionForm.controls['service_engg'].updateValueAndValidity();
            
            
            this.ActionForm.controls['status'].clearValidators();
            this.ActionForm.controls['status'].updateValueAndValidity();
            
            this.ActionForm.controls['status_remark'].clearValidators();
            this.ActionForm.controls['status_remark'].updateValueAndValidity();
            
            this.ActionForm.controls['remark_type'].clearValidators();
            this.ActionForm.controls['remark_type'].updateValueAndValidity();
            
            console.log("hey");
            
        }
    }
    
    get f() { return this.ActionForm.controls; }
    
    get_complaint_status()
    {
        this.db.get_rqst('master/get_complaint_status')
        .subscribe((result: any) => {
            console.log(result);
            this.comp_status_list=result['data'];
        }, error => {
            console.log(error);
            this.toast.openError('Something went wrong !!! Try again... ','');
        });
    }
    
    
    getremark_access(index)
    {
        console.log(index);
        console.log(this.comp_status_list);
        
        let accessdata = this.comp_status_list[index];
        this.status_remark_access = accessdata.remark_access;
        console.log(this.status_remark_access);
        
        if(this.status_remark_access)
        {
            this.ActionForm.controls["status_remark"].setValidators([Validators.required]);
            this.ActionForm.controls['status_remark'].updateValueAndValidity();
            
        }
        else
        {
            this.ActionForm.controls['status_remark'].clearValidators();
            this.ActionForm.controls['status_remark'].updateValueAndValidity();
        }
        
    }
    
    ServiceEngg()
    {
        console.log(this.branch_id);
        
        this.db.FetchData({'branch_id':this.branch_id},'complaint/service_engg_list')
        .subscribe((result)=>{
            console.log(result);
            this.serv_engg_list=result["data"]["engg_list"];
            console.log(this.serv_engg_list);
        },error=>{
            console.log("error");
            console.log(error);
            
        });
    }
    
    GetDepartment()
    {
        this.db.FetchData({'complaint_type':this.complaint_type},'complaint/department_list')
        .subscribe((result:any)=>{
            console.log(result);
            this.department_list = result['data'];
            console.log(this.department_list);
        },error=>{
            console.log(error);
        });
    }
    
    getUser()
    {
        this.db.FetchData({'complaint_type':this.complaint_type,'coming_from':this.coming_from,'complaint_id':this.complaint_id},'complaint/get_complaint_department_user_list')
        .subscribe((result:any) =>{
            console.log(result);
            this.user_list = result['data'];
            console.log(this.user_list);
            if(this.user_list.length == 0)
            {
                this.toast.openError("No Data Found!","");
            }
        },error=>{
            console.log(error);
        });
    }
    
    selectedFile:any=[];
    select_file(event)
    {
        let total_files_size=0;
        console.log(event.target.files.length);
        
        for(var i=0; i<event.target.files.length; i++)
        {
            const file = event.target.files[i];
            console.log('size', file.size);
            console.log('type', file.type);
            let extension  = file.type.split('/').pop(); 
            console.log(extension);
            console.log(typeof(file.size));
            
            total_files_size = total_files_size + parseInt(file.size);
            if(parseInt(file.size)<=5242880)
            {
                if(extension=='png' || extension=='jpeg' || extension=='jpg' || extension=='gif' || extension=='tif' || extension=='pdf')
                {
                    this.selectedFile.push(event.target.files[i]);
                }
                else if(extension!='png' && extension!='jpeg' && extension!='jpg' && extension!='gif' && extension!='tif' && extension!='pdf')
                {
                    if(extension=='vnd.openxmlformats-officedocument.wordprocessingml.document' || extension=='vnd.ms-powerpoint')
                    {
                        this.selectedFile.push(event.target.files[i]);
                    }
                    else
                    {
                        this.toast.openError('The file type not allowed!!! Try Again...','');
                    }
                    
                }
                else
                {
                    this.toast.openError('The file type not allowed!!! Try Again...','');
                }
            }
            
            else
            {
                this.toast.openError('The file size not allowed more than 5mb !!! Try Again...','');
            }
        }
        if(total_files_size>5242880)
        {
            this.toast.openError('The files size not allowed more than 5mb !!! Try Again...','');
            this.selectedFile=[];
        }
        
        console.log(this.selectedFile);
    }
    
    delFile(name:any,index:any){
        console.log(name); 
        console.log(index);
        this.selectedFile.splice(index,1);
        console.log(this.selectedFile);
    }
    
    formData=new FormData(); // for file uploading  
    remark_data=[];
    Create() 
    {
        this.loader='1';
        this.submitted = true; 
        // stop here if form is invalid
        if (this.ActionForm.invalid) {
            console.log("*****invalid data*****");
            this.loader='';
            
            return;
        }
        else
        { 
            if(this.coming_from=='Reopen')
            {
                console.log(this.ActionForm.value);
                
                this.db.FetchData({'data':this.ActionForm.value},'complaint/complaint_reopen/'+this.complaint_id).subscribe((response)=>{
                    console.log(response);
                    this.dialogRef.close(response);
                    
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try Again...','');
                });
            }
            else
            {
                this.ActionForm.controls['coming_from'].setValue(this.coming_from);
                
                if(this.coming_from!='ChangeStatus' && this.coming_from!='Reopen' && this.coming_from!='Service-engineer' && this.coming_from!='Remark')
                {
                    let forward_name = this.user_list.filter(x=>x.id==this.ActionForm['user_id'])[0].name;
                    this.ActionForm.controls['user_name'].setValue(forward_name);
                }
                
                else
                {
                    this.ActionForm.controls['user_name'].setValue('');
                    this.ActionForm.controls['user_id'].setValue('');
                    
                }
                
                this.db.FetchData({'data':this.ActionForm.value},'complaint/complaint_action')
                .subscribe((result)=>{
                    console.log(result);
                    if(result['message']=="Success")
                    {
                        var insert_id = result['data']['insert_id'];
                        if(insert_id && this.selectedFile && this.selectedFile.length)
                        {
                            for(var i=0; i<this.selectedFile.length; i++)
                            {
                                this.formData.append("image"+i ,this.selectedFile[i],this.selectedFile[i].name);
                            }
                            
                            console.log(this.formData);
                            
                            this.formData.append("insert_id",insert_id);
                            this.formData.append("complaint_id",this.complaint_id);
                            
                            this.formData.append("created_by",this.tokenInfo.id);
                            this.formData.append("created_by_type",this.tokenInfo.logged_in_type);
                            
                            this.db.FileData(this.formData,"complaint/complaint_remark_file_upload")
                            .subscribe((result1:any) =>{
                                console.log(result1);
                                // this.remark_data = result['data'];
                                // console.log(this.remark_data);
                                this.loader='';
                            },error=>{
                                console.log(error);
                            });
                        }
                    }
                    this.dialogRef.close(result);
                },error=>{
                    console.log("error");
                    console.log(error);
                    this.loader='';
                    
                });
            }
            
        }
    }
}
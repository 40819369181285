import { Component, OnInit, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-dealer-main-complaint-cancel-modal',
  templateUrl: './dealer-main-complaint-cancel-modal.component.html',
  styleUrls: ['./dealer-main-complaint-cancel-modal.component.scss']
})
export class DealerMainComplaintCancelModalComponent implements OnInit {
  complaint_id: any;
  data:any={};
  constructor(public db:DatabaseService,public toast: SnackBarOverview,private dialogRef:MatDialogRef<DealerMainComplaintCancelModalComponent>,@Inject(MAT_DIALOG_DATA)data) { 
    this.complaint_id = data.complaint_id;
  }

  ngOnInit() {
    console.log(this.complaint_id);
  }
    
  submit(cancel_reason)
  {
    console.log(cancel_reason);
    this.db.FetchData({'complaint_id':this.complaint_id,'cancel_reason':cancel_reason},'complaint/complaint_cancel').subscribe((resp)=>
    {
      console.log(resp);
      if(resp && resp['data'] && resp['data']['status']=='Cancel')
      {
        this.dialogRef.close('Cancel');
      }
      else if(resp && resp['data'] && resp['data']['status']=='Reject')
      {
        this.dialogRef.close('Reject');
      }
      else
      {
        this.dialogRef.close('InProgress');
      }
    });
   
    // this.dialogRef.closeAll();    
  }
   
  onNoClick()
  {
    this.dialogRef.close();
  }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';



@Component({
  selector: 'app-system-user-list',
  templateUrl: './system-user-list.component.html'
})
export class SystemUserListComponent implements OnInit {
  
  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  user:any = [];
  complaint_count:any = [];
  print_totalcomp:any=[];
  count:any='';
  total_complaint: any;
  department_list:any=[];
  role_list:any=[];
  sendRequest:any=true;
  token_info:any={};
  
  constructor(public db:DatabaseService,public toast: SnackBarOverview,public dialog:DialogComponent) { }
  ngOnInit() {
    this.GetSystemUserList();
    this.token_info=this.db.tokenInfo;
    
  }
  
  validate(event:any)
  {
    const pattern = /^[0-9 ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  search_user_type(user_type:any)
  { 
    console.log(user_type);
    this.search.user_type = user_type; 
    console.log(this.search.user_type);
    this.GetSystemUserList();
  }
  
  search_user_department(dept:any)
  { 
    console.log(dept);
    this.search.department_name = dept; 
    console.log(this.search.department_name);
    this.GetSystemUserList();
  }
  search_role(user_role:any)
  {
    console.log(user_role);
    this.search.user_role=user_role; 
    console.log(this.search.user_role);
    this.GetSystemUserList();
  }
  
  search_name(name:any)
  { 
    console.log(name);
    this.search.name = name; 
    console.log(this.search.name);
    this.GetSystemUserList();
  }
  
  search_code(user_code:any)
  { 
    console.log(user_code);
    this.search.user_code = user_code; 
    console.log(this.search.user_code);
    this.GetSystemUserList();
  }
  
  search_mobile(mobile_no:any)
  { 
    console.log(mobile_no);
    this.search.mobile_no = mobile_no; 
    console.log(this.search.mobile_no);
    this.GetSystemUserList();
  }
  
  GetSystemUserList(pagelimit:any=20,start:any=0,action:any=''){
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    // console.log(this.start);
    // console.log(action);
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'user/user_list')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['data']['list'])
      {
        this.sendRequest=false;
        this.db.FetchData({},'master/department_list')
        .subscribe((resp:any)=>{
          this.department_list = resp['data'];
        });
        
        this.db.FetchData({},'master/get_master_roles')
        .subscribe((resp:any)=>{
          this.role_list = resp['data'];
        });
      }
      this.user = result['data']['list'];
      this.complaint_count = result['data']['complaint_count'];
      this.rqst_count = result['data']['count'];
      this.total_complaint= result['data']['total_complaint'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';
    });
  }
  
  // public toggle(event,status,index,id) 
  // {
  //   console.log('toggle', event.checked);
  //   // this.useDefault = event.checked;
  //   this.dialog.confirmation('Status!').then((result) => {
  //     console.log(result);
  //     console.log(id);
  //     if(result==true)
  //     {
  //       console.log("if");
  //       if(event.checked==true)
  //       this.user[index]['status'] = 'Active';
  //       else if(event.checked==false)
  //       this.user[index]['status'] = 'Deactive';
  //       this.db.FetchData({'user_id':id,'status':this.user[index]['status']},'user/change_status').subscribe((response)=>{
  //         console.log(response);
  //         if(response['message']=='Success')
  //         {
  //           this.toast.openSucess('Status'+' '+ this.user[index]['status']+ ' '+ 'Successfully','');
  //         }
  //       },err=>{
  //         console.log(err);
  //         this.toast.openError('Something went wrong !!! Try Again...','');
  //       });
  //     }
  //     else
  //     {
  //       console.log("else");
  //       console.log(status);
  //       this.user[index]['status'] = status;
  //       if(status=='Active')
  //       event.checked = true;
  //       else
  //       event.checked = false;
  //       console.log('toggle', event.checked);
  //       console.log(this.user[index]['status']);
  //     }
  //   });
  // }
  
  statusChange(status,id,index){
    console.log(status);
    console.log(id);
    this.dialog.statusChangeConfirmation(' status').then((result) => {
      console.log(result); //true
      if(result)
      {
        this.db.FetchData({'user_id': id,'status':status},'user/change_status')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            console.log(this.user[index]['status']);
            if(this.user[index]['status']=='Active'){
              this.user[index]['status']='Deactive';
            }
            else{
              this.user[index]['status']='Active';
            }
            this.toast.openSucess('Status Changed Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
  
  GetComplaintList(){
    this.user=this.user.filter(x => x.total_complaint>=1);
  }
  
  GetOpenComplaintList(){
    this.user=this.user.filter(x=>x.total_runn_complaint>=1);
  }
  
  delete(index ,user_id)
  {
    console.log(index);
    console.log(user_id);
    this.dialog.delete('System User !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.get_rqst('user/delete_user/'+user_id)
        .subscribe(res => {
          console.log(res);
          this.user.splice(index,1);
          this.toast.openSucess('User Deleted Sucessfully ','');      
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
}
import { Component, OnInit, Renderer2  } from '@angular/core';
import { DatabaseService } from '../_services/database.service';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth_service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    token_info:any={};
    constructor(private renderer: Renderer2, public db:DatabaseService, public router: Router,private auth: AuthService) {
        this.token_info = this.db.tokenInfo;
     }
    
    ngOnInit() {
        this.abacusConnect();
    }
    
    status:boolean = false;
    toggleHeader() {
        this.status = !this.status;
        if(this.status) {
            this.renderer.addClass(document.body, 'active');
        }
        else {
            this.renderer.removeClass(document.body, 'active');
        }
    }

    open_url()
    {
        window.open("http://crmsupport.abacusdesk.com/projecttaskdetail/OUNzQXFYTDhnSXdSczBXNHovbWtldz09","_blank");
    }

    task_cn:any=0;
    abacusConnect()
    {
        this.db.FetchData("","master/abacusConnect")
        .subscribe(resp=>{
            console.log(resp);
            this.task_cn = resp['task_count'];
        })
    }

    logout() 
    {
        this.auth.logout();
    }

    comingFromAddComplaints()
    {
        this.db.coming_from='add';
    }
      
    clear_filter()
    {
        localStorage.setItem("filter",JSON.stringify({}));
    }
}

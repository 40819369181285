import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-branch-user-tabs',
  templateUrl: './branch-user-tabs.component.html'
})
export class BranchUserTabsComponent implements OnInit {
  branch_name:string = '';
  encrypt_id:any = '';
  detail: any = {};
  tokenInfo:any={};
  user_id:any;

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
   }

   ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    if(this.tokenInfo.logged_in_type!='System_User')
    {
      this.user_id=this.tokenInfo.id;
    }
    else
    {
      if(!this.encrypt_id)
      {
        console.log("if",this.db.branchuser_id);
        
      this.user_id = this.db.branchuser_id;
      }
      else
      this.user_id=this.encrypt_id;
    }
    this.GetUserDetail();
  }

  GetUserDetail() 
  {
    this.db.FetchData({'user_id': this.user_id }, 'BranchUser/branch_user_detail')
    .subscribe((result: any) => {
      console.log(result);
      this.detail = result['data']['detail'];
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
@Component({
  selector: 'app-import-invoice',
  templateUrl: './import-invoice.component.html'
})
export class ImportinvoiceComponent implements OnInit {
  
  ImportForm: FormGroup;
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) {
  }
  
  day:any='';
  month:any='';
  year:any='';
  uploadurl:any='';
  ngOnInit() {
    this.uploadurl = this.db.UploadUrl;
    
    let date = new Date();
    this.day = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();
    // this.ImportForm = this.formBuilder.group({
    //   name: ['', Validators.required],
    // });
  }
  
  // get e() { return this.ImportForm.controls; }
  
  download_invoice()
  {
    window.open(this.db.UploadUrl+'/invoice.csv','__self');
  }
  download_item()
  {
    window.open(this.db.UploadUrl+'/invoice_item.csv','__self');
  }
  
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { PartAssignModalComponent } from '../part-assign-modal/part-assign-modal.component';
import{ CreateTicketComponent } from '../create-ticket/create-ticket.component';
import{ ComplaintActionComponent } from '../complaint-action/complaint-action.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog,MatDialogConfig } from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { DomSanitizer } from '@angular/platform-browser';
import { DealerMainComplaintCancelModalComponent } from '../dealer-main-complaint-cancel-modal/dealer-main-complaint-cancel-modal.component';


@Component({
  selector: 'app-dealer-main-complaint-detail',
  templateUrl: './dealer-main-complaint-detail.component.html',
  styleUrls: ['./dealer-main-complaint-detail.component.scss']
})
export class DealerMainComplaintDetailComponent implements OnInit {
  name = 'Angular 5';
  fileUrl;
  ecrpt_id:any = '';
  detail: any ={};
  loader:any;
  invoice_info: any =[];
  invoice_no:any = '';
  complaints_detail:any=[];
  branch_info: any=[];
  internal_tickets: any=[];
  internal_tickets_parts: any=[];
  items: any=[];
  logs: any=[];
  remark_doc_data:any=[];
  other_doc_data:any=[];
  hide = true; // for password
  tokenInfo:any={};

  constructor(public matdialog: MatDialog,public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog: MatDialog,public dialogbox:DialogComponent,private sanitizer: DomSanitizer) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
  }

  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    console.log(this.tokenInfo);
    this.GetComplaintDetail();
    console.log(this.db.UploadUrl);
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  
  OpenDialog(complaint_id,type) 
  {
    console.log(complaint_id);
    console.log(type);
    
    const dialogRef=this.matdialog.open(DealerMainComplaintCancelModalComponent,
      {
        width: '800px',
        data:
        {
          complaint_id: complaint_id,
          type
        }
      });
      dialogRef.afterClosed().subscribe(result => 
        {
          console.log(result);
          this.GetComplaintDetail();
        });
      }


  GetComplaintDetail(){
    this.loader = '1';
    this.db.FetchData({'comp_id': this.ecrpt_id },'complaint/complaint_detail')
    .subscribe((result: any) => {
      console.log(result);
      console.log(this.ecrpt_id);
      this.detail = result['data']['detail'];
      this.internal_tickets = result['data']['internal_tickets'];
      this.internal_tickets_parts = result['data']['internal_tickets_parts'];
      this.items = result['data']['items'];
      console.log(this.items);
      this.logs = result['data']['logs'];
      this.remark_doc_data = result['data']['remark_doc_data'];
      console.log(this.remark_doc_data);
      
      this.other_doc_data = result['data']['other_doc_data'];
      console.log(this.other_doc_data);

      
      console.log(this.remark_doc_data);
      console.log(this.detail);
      
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';
    });
  }

  Comp_Reopen() 
  {
    this.dialogbox.confirmation('Status!').then((result) => {
      console.log(result);
      if(result==true)
      {
        this.db.FetchData({},'complaint/complaint_reopen/'+this.detail.id).subscribe((response)=>{
          console.log(response);
          if(response['message']=='Success')
          {
            this.toast.openSucess('Complaint ReOpen Successfully','');
            this.GetComplaintDetail();

          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
  }

  openActionDialog(from) 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { complaint_id: this.detail.id,from: from,complaint_type: this.detail.type,branch_id: this.detail.branch_id};
    dialogConfig.width='568px';
    const dialogRef = this.dialog.open(ComplaintActionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result['data']['action_permit'] && result['data']['action_permit']=='0')
      {
        this.toast.openError('Complaint is no longer resides in your access!!','');
      }

      else if(result && result['message'] && result['message']=="Success" && result['data'] && result['data']['action_permit'] && result['data']['action_permit']=='1')
      {
        if(from=='Service-engineer')
        this.toast.openSucess('Service Engineer Successfully Assigned','');
        else if(from=='Remark')
        this.toast.openSucess('Remark Added Successfully','');
        else if(from=='ChangeStatus')
        this.toast.openSucess('Status Changed Successfully','');
        else
        this.toast.openSucess('Successfully'+' ' +from,'');
        this.GetComplaintDetail();
      }
      else if(result && result['message'] && result['message']!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');
      }
    });
  }

  openTicketDialog() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { complaint_id: this.detail.id,};
    const dialogRef= this.dialog.open(CreateTicketComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result=="Success")
      {
        this.toast.openSucess('Ticket Created Successfully','');
        this.GetComplaintDetail();
      }
      else if(result && result!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');

      }
    });
  }


  
  openAssignDialog() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      branch_id: this.detail.branch_id,
      complaint_id: this.detail.id
    };
    const dialogRef= this.dialog.open(PartAssignModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result=="Success")
      {
        this.toast.openSucess('Assigned Successfully','');
        this.GetComplaintDetail();
      }
      else if(result && result!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');

      }
    });
  }

  remark:any;
  add_remark()
  {
    console.log(this.remark);
    console.log(this.detail.id);
    this.db.FetchData({'complaint_id': this.detail.id,'remark':this.remark},'complaint/add_remark').subscribe((response)=>{
      console.log(response);
      this.logs.push(response['data']);
      this.remark='';
    },err=>{
      console.log(err);
      this.toast.openError('Something went wrong !!! Try Again...','');
    });

  }

  delete_internal_ticket(tic_id,index,complaint_id)
  {
      console.log(tic_id);   
      console.log(complaint_id);    

      this.dialogbox.delete('Internal Ticket !').then((result) => {
        console.log(result);
        if(result)
        {
            this.db.FetchData({},'complaint/delete_ticket/'+ tic_id + '/'+complaint_id).subscribe((response)=>{
              console.log(response);
              if(response['message']=='Success')
              {
                this.internal_tickets.splice(index,1);
                this.toast.openSucess('Ticket Deleted Successfully','');
              }
            },err=>{
              console.log(err);
              this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
      });
  }

  close_internal_ticket(tic_id,index)
  {
      console.log(tic_id);   
      console.log(index);
       
      this.dialogbox.confirmation('Close !').then((result) => {
        console.log(result);
        if(result)
        {
            this.db.FetchData({},'complaint/close_ticket/'+tic_id).subscribe((response)=>{
              console.log(response);
              if(response['message']=='Success')
              {
                // this.internal_tickets['index']['status']='Close';
                this.toast.openSucess('Ticket Closed Successfully','');

              }
              
            },err=>{
              console.log(err);
              this.toast.openError('Something went wrong !!! Try Again...','');
            });
        }
      });
  }

  delete_remark(id:any,comp_id:any,index:any)
  {
    console.log("delete clicked");
    console.log(id , index); 
    console.log("Complaint Id : "+comp_id);
    
    this.dialogbox.delete('Remark!').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({'complaint_id':comp_id,'remark_id':id},'complaint/delete_complaint_remark').subscribe((response)=>{
          console.log(response);
          this.remark_doc_data.splice(index,1);
          if(response['message']=='Success'){
            this.toast.openSucess('Complaint deleted successfully !!','');
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
  }


 

}

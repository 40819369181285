import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import {ActivatedRoute, Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';


@Component({
    selector: 'app-branch-user-list',
    templateUrl: './branch-user-list.component.html'
})
export class BranchUserListComponent implements OnInit {
    encrypt_id:any = '';
      
    loader:any = '';  
    start:any = "0";
    pagelimit:any = "20";
    search:any = {};
    rqst_count:any = '';
    total_page:any = ''
    pagenumber:any = '';
    user: any;
    branch_comp_count:any = [];
    tokenInfo:any={};

    constructor(public db:DatabaseService,public router:Router,public route:ActivatedRoute,public toast: SnackBarOverview,public dialog:DialogComponent) { 
        this.tokenInfo = this.db.tokenInfo;
        console.log(this.tokenInfo);
        console.log(this.tokenInfo.id);

    }
    
    ngOnInit() {
        this.GetBranchUserList();
    }
  
    GetBranchUserList(pagelimit:any=20,start:any=0,action:any=''){
        this.loader = "1";
        this.pagelimit = pagelimit;
        this.start = start;
        console.log(this.start);
        console.log(action);
        
        if(action == "refresh")
        {
            this.search = {};
            this.start = 0;
        }
        
        this.db.FetchData({'branch_id': this.tokenInfo.loggedin_user_branch,'search':this.search,'start':this.start,'pagelimit':this.pagelimit, },'BranchUser/get_branch_users')
        .subscribe((result:any)=>{
            console.log(result);
              this.user = result['data']['list'];
            //   this.branch_comp_count = result['data']['branch_comp_count']
            this.rqst_count = result['data']['count'];
            this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
            this.pagenumber = (this.start/this.pagelimit)+1;
            this.loader = '';
            
        },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');    
            this.loader = '';
        });
    }
    
    delete(index ,id){
        console.log(index);
        console.log(id);
        this.dialog.delete('User!').then((result) => {
            console.log(result);
            if(result)
            {
                this.db.get_rqst('BranchUser/delete_branch_user/'+id)
                .subscribe(res => {
                    console.log(res);
                    this.user.splice(index,1);
                    this.toast.openSucess('User Deleted Successfully','');     
                },err=>{
                    console.log(err);
                    this.toast.openError('Something went wrong !!! Try Again...','');
                });
            }
        });
    }
    
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { MatDialog,MatDialogConfig } from '@angular/material';

import { AddMasterDialogComponent } from '../add-master-dialog/add-master-dialog.component';
import { DialogComponent } from '../../DialogComponent.js';
import { CdkRowDef } from '@angular/cdk/table';
import { MasterProductLevelDialogComponent } from '../master-product-level-dialog/master-product-level-dialog.component';
import { AddPermissionDialogComponent } from '../add-permission-dialog/add-permission-dialog.component';
import { AddComplaintCategoryDialogComponent } from '../add-complaint-category-dialog/add-complaint-category-dialog.component';
import { AddProductLevelDialogComponent } from '../add-product-level-dialog/add-product-level-dialog.component';



@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html'
})
export class MasterListComponent implements OnInit {
  
  loader:any = '';
  progress:any = '';
  start:any = "0";
  pagelimit:any = "100";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  department: any= [];
  part_list: any;
  issues_list: any=[];
  region_list: any;
  complaint_category_list:any;
  part_category_list:any;
  sparepart:any=[];
  region:any=[];
  department_complaint_category_list:any=[];
  assigned_category:any=[];
  department_name: any;
  dept_id: any;
  cat_id:any;
  category_name:any;
  width: any;
  role_list:any=[];
  complaint_cat:any=[];
  not_existing_category_array: any=[];
  
  constructor(public db:DatabaseService, public router:Router, public toast: SnackBarOverview, public dialog: MatDialog,public dialogbox:DialogComponent) { }
  
  ngOnInit() {
    this.GetDepartmentList();
    this.GetSparePartList();
    this.GetRegionList();
    this.GetComplaintCategoryList();
    this.GetIssueList();
    this.GetRoleList();
    // this.GetDepartmentComplaintCategoryList();
  }
  
  
  
  openAddNewDialog() 
  {
    const dialogRef = this.dialog.open(AddMasterDialogComponent ,{
      width:'500px'
    });
    console.log("Dialog closed");
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
      console.log(`Dialog result: ${result}`);
    });
  }
  

  openProductleveldialog(role_id,role_com_cat_id,product_level)
  {
    console.log(product_level);
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.data = {role_id:role_id,role_com_cat_id:role_com_cat_id, product_level: product_level};
    const dialogRef = this.dialog.open(MasterProductLevelDialogComponent, dialogConfig);
    console.log("Dialog closed");
  }
  
  
  
  openAddPermissionDialog(role_index,cat_index,role_id,role_com_cat_id)
  {
    console.log(role_id);
    console.log(role_com_cat_id);
    this.db.FetchData({'role_id':role_id,'role_com_cat_id':role_com_cat_id},'master/get_role_comp_cat_permission')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {role_id:role_id,role_com_cat_id:role_com_cat_id,permission_array:result['data']};
        dialogConfig.width='500px';
        const dialogRef= this.dialog.open(AddPermissionDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          if(result && result.message=="Success")
          {
            this.role_list[role_index].complaint_category[cat_index].permission_array = result.data;
            this.toast.openSucess('Submit Successfully','');
          }
          else if(result && result!="Success")
          {
            this.toast.openError('Something went wrong !!! Try again... ','');
            
          }
        });
      }
    },error=>{
      console.log(error);
    });
  }


    // Add complaint category
  openAddComplaintCategoryDialog(index,role_id)
  {
    console.log(index);
    console.log(role_id);
    this.db.FetchData({'role_id':role_id},'master/get_not_used_role_comp_cat')
    .subscribe((result:any)=>{
      console.log(result);
      this.not_existing_category_array=result['data'];
      if(result['message']=="Success")
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { role_id:role_id, category_array:this.not_existing_category_array };
        dialogConfig.width='500px';

        const dialogRef= this.dialog.open(AddComplaintCategoryDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          if(result && result.message=="Success")
          {
            this.role_list[index]['complaint_category']=result.data;
            this.toast.openSucess('Submit Successfully','');
            this.GetRoleList();
          }
          else if(result && result!="Success")
          {
            this.toast.openError('Something went wrong !!! Try again... ','');
          }
        });
      }
    },error=>{
      console.log(error);
    });
  }


  openAddNewProductLevelDialog(role_index,role_id,cat_id,cat_index)
  {
    console.log(role_id);
    console.log(cat_id);
    this.db.FetchData({'role_id':role_id,'role_comp_category_id':cat_id},'master/get_not_used_product_level')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {role_id:role_id,role_comp_category_id:cat_id,product_level_array:result['data']};
        dialogConfig.width='500px';
        const dialogRef= this.dialog.open(AddProductLevelDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          if(result && result.message=="Success")
          {
            this.role_list[role_index].complaint_category[cat_index].permission_array = result.data;
            this.toast.openSucess('Submit Successfully','');
          }
          else if(result && result!="Success")
          {
            this.toast.openError('Something went wrong !!! Try again... ','');
          }
        });
      }

      else
      {
        this.toast.openError('No one product level has left !!!','');
      }
    },error=>{
      console.log(error);
    });
  }

  search_type(type:any)
  { 
    this.search={};
    this.search.type_name = type; 
    console.log(this.search.type_name);
  }
  search_master(master:any)
  {
    this.search.master=master;
    console.log(this.search.master);
    if(this.search.type_name == 'region')
    {
      this.GetRegionList();
    }
    else if(this.search.type_name == 'department')
    {
      this.GetDepartmentList();
    }
    else if(this.search.type_name == 'spareparts')
    {
      this.GetSparePartList();
    }
    else if(this.search.type_name == 'complaint_category')
    {
      this.GetComplaintCategoryList();
    }
    else if(this.search.type_name == 'complaint_issue')
    {
      this.GetIssueList();
    }
    else if(this.search.type_name == 'role')
    {
      this.GetRoleList();
    }
  }
  
  search_dr_source(source:any)
  { 
    this.search.source = source; 
    console.log(this.search.source);
  }
  
  
  charonly(event: any){
    const pattern =/^[a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  flags:any;
  flag:any = '0';
  actives:any = {};
  
  edit_region(){ this.actives.region_code = "1"; this.actives.region_name = "1"; this.flag = '1';  console.log("EDIT"); }
  cancel_region(){ this.actives.region_code = "1"; this.actives.region_name = "1"; this.flag='0'; this.actives.region_code='0' ;  this.actives.region_name='0' ; console.log("CANCEL"); } 
  
  edit_spare_parts(part_name ,index){ console.log(index); console.log(part_name); this.actives.part_name = "1"; this.flag = '1';  console.log("EDIT"); }
  cancel_spare_parts(){this.actives.part_name = "1"; this.flag='0'; this.actives.part_name='0'; console.log("CANCEL"); } 
  
  edit_department(){  this.actives.department_name = "1"; this.flag = '1';  console.log("EDIT"); }
  cancel_department(){ this.actives.department_name = "1"; this.flag='0'; this.actives.department_name='0'; console.log("CANCEL"); }  
  
  edit_comp_cat(category_name ,index){ console.log(index); console.log(category_name); this.actives.category_name = "1"; this.flag = '1';  console.log("EDIT"); }
  cancel_comp_cat(){ this.actives.category_name = "1"; this.flag='0'; this.actives.category_name='0';  console.log("CANCEL"); } 
  
  
  update_region(index:any) {
    console.log("Update");
    this.flags=0;
    this.flag=0;
    
    console.log(index);
    console.log(this.region_list);
    
    //   // let filteredData = this.branch_service_engg[index];
    //   let filteredData = Object.assign({},this.branch_service_engg[index]);
    //   console.log(filteredData);
    //   this.loader = '1';
    //   console.log(this.details);
    //   this.db.FetchData({ 'data': filteredData }, 'BranchUser/update_branch_user')
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result['message'] == "Success") {
    //       console.log("Updated!");
    this.toast.openSucess('Updated! ','');
    //     }
    //     else {
    //       console.log("X something went wrong! ");
    //       this.toast.openError('Something went wrong !!! Try again... ','');
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    this.actives = {};
  }  
  update_department(index:any) {
    console.log("Update");
    this.flag=0;
    this.flags=0;
    
    console.log(index);
    console.log(this.department);
    
    //   // let filteredData = this.branch_service_engg[index];
    //   let filteredData = Object.assign({},this.branch_service_engg[index]);
    //   console.log(filteredData);
    //   this.loader = '1';
    //   console.log(this.details);
    //   this.db.FetchData({ 'data': filteredData }, 'BranchUser/update_branch_user')
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result['message'] == "Success") {
    //       console.log("Updated!");
    //       this.toast.openSucess('Updated! ','');
    //     }
    //     else {
    //       console.log("X something went wrong! ");
    //       this.toast.openError('Something went wrong !!! Try again... ','');
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    this.actives = {};
  }  
  
  update_spare_parts(index:any) {
    console.log("Update");
    this.flag=0;
    this.flags=0;
    
    console.log(index);
    console.log(this.part_list);
    
    //   // let filteredData = this.branch_service_engg[index];
    //   let filteredData = Object.assign({},this.branch_service_engg[index]);
    //   console.log(filteredData);
    //   this.loader = '1';
    //   console.log(this.details);
    //   this.db.FetchData({ 'data': filteredData }, 'BranchUser/update_branch_user')
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result['message'] == "Success") {
    //       console.log("Updated!");
    //       this.toast.openSucess('Updated! ','');
    //     }
    //     else {
    //       console.log("X something went wrong! ");
    //       this.toast.openError('Something went wrong !!! Try again... ','');
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    this.actives = {};
  }  
  
  update_comp_cat(index:any) {
    console.log("Update");
    this.flag=0;
    this.flags=0;
    
    console.log(index);
    console.log(this.complaint_category_list);
    
    //   // let filteredData = this.branch_service_engg[index];
    //   let filteredData = Object.assign({},this.branch_service_engg[index]);
    //   console.log(filteredData);
    //   this.loader = '1';
    //   console.log(this.details);
    //   this.db.FetchData({ 'data': filteredData }, 'BranchUser/update_branch_user')
    //   .subscribe((result: any) => {
    //     console.log(result);
    //     if (result['message'] == "Success") {
    //       console.log("Updated!");
    //       this.toast.openSucess('Updated! ','');
    //     }
    //     else {
    //       console.log("X something went wrong! ");
    //       this.toast.openError('Something went wrong !!! Try again... ','');
    //     }
    //   }, error => {
    //     console.log(error);
    //   });
    this.actives = {};
  }  
  
  GetDepartmentList(pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(this.search);
    
    this.search.type = 1;  
    
    if(this.search.type_name == undefined){
      this.search.type_name = 'department';
    }
    
    if(action == "refresh"){
      this.search = {};
      this.search.type_name = 'region';
      this.start = 0;
    }
    
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/department_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.department = result['data'];
      // for(var i=0;i<this.department.length;i++)
      // {
      //   for(var j=0;j<this.department[i].complaint_category.length;j++)
      //   {
      //     if(this.department[i].complaint_category[j]['checked'] == true)
      //     {
      //       this.assigned_category.push({'category_name':this.department[i].complaint_category[j]['category_name'] ,'category_id':this.department[i].complaint_category[j]['id'] ,'department_name': this.department[i]['department_name'] ,'department_id':this.department[i]['id'] });
      //     }
      //   }
      
      // }
      
      console.log(this.department);
      console.log("assign cat list");
      
      console.log(this.assigned_category);
      this.rqst_count = result['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      this.progress = '';
    },error=>{
      console.log(error);
    });
  }
  
  regionCode(region_code)
  {
    console.log(region_code);
    this.db.FetchData({'region_code':region_code},'master/check_region_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flags=true;
      }
      else{
        this.flags=false;
      }
    },error=>{
      console.log(error);
    });
  }
  
  regionName(region_name)
  {
    console.log(region_name);
    this.db.FetchData({'region_name': region_name},'master/check_region_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flags=true;
      }
      else{
        this.flags=false;
      }
    },error=>{
      console.log(error);
    });
  }
  
  DepartmentName(department_name){
    console.log(department_name);
    this.db.FetchData({'department_name':department_name},'master/check_department_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flags=true;
      }
      else{
        this.flags=false;
      }
    },error=>{
      console.log(error);
    });
  }
  
  PartName(part_name:any){
    console.log(part_name);
    this.db.FetchData({'part_name':part_name},'master/check_part_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flags=true;
      }
      else{
        this.flags=false;
      }
    },error=>{
      console.log(error);
    });
  }
  
  ComplaintCategoryName(comp_category_name:any){
    console.log(comp_category_name);
    this.db.FetchData({'comp_category_name':comp_category_name},'master/check_comp_category_existence	')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flags=true;
      }
      else{
        this.flags=false;
      }
    },error=>{
      console.log(error);
    });
  }
  part_count;
  GetSparePartList(pagelimit:any=100,start:any=0,action:any='')
  {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(this.search);
    this.search.type = 1;  
    
    // if(this.search.type_name ==  'spareparts') 
    // {
    //   this.search.type_name = 'spareparts';
    // }
    
    if(action == "refresh")
    {
      this.search = {};
      this.search.type_name = 'region';
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/spare_part_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.part_list = result['data']['list'];
      this.part_count = result['data']['count'];

      console.log(this.part_list);
      
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      this.progress = '';

      console.log('req count' + this.rqst_count);
      console.log('pagelimit' + this.pagelimit);
    },error=>{
      console.log(error);
      // this.dialog.error('Something went wrong !!! Try Again...');
    });
  }
  
  GetRegionList(pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(this.search);
    this.search.type = 1;  
    
    // if(this.search.type_name == 'region') 
    // {
    //   this.search.type_name = 'region';
    // }
    
    if(action == "refresh")
    {
      this.search = {};
      this.search.type_name = 'department';
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/region_list')
    .subscribe((result:any)=>{
      console.log(result);
      
      this.region_list = result['data'];
      console.log(this.region_list);
      
      this.rqst_count = result['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      this.progress = '';
    },error=>{
      console.log(error);
      // this.dialog.error('Something went wrong !!! Try Again...');
    });
  }
  
  GetComplaintCategoryList(action:any=''){
    {
      this.loader = "1";
      this.progress = "1";
      console.log(this.start);
      console.log(action);
      console.log(this.search);
      this.search.type = 1;  
      // if(this.search.type_name == 'complaint_category') 
      // {
      //   this.search.type_name = 'complaint_category';
      // }
      if(action == "refresh")
      {
        this.search = {};
        this.search.type_name = 'region';
        this.start = 0;
      }
      this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/complaint_category_list')
      .subscribe((result:any)=>{
        console.log(result);
        
        this.complaint_category_list = result['data'];
        console.log(this.complaint_category_list);
        
        this.rqst_count = result['count'];
        this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
        this.pagenumber = (this.start/this.pagelimit)+1;
        this.loader = '';
        this.progress = '';
      },error=>{
        console.log(error);
      });
    }
  } 
  
  GetIssueList(pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(this.search);
    this.search.type = 1;  
    
    if(action == "refresh")
    {
      this.search = {};
      this.search.type_name = 'complaint_issue';
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/issues_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.issues_list = result['data'];
      console.log(this.issues_list);
      
      this.rqst_count = result['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      this.progress = '';
    },error=>{
      console.log(error);
      // this.dialog.error('Something went wrong !!! Try Again...');
    });
  }
  
  GetRoleList(pagelimit:any=20,start:any=0,action:any='')
  {
    console.log("i m in role");
    
    this.loader = "1";
    this.progress = "1";
    this.pagelimit = pagelimit
    this.start = start;
    console.log(this.start);
    console.log(action);
    console.log(this.search);
    this.search.type = 1;  
    
    if(action == "refresh")
    {
      this.search = {};
      this.search.type_name = 'role';
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'master/role_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.role_list = result['data'];
      console.log(this.role_list); 
      this.rqst_count = result['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      this.progress = '';
    },error=>{
      console.log(error);
      // this.dialog.error('Something went wrong !!! Try Again...');
    });
  }
  
  delete_master(from,index,id,comp_type:any='')
  {
    console.log(index);
    console.log(comp_type);

    console.log(from);
    console.log(id);
    this.dialogbox.delete(from +' !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({'id': id,'from':from,'complaint_type':comp_type},'master/delete_master')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            if(from=='Region')
            this.region_list.splice(index,1);
            else if(from=='Department')
            this.department.splice(index,1);
            else if(from=='Part')
            this.part_list.splice(index,1);
            else if(from=='Comp_category'){
              from="Category";
              this.complaint_category_list.splice(index,1);
            }
            else if(from=='Issue')
            this.issues_list.splice(index,1);
            this.toast.openSucess(from + ' ' + 'Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
  
  delete_role_permission(role_index,cat_index,perm_index,role_id,comp_category_id,action_id)
  {
    console.log(perm_index);
    
    this.dialogbox.delete('Permission ').then((result) => {
      console.log(result);
      if(result)
      {
        let data = {'role_id':role_id,'comp_category_id':comp_category_id,'action_id':action_id};
        console.log(data);
        
        this.db.FetchData({'data': data},'master/delete_master_role_permission')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            this.role_list[role_index].complaint_category[cat_index].permission_array.splice(perm_index,1);
            this.toast.openSucess('Permission Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
  
  // Delete complaint category
  delete_complaint_category(role_index,cat_index,role_id,comp_category_id,comp_category_name,id)
  {
    console.log(role_index);
    console.log(cat_index);
    console.log(role_id);
    console.log(comp_category_id);
    console.log(comp_category_name);
    
    this.dialogbox.delete('Category ').then((result) => {
      console.log(result);
      if(result)
      {
        let data = {'role_id':role_id,'comp_category_id':comp_category_id,'comp_category_name':comp_category_name,'id':id};
        console.log(data);
        
        this.db.FetchData({'data': data},'master/delete_master_role_comp_category')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {

            this.role_list[role_index].complaint_category.splice(cat_index,1);
            
            this.toast.openSucess('Category Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }

  delete_role(index,role_id)
  {
    console.log(index);
    
    this.dialogbox.delete('Role !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({'role_id': role_id},'master/delete_role')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            this.role_list.splice(index,1);
            this.toast.openSucess('Role Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
  
  AddRole(){
    console.log("i am in add role function");
    
  }
  
  statusChange(status,id,comp_type,index){
    console.log(status);
    console.log(id);
    console.log(comp_type);
    this.dialogbox.statusChangeConfirmation(' status').then((result) => {
      console.log(result); //true
      if(result)
      {
        this.db.FetchData({'id': id,'status':status,'complaint_type':comp_type},'master/update_master_issue_status')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            console.log(this.issues_list[index]['status']);
            if(this.issues_list[index]['status']=='Active'){
              this.issues_list[index]['status']='Deactive';
            }
            else{
              this.issues_list[index]['status']='Active';
            }
            this.toast.openSucess('Status Changed Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }
 
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../_services/database.service';
import * as jwt_decode from "jwt-decode";
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  flag=0;
  user_id: any;
  type: any;
  profile_data: any=[];
  resp: any=[];
  logged_in_type: any;
  details: any=[];
  active: any = {}; 
  district_list: any=[];
  state_list: any=[];
  department: any=[];
  form:any={};
  hide=true;
  showcancelbutton=false;
  
  constructor(public db: DatabaseService,public toast: SnackBarOverview) {  
    this.getToken();
    this.getUserProfile(this.user_id,this.type);
    this.GetDepartmentList();
    this.GetStates();
  }
  
  ngOnInit() {
  }
  
  edit_name() { this.active.name = "1"; this.active.email="0"; this.active.mobile="0"; this.active.mobile_no="0"; this.active.department="0"; this.active.password="0";}
  edit_email() { this.active.name = "0"; this.active.email="1"; this.active.mobile="0"; this.active.mobile_no="0"; this.active.department="0"; this.active.password="0";}
  edit_mobile() { this.active.name = "0"; this.active.email="0"; this.active.mobile="1"; this.active.mobile_no="0";this.active.department="0";this.active.password="0"; }
  edit_mobile_no(){ this.active.name = "0";this.active.email="0";this.active.mobile="0"; this.active.mobile_no="1"; this.active.department="0";this.active.password="0"; }
  edit_dept() { this.active.name = "0"; this.active.email="0"; this.active.mobile="0";  this.active.mobile_no="0";this.active.department="1";this.active.password="0"; }
  edit_bmdept(){this.active.name = "0";this.active.email="0";this.active.mobile="0";this.active.mobile_no="0";this.active.department="0"; this.active.BM_Department ="1";this.active.password="0"; }
  edit_password() { this.active.name = "0"; this.active.email="0"; this.active.mobile="0"; this.active.mobile_no="0";this.active.department="0"; this.active.password="1";}

  user_type:any='';
  getToken(){
    if (localStorage.getItem('access_token')) {
      this.db.token = localStorage.getItem('access_token');
      let tokenInfo = this.getDecodedAccessToken(this.db.token); // decode token
      let expireDate = tokenInfo.exp; // get token expiration dateTime
      this.user_id=tokenInfo['id'];
      this.type=tokenInfo['logged_in_type'];
      this.user_type=tokenInfo['user_type'];

      console.log(this.user_id);
      console.log(this.type);
      
      
      this.db.tokenInfo = tokenInfo;
      return true;
    }
  }
  getDecodedAccessToken(token: string): any {
    try{
      return jwt_decode(token);
    }
    catch(Error){
      return null;
    }
  }
  
  getUserProfile(user_id,type){
    console.log(user_id);
    console.log(type);
    this.logged_in_type=type;
    this.db.FetchData({'id':user_id,'logged_in_type':type},'master/get_profile')
    .subscribe(resp=>{
      console.log(resp);
      this.resp=resp;  
    })
  }
  
  // saveDetails
  saveDetails()
  {
    console.log("i am in save function");
    
    this.flag=0;
    // this.submitted = true;
    if (this.resp.invalid) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { 
      this.db.FetchData({'id':this.user_id,'logged_in_type':this.logged_in_type,'data':this.resp},'master/update_profile')
      .subscribe((result)=>{
        console.log(result);
        this.details=result;
        this.toast.openSucess('Details Updated Succesfully','');
      },error=>{
        console.log("error");
        console.log(error);
        this.toast.openError('Something went wrong!!! Try Again..','');
      });
    }
    this.active = {};
    this.showcancelbutton=false;
  }
  
  editDetails()
  {
    console.log("i am in function");
    this.showcancelbutton=true;
    this.flag=1;
  }

  cancel()
  {
    this.flag=0;
    this.showcancelbutton=false;

  }

  GetStates()
  {   
    this.db.FetchData({},'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
    this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }
  
  // master/department_list
  GetDepartmentList(){
    this.db.FetchData({},'master/department_list')
    .subscribe(result=>{
      console.log(result);
      this.department=result['data'];
    }); 
  }
  
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder, Form} from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';


@Component({
  selector: 'app-add-customer-care',
  templateUrl: './add-customer-care.component.html'
})

export class AddCustomerCareComponent implements OnInit {

  data:any={};
  form:any={};
  state_list=[];
  district_list:any=[];
  user:any={};
  flag:any;
  isDisabled: boolean;
  
  constructor(public db:DatabaseService,public formbuilder:FormBuilder,public router:Router,public toast: SnackBarOverview)
  {
    this.getstate();
  }
  
  ngOnInit() {
    this.form.country = 'India';
    this.isDisabled=false;

  }

  getuserCode(user_code)
  {
    console.log("first block");
    
    this.db.FetchData({'user_code':user_code},'Customer_Care/check_user_code_existence')
    .subscribe((result:any)=>{
      console.log("first");
      console.log(result);
      if(result['message']=="Success")
      {
        this.flag=true;
      }
      else{
        this.flag=false;
      }
      console.log("second");
      
    },error=>{console.log(error);
    }
    );
  }


  getstate()
  {   
    this.db.FetchData({}, 'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
        this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }
  
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  validate(event:any)
  {
    const pattern = /^[0-9a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }


  submit()
  {
    this.isDisabled=true;
    console.log(this.form);
    this.db.FetchData({'data':this.form},'Customer_Care/save_user')
    .subscribe((result:any)=>{
      console.log(result);
      this.router.navigate(['/customer-care-list']);
    });
  }

  charonly(event: any){const pattern =/^[a-zA-Z ]+$/;let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) {event.preventDefault();} }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-branch-user-data',
  templateUrl: './import-branch-user-data.component.html',
  styleUrls: ['./import-branch-user-data.component.scss']
})
export class ImportBranchUserDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit,Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-edit-complaint-item',
  templateUrl: './edit-complaint-item.component.html',
  styleUrls: ['./edit-complaint-item.component.scss']
})
export class EditComplaintItemComponent implements OnInit {
  order_qty: any;
  cat_code: any;
  item_name: any;
  complaint_id: any;
  item_id: any;
  issue_type: any=[];
  complaint_type: any;
  issue_type_array: any=[];
  row_issue_array: any=[];
  error:any = [];
  checkedItems:any=[];
  level3_code:any;

  
  constructor(private dialogRef:MatDialogRef<EditComplaintItemComponent>,@Inject(MAT_DIALOG_DATA)data, public db:DatabaseService,) { 
    this.order_qty = data.qty;
    this.complaint_id = data.complaint_id;
    this.item_id = data.item_id;
    
    this.item_name = data.description;
    this.cat_code = data.cat_code;
    this.issue_type_array = data.item_array;
    this.complaint_type= data.complaint_type;
    this.row_issue_array=data.issue_array;
    this.level3_code = data.level3_code;

    // console.log(this.item_name);
    // console.log(this.cat_code);
    // console.log(this.order_qty);
    // console.log(this.issue_type_array);
    // console.log(this.issue_type['issue_data']);
    // console.log(this.row_issue_array);
  }
  
  ngOnInit() {
    this.get_all_issues();
    
  }
  
  get_all_issues()
  {
    this.db.FetchData({'complaint_type':this.complaint_type,'cat_code':this.cat_code,'level3_code':this.level3_code},'complaint/get_comp_type_issues')
    .subscribe((result)=>{
      console.log(result);
      if(result['message']=='Success')
      {
        var result_data = result['data'];
        console.log(result_data);
        console.log(result_data.length);
        console.log(this.issue_type);
        if(this.complaint_type=='Logistic')
        {
          for(var i=0;i<result_data.length;i++)
          {
            this.issue_type.push({issue_type:result_data[i].issue_type,fault_qty:'',remark:'',checked:false,item_id:this.item_id,order_qty:this.order_qty,id:''});
          }
        }
        else if(this.complaint_type=='Product')
        {
          for(var i=0;i<result_data.length;i++)
          {
            this.issue_type.push({issue_type:result_data[i].issue_type,fault_qty:'',remark:'',checked:false,item_id:this.item_id,order_qty:this.order_qty,id:''});
          }
        }
        console.log(this.issue_type);
        for(var i=0;i<this.row_issue_array.length;i++)
        {
          let temp_index = this.issue_type.findIndex(({ issue_type }) => issue_type == this.row_issue_array[i].issue_type); 
          
          console.log(temp_index);
          if(temp_index!=-1)
          {
            this.issue_type[temp_index].checked=true;
            this.issue_type[temp_index].id=this.row_issue_array[i].id;
            
            if(this.complaint_type=='Logistic')
            this.issue_type[temp_index].fault_qty=this.row_issue_array[i].fault_qty;
            else if(this.complaint_type=='Product')
            this.issue_type[temp_index].fault_qty=this.row_issue_array[i].fault_qty;

            this.issue_type[temp_index].remark=this.row_issue_array[i].remark;
            this.issue_type[temp_index].item_id=this.row_issue_array[i].item_id;
            
            this.checkedItems.push(this.issue_type[temp_index]);
          }
        }
      }
    },error=>{
      console.log("error");
      console.log(error);
    });
  }
  
  data:any = {};
  qty_error:any = [];
  CheckQty(index:any)
  {
    console.log(this.issue_type[index]);
    console.log(this.issue_type[index]['order_qty']);
    
    this.data = Object.assign({},this.issue_type[index]);
    console.log(this.data);
    
    console.log(this.data.fault_qty);
    
    
    if((this.data.fault_qty <= 0) || ((this.data.fault_qty) > this.data.order_qty) || this.data.fault_qty==null)
    {
      console.log("hello");
      
      this.error[index] = Object.assign({'qty' : 1});
      this.issue_type[index].checked=false;
      this.data.checked=false;
      // let exist_row = this.checkedItems.filter(x=>x.issue_type===this.data.issue_type)[0];
      // console.log(exist_row);
      // this.checkedItems.splice(0,1);

      let existindex = this.checkedItems.findIndex(({ issue_type }) => issue_type == this.data.issue_type); 
      console.log(existindex);
      if(existindex!=-1)
      this.checkedItems.splice(existindex,1);

    }
    else if(this.data.fault_qty!=null)
    {
      console.log("hey");
      
      this.error[index] = Object.assign({'qty' : 0});
      this.issue_type[index].checked=true;
      this.data.checked=true;
      
      let exist_row = this.checkedItems.filter(x=>x.issue_type===this.data.issue_type)[0];
      console.log(exist_row);
      if(!exist_row)
      this.checkedItems.push(this.data);
    }
    
    this.qty_error = this.error.filter((x) => x.qty == 1);//for future use
    
    console.log(this.error);
    console.log(this.data);
    console.log(this.qty_error);
    console.log(this.checkedItems);
  }

  getCheckedRow(event,index)
  {
    console.log(index);
    
    // Object.assign({},this.issue_type[index]);
    console.log(this.issue_type[index]);
    console.log(this.issue_type);
    console.log(this.checkedItems);

    this.issue_type[index] = Object.assign({},this.issue_type[index]);
    this.issue_type[index]['checked'] = !this.issue_type[index]['checked'];   
    console.log(this.issue_type[index]);

    if(this.issue_type[index]['checked']==true)
    {
      console.log("CheckedTrue");
      
      if(this.issue_type[index]['fault_qty'] && (this.issue_type[index]['fault_qty'] <= this.issue_type[index]['order_qty']))
      {
        console.log(this.issue_type[index]);
        
        this.checkedItems.push(this.issue_type[index]);
      }
    }
    
    else if(this.issue_type[index]['checked']==false)
    {
      console.log("CheckedFalse");
      let existindex = this.checkedItems.findIndex(({ issue_type }) => issue_type == this.issue_type[index].issue_type); 
      console.log(existindex);
      if(existindex!=-1)
      this.checkedItems[existindex]['checked']=false;
    }    

    console.log(this.checkedItems);
    
  }
  
  submit() 
  {
    var j=0;
    console.log(this.checkedItems);
    console.log(this.issue_type);
    
    for(var i=0;i<this.issue_type.length;i++)
    {
      console.log(this.issue_type[i].remark);
      if(this.issue_type[i].checked==true)
      {
        this.checkedItems[j] = Object.assign({},this.issue_type[i]);
        j++;
      }
    }
    console.log(this.checkedItems);    
    console.log(this.issue_type);   
    console.log(this.row_issue_array);
    
    let data = {complaint_id:this.complaint_id,type:this.complaint_type,items_issue:this.checkedItems,item_id:this.item_id};
    console.log(data);
    
    this.db.FetchData({'data': data},'complaint/update_complaint_item_issue')
    .subscribe((result: any) => {
      console.log(result);
      this.dialogRef.close(result['message']);
    }, error => {
      console.log(error);
    });
    
  }
  
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-complaint-list',
  templateUrl: './complaint-list.component.html'
})
export class ComplaintListComponent implements OnInit {
  loader:any = '';  
  Comp_Data:any=[];
  data:any=[];
  dealer_name:string='';

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent) { }

  ngOnInit() {
    this.dealer_name = this.db.dealer_name;
    this.getComplaintList(this.db.dealer_id);
    console.log(this.router);
    
  }

  getComplaintList(dealer_id)
  {
    this.loader = '1';
    this.db.FetchData({'dealer_id':dealer_id},'dealer/get_dealer_complaints')
    .subscribe((result: any) => {
      console.log(result);
      this.Comp_Data = result['data']['complaints'];
      console.log(this.Comp_Data);
      
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';      
    });
  }

  delete_comp(id:any,index:any)
  {
    console.log(id);    
    this.dialog.delete('Complaint !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'dealer/delete_complaint/'+id).subscribe((response)=>{
          console.log(response);
          if(response['message']=='Success'){
          this.Comp_Data.splice(index,1);
          this.toast.openSucess('Complaint Deleted Sucessfully ','');    
          }  

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.Comp_Data);
  }
}
import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute} from '@angular/router';
import { Router } from '@angular/router';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    allweek_mnth_year_complaint:any=[];
    complaintcount: any;
    internalticket: any;
    branchcount:any;
    dealercount:any;
    comp_category:any=[];
    
    logs: any;  
    loader:any = ''; 
    isActive:any={};
    branch_wise_comp:any=[];
    all_level1_data:any = [];
    branchlist:any=[];
    
    
    
    weekly_bar_chart_complaint: any=[];
    monthly_bar_chart_complaint: any=[];
    yearly_bar_chart_complaint: any=[];
    
    weekly_line_chart_complaint: any=[];
    monthly_line_chart_complaint: any=[];
    token_info:any={};
    search:any = {};
    
    public linecanvas: ElementRef;
    public linecontext: CanvasRenderingContext2D;
    public linechartType: string = 'line';
    public linechartData: any[];
    public linechartLabels: any[];
    public linechartColors: any[];
    public linechartOptions: any;
    
    //line chart
    public isLoadCanvas:any = false;
    public isLoadCanvasMonth:any = false;
    
    //bar chart
    public IsLoadBarYearcanvas:any = false;
    public IsLoadBarMonthCanvas:any = false;
    public IsLoadBarWeekCanvas:any = false;
    public barchartColors: any[];
    
    constructor(public db:DatabaseService,public route:ActivatedRoute,public router:Router) {
    }
    
    ngOnInit() {
        this.getDashboard_data();
        this.get_all_comp_cate();
        this.get_branch_list();
        
        this.linechartColors = [{
            backgroundColor: '#0095d8',
            borderColor: '#0095d8'
        }];
        
        // this.barchartColors = [{ backgroundColor:"#ea7239" },
        // { backgroundColor:"#26917a" }];
        
        this.search.line_comp_cat='All';
        this.search.bar_branch='All';
        
        this.isActive.linechart_week_active=1;
        
        this.isActive.weekcomp_catlable=1;
        this.isActive.closeComp_year_active=1;
        this.isActive.barchart_year_active=1;
        this.token_info = this.db.tokenInfo;
        
    }
    
    
    
    get_all_comp_cate()
    {
        this.db.FetchData({},'master/complaint_category_list').subscribe(resp=>{
            this.comp_category=resp['data'];
            console.log(this.comp_category);
        });
    }
    
    get_branch_list()
    {
        this.db.FetchData({},'master/branch_list_forsearch').subscribe(resp=>{
            console.log(resp);
            this.branchlist=resp['data'];
            console.log(this.branchlist);
        });
    }
    
    getDashboard_data()
    {
        console.log("hello"); 
        this.loader = "1";
        this.db.FetchData({'search':this.search},'dashboard/dashboard_data')
        .subscribe((resp:any)=>{
            console.log(resp);     
            
            if(resp['message']=='Success')
            {
                console.log(resp);
                this.complaintcount = resp['data']['comp'];        
                this.internalticket = resp['data']['internal_ticket']['total_internal_ticket'];
                this.branchcount = resp['data']['branch']['total_branch'];
                this.dealercount = resp['data']['dealer']['total_dealer'];
                this.comp_category = resp['data']['comp_category'];
                
                this.branch_wise_comp=resp['data']['branch_wise_comp'];
                this.all_level1_data=resp['data']['all_level1_data'];
                
                // bar chart
                this.weekly_bar_chart_complaint = resp['data']['weekly_complaint_bar_chart'];
                this.monthly_bar_chart_complaint = resp['data']['month_complaint_bar_chart'];
                this.yearly_bar_chart_complaint = resp['data']['financial_year_bar_chart'];
                this.getBarChartDataDefault(this.yearly_bar_chart_complaint,'year');
                
                //line chart
                this.weekly_line_chart_complaint = resp['data']['weekly_complaint_line_chart'];
                this.monthly_line_chart_complaint = resp['data']['month_complaint_line_chart'];
                this.getLineChartDataDefault(this.weekly_line_chart_complaint, 1); 
            }
            this.loader = '';
        },error=>{
            console.log(error);
            this.loader = '';
        });
    }
    
    // BAR CHART START 
    public barChartLabels:string[]=[];
    public barChartType:string = 'bar';
    public barChartLegend:boolean = true;
    
    
    public barChartData:any[] = [
        {
            data: [],
            label: '',
            padding: '100px'
        },
        
        {
            data: [],
            label: '',
            padding: '100px'
        },

        {
            data: [],
            label: '',
            padding: '100px'
        }
    ];
    
    
    
    
    public barchartClicked(e:any,type):void
    {
        console.log(type);
        
        console.log(e);  
        console.log(e.active);
        console.log(e.active[0]);
        console.log(e.active[0]._model);
        console.log(e.active[0]._model.datasetLabel);
        console.log(e.active[0]._model.label);
        
        this.db.complaint_active_tab = 'All';
        this.router.navigate(['dashboard-complaints-list',{'dashboard':true,'dashboard_from':'bar_chart','complaint_type':e.active[0]._model.datasetLabel,'date':e.active[0]._model.label,"chart_type":type}]);   
    }
    
    
    public barchartHovered(e:any):void {
        console.log(e);
    }
    
    
    bar_chart_search_branch(bar_branch:any,type)
    { 
        console.log(bar_branch);
        this.search.bar_branch = bar_branch; 
        console.log(this.search.bar_branch);
        this.loader = "1";    
        this.db.FetchData({'search':this.search},'dashboard/getchart_search_data')
        .subscribe((resp:any)=>{
            console.log(resp);    
            if(resp['message']=='Success')
            {
                console.log(resp);
                let bartype='';
                
                console.log('year  ' + this.IsLoadBarYearcanvas);
                console.log('month  ' + this.IsLoadBarMonthCanvas);
                console.log('week  ' + this.IsLoadBarWeekCanvas);
                
                if(this.IsLoadBarYearcanvas==true)
                {
                    this.yearly_bar_chart_complaint=[];
                    this.yearly_bar_chart_complaint = resp['data']['financial_year_bar_chart'];
                    this.getBarChartDataDefault(this.yearly_bar_chart_complaint, 'year'); 
                }
                else if(this.IsLoadBarMonthCanvas==true)
                {
                    this.monthly_bar_chart_complaint=[];
                    this.monthly_bar_chart_complaint = resp['data']['month_complaint_bar_chart'];
                    this.getBarChartDataDefault(this.monthly_bar_chart_complaint, 'month'); 
                }
                
                else if(this.IsLoadBarWeekCanvas==true)
                {
                    this.weekly_bar_chart_complaint=[];
                    this.weekly_bar_chart_complaint = resp['data']['weekly_bar_chart_complaint'];
                    this.getBarChartDataDefault(this.weekly_bar_chart_complaint, 'week'); 
                }
                this.loader = "";
                
            }
        },error=>{
            console.log(error);
            this.loader = "";
        });
    }
    
    temp_barchart_labels = [];
    
    getBarChartDataDefault(yearly_bar_chart_complaint,from)
    {        
        console.log(" i am in bar chart data ");
        console.log(yearly_bar_chart_complaint);
        
        this.barChartData=[
            {
                'data':[],
                'label':'',
                'padding':'100px'
            },
            {
                'data':[],
                'label':'',
                'padding':'100px'
            },
            {
                'data':[],
                'label':'',
                'padding':'100px'
            }
        ];
        
        this.temp_barchart_labels=[];
        console.log(yearly_bar_chart_complaint);
        console.log(this.barChartData);
        

        
        for(var i=0;i<yearly_bar_chart_complaint.length;i++)
        {
            console.log(this.yearly_bar_chart_complaint[i]);
            
            this.barChartData[i]['data']=yearly_bar_chart_complaint[i]['values'];
            this.barChartData[i]['label']=yearly_bar_chart_complaint[i]['label'];
        }
        
        console.log(yearly_bar_chart_complaint);
        
        if(yearly_bar_chart_complaint.length > 0)
        {
            for(var i=0;i<yearly_bar_chart_complaint[0]['bottom_label'].length;i++)
            {
                this.temp_barchart_labels.push(yearly_bar_chart_complaint[0]['bottom_label'][i]);
            }
        }
        console.log(this.temp_barchart_labels);
        
        this.barChartLabels=this.temp_barchart_labels;
        console.log(this.barChartData);
        
        
        if(from == 'year') {
            this.IsLoadBarYearcanvas = true;
            this.IsLoadBarMonthCanvas = false;
            this.IsLoadBarWeekCanvas = false;
            
        }
        
        else if(from == 'month') {
            this.IsLoadBarMonthCanvas = true;
            this.IsLoadBarYearcanvas = false;
            this.IsLoadBarWeekCanvas = false;
        }
        
        else if(from == 'week') {
            this.IsLoadBarWeekCanvas = true;
            this.IsLoadBarYearcanvas = false;
            this.IsLoadBarMonthCanvas = false;
        }
    }
    
    
    
    get_barchart_category(type)
    {
        console.log(type);
        this.barChartData=[];
        console.log(this.barChartLabels.length);
        this.barChartLabels.length=0;
        console.log(this.barChartLabels.length);
        if(type=='week')
        {
            console.log("week tab active");
            this.getBarChartDataDefault(this.weekly_bar_chart_complaint,'week');
        }
        else if(type=='month')
        {
            console.log("month tab active");
            this.getBarChartDataDefault(this.monthly_bar_chart_complaint,'month');
        }
        else
        {
            console.log("year tab active");
            this.getBarChartDataDefault(this.yearly_bar_chart_complaint,'year');
        }
        console.log(this.barChartLabels);
        
    }
    
    
    
    // bar chart end
    
    
    
    /******** line chart start ******/
    
    getLineChartDataDefault(line_chart_complaint, src)
    {
        console.log("i m in linechart");
        console.log(line_chart_complaint);
        
        this.linechartData = [{
            data: []
        }];
        this.linechartLabels = [];
        
        let temp_line_data=[];
        let temp_line_labels=[];
        
        for(var i=0;i<line_chart_complaint.length;i++)
        {
            console.log(line_chart_complaint[i]['avg_comp']);
            
            temp_line_labels.push(line_chart_complaint[i]['Date']);
            temp_line_data.push(line_chart_complaint[i]['avg_comp']);
            
        }
        
        
        this.linechartData = [{
            data: temp_line_data,
            label: '',
            fill: false
        }];
        
        this.linechartLabels = temp_line_labels;
        
        console.log("line chart label");
        console.log(this.linechartLabels);
        
        console.log("line chart data");
        console.log(this.linechartData);
        
        this.linechartOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        stepSize: 1
                    }
                }]
            },
        }   
        
        if(src == 1) {
            
            this.isLoadCanvas = true;
            this.isLoadCanvasMonth = false;
        }
        
        if(src == 2) {
            this.isLoadCanvas = false;
            this.isLoadCanvasMonth = true;
        }
        
    }
    
    
    get_linechart_data(type)
    {
        console.log(type);
        if(type=='week')
        {
            console.log("week tab active");
            this.getLineChartDataDefault(this.weekly_line_chart_complaint, 1);
        }
        else if(type=='month')
        {
            console.log("month tab active");
            this.getLineChartDataDefault(this.monthly_line_chart_complaint, 2);
        }
        
    }
    
    line_chart_search_comp_cat(line_comp_cat:any)
    { 
        console.log(line_comp_cat);
        this.search.line_comp_cat = line_comp_cat; 
        console.log(this.search.line_comp_cat);
        
        this.db.FetchData({'search':this.search},'dashboard/getchart_search_data')
        .subscribe((resp:any)=>{
            console.log(resp);     
            
            if(resp['message']=='Success')
            {
                this.weekly_line_chart_complaint=[];
                this.monthly_line_chart_complaint=[];
                
                console.log(resp);
                
                if(this.isLoadCanvas==true)
                {
                    this.weekly_line_chart_complaint = resp['data']['weekly_complaint_line_chart'];
                    this.getLineChartDataDefault(this.weekly_line_chart_complaint, 1); 
                }
                else if(this.isLoadCanvasMonth==true)
                {
                    this.monthly_line_chart_complaint = resp['data']['month_complaint_line_chart'];
                    this.getLineChartDataDefault(this.monthly_line_chart_complaint, 2); 
                }
            }
        },error=>{
            console.log(error);
        });
    }
    
    
    /******** line chart end ******/
    
    
    GoToComplaints()
    {
        this.router.navigate(['complaints-list']);    
        
    }
    GoToDealers()
    {
        this.router.navigate(['dealer-list']);    
    }
    
    GoToBranchs()
    {
        this.router.navigate(['branch-list']);    
    }
    
    GoToTickets()
    {
        this.router.navigate(['internal-tickets']);    
        
    }
    
    
    /******product level,branch wise complaint filteration start ******/
    get_complaint_search_data(from,type,status)
    {
        console.log(this.search);
        console.log(type);
        console.log(status);
        if(from=='product_level1')
        {
            this.router.navigate(['dashboard-complaints-list',{'dashboard':true,'dashboard_from':'product_level1','product_level1':type,'status':status,"branch_code":this.search.bar_branch}]);    
        }
        else if(from=='branch')
        {
            this.router.navigate(['dashboard-complaints-list',{'dashboard':true,'dashboard_from':'branch','branch_code':type,'status':status}]);    
        }
        
    }
    
    /******product level,branch wise complaint wise filteration end ******/
    

    level_1_data()
    {
        this.db.FetchData({"search":this.search},"dashboard/product_level_1")
        .subscribe(resp=>{
            console.log(resp);
            this.all_level1_data=resp['data']['all_level1_data'];
        })
    }
    
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { ActivatedRoute,Router } from '@angular/router';
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit
{
  loader:any='1';
  invoice_info:any={};
  invoice_item:any=[];
  toast:any;
  id:any;
  tokenInfo;any={};
  page:any;
  searched_data: any=[];
  constructor(public db:DatabaseService ,public route:ActivatedRoute) { 
    this.tokenInfo = this.db.tokenInfo;
    this.searched_data=this.db.search_data;
    console.log(this.db.search_data);
  }
  
  ngOnInit() {
    this.route.params.subscribe(params=>{
      this.id=params['id'];
      this.page=params['page'];
    });
    console.log(this.id);
    this.Invoice(this.id);
    console.log(this.db.complaint_type);
    console.log(this.db.coming_from);
    
  }
  
  Invoice(invoice_no: any)
  {
    this.loader=1;
    this.db.FetchData({'invoice_no':invoice_no},'complaint/get_invoice_info_new')
    .subscribe((result:any)=>{
      console.log(result);
      // this.loader='';
      if(result['message']=="Success")
      {
        this.invoice_info = result['data']['inv_data'][0];
        this.invoice_item = result['data']['inv_data'][0].items;
        console.log(this.invoice_info);
        console.log(this.invoice_item);
        this.loader='';
      }
      else{
        this.invoice_info={};
        // this.invoice_item=[];
      }
    },error=>{
      console.log(error);
      this.loader='';
      this.toast.openError('Something went wrong !!! Try Again...','');
    });
  }
  
  setValue()
  {
    this.db.setSearchValue(this.searched_data);
  }
  ComingFromBack()
  {
    console.log("i am in function back");
    console.log(this.db.coming_from);
    
    this.db.coming_from='back';
  }
}
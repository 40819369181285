import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html'
})
export class DealerListComponent implements OnInit {
  listingData:any=[];
  dealer_comp_count:any=[];
  dealer:any=[];
  loader:any = '';
  progress:any = '';
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  print_totalcomp:any=[];
  branchlist:any=[];
  sendRequest:any=true;
  token_info: any={};
  public listing;

  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent) {
    this.listing = new Array(8);
   }
 
  ngOnInit() {
    this.getdealerlist();
    this.get_branch_list();
    this.token_info=this.db.tokenInfo;
  }

  importData(){
    console.log("i m in function");


  }
  validate(event:any)
  {
    const pattern = /^[0-9 ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
    {event.preventDefault();}
  }
  search_dealer_name(dealer_name:any)
  {
    this.search.dealer_name=dealer_name;
    console.log(this.search.dealer_name);
    this.getdealerlist();   
  }
  search_dealer_code(dealer_code:any)
  {
    this.search.dealer_code=dealer_code;
    console.log(this.search.dealer_code);
    this.getdealerlist();
  }
  search_mobile(mobile:any)
  {
    this.search.mobile=mobile;
    console.log(this.search.mobile);
    this.getdealerlist();
  }
  search_address(address:any)
  {
    this.search.address=address;
    console.log(this.search.address);
    this.getdealerlist();
  }
  search_branch_name(branch_name:any)
  {
    this.search.branch_name=branch_name;
    console.log(this.search.branch_name);
    this.getdealerlist();
  }
  search_branch(branch:any)
  {
    this.search.branch=branch;
    console.log(this.search.branch);
    this.getdealerlist();
  }
  delete_dealer(id:any,index:any)
  {
    console.log(id);    
    this.dialog.delete('Dealer !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'dealer/delete_dealer/'+id).subscribe((response)=>{
          console.log(response);
          this.listingData.splice(index,1);
          this.toast.openSucess('Dealer Deleted Sucessfully ','');      

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.listingData);
  }

  getdealerlist(pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(start);
    
    console.log(this.start);
    console.log(action);
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'dealer/dealer_list')
    .subscribe(result=>{
      console.log(result);
      this.sendRequest=false;

      this.listingData= result['data']['list'];
      this.dealer_comp_count = result['data']['dealer_comp_count'];
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      console.log(this.listingData);
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');    
      this.loader = '';
    });
  }

  GetComplaintList(){
    this.listingData = this.listingData.filter(x => x.total_complaint>=1);
  }
  GetOpenComplaintList(){
    this.listingData = this.listingData.filter(x => x.total_runn_complaint>=1);
  }
  get_branch_list()
  {
    this.db.FetchData({},'master/branch_list_forsearch').subscribe(resp=>{
      console.log(resp);
      this.branchlist=resp['data'];
      console.log(this.branchlist);
    });
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {DatabaseService} from './_services/database.service';
import { AuthGuard } from './_guard/AuthGuard';
import { AuthService } from './_services/auth_service';
import { AuthGuardLog } from './_guard/AuthGuardLog';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
//for text editor
import { NgxEditorModule } from 'ngx-editor';
import { CommonModule } from '@angular/common';
// import { EditorModule } from '../../modules/editor.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { ChartsModule } from 'ng2-charts';

import { ChartsModule } from 'ng2-charts/ng2-charts';

import {MatCardModule} from '@angular/material/card';
import { JwtModule } from '@auth0/angular-jwt';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AddServiceComponent } from './service-engineer/add-service/add-service.component';

import { AddComplaintsComponent } from './complaints/add-complaints/add-complaints.component';
// import { AddComplaintsComponent } from './complaints/add-complaints/add-complaints.component_backup';
import { ComplaintsListComponent } from './complaints/complaints-list/complaints-list.component';

import { DashboardComplaintsListComponent,RemoveUnderscorePipe } from './Dashboard-complaints/dashboard-complaints-list.component';


import { ComplaintsDetailComponent } from './complaints/complaints-detail/complaints-detail.component';
import { PartAssignModalComponent } from './complaints/part-assign-modal/part-assign-modal.component';
import { CreateTicketComponent } from './complaints/create-ticket/create-ticket.component';
import { ComplaintActionComponent } from './complaints/complaint-action/complaint-action.component';
import { ComplaintItemActionComponent } from './complaints/complaint-item-action/complaint-item-action.component';

import { TicketActionComponent } from './complaints/ticket-action/ticket-action.component';
import { AddDealerComponent } from './Dealers/add-dealer/add-dealer.component';
import { AddCustomerComponent } from './Dealers/add-customer/add-customer.component';
import { DealerListComponent } from './Dealers/dealer-list/dealer-list.component';
import { DealerDetailComponent } from './Dealers/dealer-detail/dealer-detail.component';
import { DealerTabsComponent } from './tabs/dealer-tabs/dealer-tabs.component';
import { CustomerListComponent } from './Dealers/customer-list/customer-list.component';
import { ComplaintListComponent } from './Dealers/complaint-list/complaint-list.component';
import { AddBranchComponent } from './Branch/add-branch/add-branch.component';
import { BranchListComponent } from './Branch/branch-list/branch-list.component';
import { ServiceEnggListComponent } from './service-engineer/service-engg-list/service-engg-list.component';
import { BranchUserListComponent } from './Branch/branch-user-list/branch-user-list.component';
import { BranchDetailComponent } from './Branch/branch-detail/branch-detail.component';
import { BranchUserDetailComponent } from './Branch/branch-user-detail/branch-user-detail.component';
import { BranchTabsComponent } from './tabs/branch-tabs/branch-tabs.component';
import { BranchUserTabsComponent } from './tabs/branch-user-tabs/branch-user-tabs.component';
import { BranchDealerListComponent } from './Branch/branch-dealer-list/branch-dealer-list.component';
import { BranchComplaintsListComponent } from './Branch/branch-complaints-list/branch-complaints-list.component';
import { BranchComplaintsDetailComponent } from './Branch/branch-complaints-detail/branch-complaints-detail.component';
import { StockAssignModalComponent } from './Branch/stock-assign-modal/stock-assign-modal.component';
import { AddStockModalComponent } from './Branch/add-stock-modal/add-stock-modal.component';
import { BranchSparepartListComponent } from './Branch/branch-sparepart-list/branch-sparepart-list.component';
import { BranchInstalledPartLogsListComponent } from './Branch/branch-installed-part-logs-list/branch-installed-part-logs-list.component';

import { SystemUserListComponent } from './System-User/system-user-list/system-user-list.component';
import { SystemUserDetailComponent } from './System-User/system-user-detail/system-user-detail.component';
import { SystemUserComplaintListComponent } from './System-User/system-user-complaint-list/system-user-complaint-list.component';
import { SystemUserTabsComponent } from './tabs/system-user-tabs/system-user-tabs.component';
import { SystemUserAddComponent } from './System-User/system-user-add/system-user-add.component';
import { InternalTicketsComponent } from './System-User/internal-tickets/internal-tickets.component';
import { MasterListComponent } from './Master/master-list/master-list.component';
import { AddMasterDialogComponent } from './Master/add-master-dialog/add-master-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerDetailComponent } from './Dealers/customer-detail/customer-detail.component';
import { CustomerTabsComponent } from './tabs/customer-tabs/customer-tabs.component';
import { CustomerComplaintListComponent } from './Dealers/customer-complaint-list/customer-complaint-list.component';
import { TabMasterComponent } from './Master/tab-master/tab-master.component';
import { InternalTicketDetailComponent } from './complaints/internal-ticket-detail/internal-ticket-detail.component';

import { ImportinvoiceComponent } from './Master/import-invoice/import-invoice.component';
import { BranchStockLogsModalComponent } from './Branch/branch-stock-logs-modal/branch-stock-logs-modal.component';
import { RegistrationComponent } from './Registration/registration.component';
import { AddCustomerCareComponent } from './Customer-Care/add-customer-care/add-customer-care.component';

import { CustomerCareListComponent } from './Customer-Care/customer-care-list/customer-care-list.component';
import {ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InvoiceDetailComponent } from  './complaints/invoice-detail/invoice-detail.component';
import { InvoiceListComponent } from './complaints/invoice-list/invoice-list.component';
import { ProfileComponent } from './profile/profile.component';
import { BranchUsedQuantityModalComponent } from './Branch/branch-used-quantity-modal/branch-used-quantity-modal.component';
import { AddRoleComponent } from './Master/add-role/add-role.component';
import { Select2Module } from 'ng2-select2';
import { PushNotificationsModule } from 'ng-push';

// import { PushNotificationService } from 'ngx-push-notifications';
import { MasterProductLevelDialogComponent } from './Master/master-product-level-dialog/master-product-level-dialog.component';
import { AddPermissionDialogComponent } from './Master/add-permission-dialog/add-permission-dialog.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImportDealerDataComponent } from './Dealers/import-dealer-data/import-dealer-data.component';
import { ImportBranchDataComponent } from './Branch/import-branch-data/import-branch-data.component';
import { ImportSystemUsersDataComponent } from './System-User/import-system-users-data/import-system-users-data.component';

import { ImportBranchUserDataComponent } from './Branch/import-branch-user-data/import-branch-user-data.component';

import { DealerMainComplaintListComponent } from './complaints/dealer-main-complaint-list/dealer-main-complaint-list.component';
import { DealerMainComplaintDetailComponent } from './complaints/dealer-main-complaint-detail/dealer-main-complaint-detail.component';
import { DealerMainComplaintCancelModalComponent } from './complaints/dealer-main-complaint-cancel-modal/dealer-main-complaint-cancel-modal.component';

import { ImportMasterProductComponent } from './Master/import-master-product/import-master-product.component';
import { CreateEmailComponent } from './complaints/create-email/create-email.component';
import { EditComplaintItemComponent } from './complaints/edit-complaint-item/edit-complaint-item.component';
import { ComplaintModalComponent } from './complaints/complaint-modal/complaint-modal.component';
import { AddComplaintCategoryDialogComponent } from './Master/add-complaint-category-dialog/add-complaint-category-dialog.component';
import { AddProductLevelDialogComponent } from './Master/add-product-level-dialog/add-product-level-dialog.component';
import { MatInputModule, MatFormFieldModule } from '@angular/material';
import { ComplaintProductDialogComponent } from './complaints/complaint-product-dialog/complaint-product-dialog.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ReportComponent } from './report/report.component';
import { ImportIssueTypeComponent } from './Master/import-issue-type/import-issue-type.component';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent},

  { path: 'add-branch-user', component: AddServiceComponent, canActivate: [AuthGuard]},
  { path: 'add-complaints', component: AddComplaintsComponent, canActivate: [AuthGuard]},
  { path: 'complaints-list', component: ComplaintsListComponent, canActivate: [AuthGuard]},

  { path: 'dashboard-complaints-list', component: DashboardComplaintsListComponent, canActivate: [AuthGuard]},


  { path: 'complaints-detail/:id/:from', component: ComplaintsDetailComponent, canActivate: [AuthGuard]},
  { path: 'add-dealer', component: AddDealerComponent, canActivate: [AuthGuard]},
  { path: 'add-customer', component: AddCustomerComponent, canActivate: [AuthGuard]},
  { path: 'dealer-list', component: DealerListComponent, canActivate: [AuthGuard]},
  { path: 'dealer-detail/:id', component: DealerDetailComponent, canActivate: [AuthGuard]},
  { path: 'dealer/customer-list', component: CustomerListComponent, canActivate: [AuthGuard]},
  { path: 'customer-detail/:id', component: CustomerDetailComponent, canActivate: [AuthGuard]},
  { path: 'dealer/complaint-list', component: ComplaintListComponent, canActivate: [AuthGuard]},
  { path: 'dealer/customer-complaint-list', component: CustomerComplaintListComponent, canActivate: [AuthGuard]},
  { path: 'customer-detail/complaint-list', component: CustomerDetailComponent, canActivate: [AuthGuard]},
  { path: 'add-branch', component: AddBranchComponent, canActivate: [AuthGuard]},
  { path: 'branch-list', component: BranchListComponent, canActivate: [AuthGuard]},
  { path: 'branch-user-list', component: BranchUserListComponent, canActivate: [AuthGuard]},
  { path: 'service-engg-list', component: ServiceEnggListComponent, canActivate: [AuthGuard]},

  { path: 'branch-detail/:id', component: BranchDetailComponent, canActivate: [AuthGuard]},
  { path: 'branch-user-detail/:id', component: BranchUserDetailComponent, canActivate: [AuthGuard]},
  
  { path: 'branch-user-dealer-list', component: BranchDealerListComponent, canActivate: [AuthGuard]},
  { path: 'branch-user-complaints-list', component: BranchComplaintsListComponent, canActivate: [AuthGuard]},

  { path: 'branch-dealer-list', component: BranchDealerListComponent, canActivate: [AuthGuard]},

  { path: 'branch-complaints-list', component: BranchComplaintsListComponent, canActivate: [AuthGuard]},
  { path: 'branch-complaints-detail', component: BranchComplaintsDetailComponent, canActivate: [AuthGuard]},
  
  { path: 'branch-sparepart-list', component: BranchSparepartListComponent, canActivate: [AuthGuard]},
  { path: 'branch-installedpart-log-list', component: BranchInstalledPartLogsListComponent, canActivate: [AuthGuard]},

  { path: 'system-user-list', component: SystemUserListComponent , canActivate: [AuthGuard]},
  { path: 'system-user-detail/:id', component: SystemUserDetailComponent, canActivate: [AuthGuard] },
  { path: 'system-user-complaint-list', component: SystemUserComplaintListComponent , canActivate: [AuthGuard]},
  { path: 'system-user-add', component: SystemUserAddComponent , canActivate: [AuthGuard]},
  { path: 'internal-tickets', component: InternalTicketsComponent , canActivate: [AuthGuard]},
  { path: 'master-list', component: MasterListComponent , canActivate: [AuthGuard]},
  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard]},
  { path: 'import-invoice', component: ImportinvoiceComponent , canActivate: [AuthGuard]},
  { path: 'internal-ticket-detail/:id', component: InternalTicketDetailComponent, canActivate: [AuthGuard] },
  { path: 'add-customer-care', component: AddCustomerCareComponent, canActivate: [AuthGuard] },
  { path: 'customer-care-list', component: CustomerCareListComponent, canActivate: [AuthGuard] },
  { path: 'registration', component: RegistrationComponent},

  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'invoice-detail/:id/:page', component: InvoiceDetailComponent ,canActivate: [AuthGuard]},
  { path: 'invoice-list', component: InvoiceListComponent,canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent,canActivate: [AuthGuard] },

  { path: 'master-list/add-role', component: AddRoleComponent, canActivate: [AuthGuard]},
  { path: 'import-dealer-data', component: ImportDealerDataComponent, canActivate: [AuthGuard]},
  { path: 'import-branch-data', component: ImportBranchDataComponent, canActivate: [AuthGuard]},
  { path: 'import-system-users-data', component: ImportSystemUsersDataComponent, canActivate: [AuthGuard]},
  { path: 'import-branch-user-data', component: ImportBranchUserDataComponent, canActivate: [AuthGuard]},

  { path: 'dealer-main-complaint-list', component: DealerMainComplaintListComponent, canActivate: [AuthGuard]},
  { path: 'dealer-main-complaint-detail/:id', component: DealerMainComplaintDetailComponent, canActivate: [AuthGuard]},
  { path: 'dealer-main-complaint-cancel-modal', component: DealerMainComplaintCancelModalComponent, canActivate: [AuthGuard]},

  { path: 'import-master-product-data', component: ImportMasterProductComponent, canActivate: [AuthGuard]},
  { path: 'import-issue-type', component: ImportIssueTypeComponent, canActivate: [AuthGuard]},
  { path: 'report', component: ReportComponent,canActivate: [AuthGuard]},

];


@NgModule({
  declarations:   [
    RemoveUnderscorePipe,
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    AddServiceComponent,
    AddComplaintsComponent,
    ComplaintsListComponent,
    DashboardComplaintsListComponent,
    ComplaintsDetailComponent,
    PartAssignModalComponent,
    CreateTicketComponent,
    ComplaintActionComponent,
    ComplaintItemActionComponent,
    TicketActionComponent,
    AddDealerComponent,
    AddCustomerComponent,
    DealerListComponent,
    DealerDetailComponent,
    DealerTabsComponent,
    CustomerListComponent,
    ComplaintListComponent,
    AddBranchComponent,
    BranchListComponent,
    BranchUserListComponent,
    ServiceEnggListComponent,
    BranchDetailComponent,
    BranchUserDetailComponent,
    BranchTabsComponent,
    BranchUserTabsComponent,
    BranchDealerListComponent,
    BranchComplaintsListComponent,
    BranchComplaintsDetailComponent,
    StockAssignModalComponent,
    AddStockModalComponent,
    BranchSparepartListComponent,
    BranchInstalledPartLogsListComponent,
    SystemUserListComponent,
    SystemUserDetailComponent,
    SystemUserComplaintListComponent,
    InternalTicketsComponent,
    SystemUserTabsComponent,
    SystemUserAddComponent,
    MasterListComponent,
    AddMasterDialogComponent,
    DashboardComponent,
    CustomerDetailComponent,
    CustomerTabsComponent,
    CustomerComplaintListComponent,
    TabMasterComponent,
    InternalTicketDetailComponent,
    ImportinvoiceComponent,
    BranchStockLogsModalComponent,
    RegistrationComponent,
    AddCustomerCareComponent,
    CustomerCareListComponent,
    ForgotPasswordComponent,
    InvoiceDetailComponent,
    InvoiceListComponent,
    ProfileComponent,
    BranchUsedQuantityModalComponent,
    AddRoleComponent,
    MasterProductLevelDialogComponent,
    AddPermissionDialogComponent,
    AddProductLevelDialogComponent,
    AddComplaintCategoryDialogComponent,
    ImportDealerDataComponent,
    ImportBranchDataComponent,
    ImportSystemUsersDataComponent,
    ImportBranchUserDataComponent,
    DealerMainComplaintListComponent,
    DealerMainComplaintDetailComponent,
    DealerMainComplaintCancelModalComponent,
    ImportMasterProductComponent,
    CreateEmailComponent,
    EditComplaintItemComponent,
    ComplaintModalComponent,
    ComplaintProductDialogComponent,
    ReportComponent,
    ImportIssueTypeComponent
  ],
  imports: [
    NgxEditorModule,
    Select2Module,
    BrowserModule,
    CommonModule,
    PushNotificationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    ChartsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    FilterPipeModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [
    DatabaseService,
    AuthGuard,
    AuthGuardLog,
    AuthService,
    NgxNavigationWithDataComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    StockAssignModalComponent,
    PartAssignModalComponent,
    AddStockModalComponent,
    CreateTicketComponent,
    ComplaintActionComponent,
    ComplaintItemActionComponent,
    TicketActionComponent,
    AddMasterDialogComponent,
    BranchStockLogsModalComponent,
    ComplaintItemActionComponent,
    BranchUsedQuantityModalComponent,
    MasterProductLevelDialogComponent,
    AddPermissionDialogComponent,
    AddProductLevelDialogComponent,
    AddComplaintCategoryDialogComponent,
    CreateEmailComponent,
    EditComplaintItemComponent,
    ComplaintModalComponent,
    ComplaintProductDialogComponent,
    ImportIssueTypeComponent
  ],

})
export class AppModule { 
 
}
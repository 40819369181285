import { OnInit, Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material';


@Injectable({ providedIn: 'root' })


export class SnackBarOverview implements OnInit {
  
  constructor(public snackBar: MatSnackBar) { }
  
  ngOnInit() {
  } 

//   only two values for the verticalPosition are allowed: 'top' and 'bottom'.
// For horizontalPosition, 'start', 'center', 'end', 'left' and 'right'
  openSucess(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      // verticalPosition: 'bottom',
      verticalPosition: 'bottom', 
      horizontalPosition: 'left',
      panelClass: ['successsnack']
    });
  }

  openComplaint_Sucess(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      // verticalPosition: 'bottom',
      verticalPosition: 'top', 
      horizontalPosition: 'right',
      panelClass: ['successsnack']
    });
  }

  openError(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'bottom', 
      horizontalPosition: 'left',
      panelClass: ['errorsnack']
    });
  }
      
}
import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-customer-care-list',
  templateUrl: './customer-care-list.component.html'
})
export class CustomerCareListComponent implements OnInit {
  listingData:any=[];
  dealer_comp_count:any=[];
  dealer:any=[];
  loader:any = '';
  progress:any = '';
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  print_totalcomp:any=[];
  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent) {
   }
 
  ngOnInit() {
    this.getuserlist();
  }
 
  delete(id:any,index:any)
  {
    console.log(id);    
    this.dialog.delete('User !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'Customer_Care/delete_user/'+id).subscribe((response)=>{
          console.log(response);
          this.listingData.splice(index,1);
          this.toast.openSucess('User Deleted Sucessfully ','');      

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.listingData);
  }

  getuserlist(pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'Customer_Care/user_list')
    .subscribe(result=>{
      console.log(result);
      this.listingData= result['data']['list'];
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
      console.log(this.listingData);
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');    
      this.loader = '';
    });
  }
}
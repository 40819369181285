import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogComponent } from 'src/app/DialogComponent';
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-complaint-product-dialog',
  templateUrl: './complaint-product-dialog.component.html',
})
export class ComplaintProductDialogComponent implements OnInit {
  
  product_detail:any={};
  invoice_detail:any={};
  user_inv:any='No';
  search:any='';
  detail:any={};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public db:DatabaseService,public dialog:DialogComponent,public dialogRef:MatDialogRef<ComplaintProductDialogComponent>,public toast:SnackBarOverview) { 
    console.log(data);
    this.product_detail = data.product_detail;
    this.invoice_detail = data.invoice_detail;
    
    if(this.invoice_detail.inv_no && this.invoice_detail.inv_no!=null)
    {
      this.user_inv = "Yes";
    }
  }
  
  ngOnInit() {
    console.log(this.product_detail);
    console.log(this.invoice_detail);
    
    this.detail.level1 = this.product_detail.level1;
    this.detail.level1_code = this.product_detail.level1_code;
    this.detail.level2 = this.product_detail.level2;
    this.detail.level2_code = this.product_detail.level2_code;
    this.detail.level3 = this.product_detail.level3;
    this.detail.level3_code = this.product_detail.level3_code;
    this.detail.cat_code = this.product_detail.cat_code;
    this.detail.complaint_id = this.product_detail.complaint_id;
    this.detail.inv_item_id = this.product_detail.id;
    this.detail.description = this.product_detail.description;
    this.detail.batch_no = this.product_detail.batch_no;
    
    this.issues_list = this.product_detail.issue_data;

    this.get_product_level1();   
    this.get_product_level2();
    this.get_product_level3();
    this.get_product_catcode();
    // this.get_complaint_issue_type();
  }
  loader:any;
  level1_array:any=[];
  get_product_level1()
  {
    this.loader = '1';
    this.db.FetchData({'search':'','user_inv':this.user_inv,'comp_type':this.invoice_detail.type,'inv_id':this.invoice_detail.inv_id},"complaint/get_product_level1")
    .subscribe(resp=>{
      console.log(resp);
      this.loader = '';
      
      if(resp['data'].length > 1)
      {
        this.level1_array = resp['data'];
        console.log(this.level1_array);
      }
      else
      {
        this.toast.openError("Invoice has only 1 Item","");
        this.dialogRef.close(resp['data']);
      }
    })
  }
  
  get_level2(row)
  {
    console.log(row);
    this.detail.level1_code = row.level1_code;
    this.get_product_level2()
  }
  
  level2_array:any=[];
  
  get_product_level2()
  {
    this.loader = '1';
    
    this.db.FetchData({'search':this.detail,'user_inv':this.user_inv,'comp_type':this.invoice_detail.type,'inv_id':this.invoice_detail.inv_id},"complaint/get_product_level2")
    .subscribe(resp=>{
      console.log(resp);
      this.loader = '';
      
      this.level2_array = resp['data'];
    })
  }
  
  get_level3(row)
  {
    console.log(row);
    this.detail.level2_code = row.level2_code;
    this.get_product_level3();
  }
  
  level3_array:any=[];
  
  get_product_level3()
  {
    this.loader = '1';
    
    this.db.FetchData({'search':this.detail,'user_inv':this.user_inv,'comp_type':this.invoice_detail.type,'inv_id':this.invoice_detail.inv_id},"complaint/get_product_level3")
    .subscribe(resp=>{
      console.log(resp);
      this.loader = '';
      
      this.level3_array = resp['data'];
    })
  }
  
  get_cat_code(row)
  {
    console.log(row);
    
    this.detail.level3_code = row.level3_code;
    this.get_product_catcode();
  }

  cat_code_arr:any=[];
  get_product_catcode()
  {
    this.db.FetchData({'search':this.detail,'user_inv':this.user_inv,'comp_type':this.invoice_detail.type,'inv_id':this.invoice_detail.inv_id},"complaint/get_product_catcode")
    .subscribe(resp=>{
      console.log(resp);
      this.cat_code_arr = resp['data'];
    })
  }

  issues_list:any=[];
  get_complaint_issue_type()
  {
    this.db.FetchData({'complaint_type':this.invoice_detail.type,'cat_code':this.detail.cat_code,'level3_code':this.detail.level3_code,'level3':this.detail.level3},"complaint/get_comp_type_issues")
    .subscribe(resp=>{
      console.log(resp);
      this.issues_list = resp['data'];
      for(let i=0;i<this.issues_list.length;i++)
      {
        this.issues_list[i]['fault_qty'] = 0;
        this.issues_list[i]['remark'] = '';
        this.issues_list[i]['checked'] = false;
      }
      console.log(this.issues_list);
      
    })
  }

  get_cmp_issue(row)
  {
    this.detail.description = row.description;
    this.get_complaint_issue_type();
  }

  update_product_item()
  {
    this.detail.issue_list = this.issues_list;
    console.log(this.detail);
    this.db.FetchData({'item':this.detail},"complaint/update_complaint")
    .subscribe(resp=>{
      console.log(resp);
      if(resp['data'])
      {
        this.toast.openSucess("Item Updated","");
        this.dialogRef.close(resp['data']);
      }
      else
      {
        this.toast.openError("Somthing went wrong !","");
      }
    })
  }
  
  checked_issue:any=[];
  change_fault(indx)
  {
    console.log(this.issues_list[indx]);
    if(this.issues_list[indx]['fault_qty'] == 0 || this.issues_list[indx]['fault_qty'] == null)
    {
      // this.issues_list[indx]['checked'] = false;
      let index = this.checked_issue.findIndex(x=>x.issue_type===this.issues_list[indx]['issue_type']);
      console.log(index);
      this.checked_issue.splice(index,1);
    }
    else
    {
      // this.issues_list[indx]['checked'] = true;
      this.checked_issue.push(this.issues_list[indx]);
    }
    console.log(this.checked_issue);
    
  }
}

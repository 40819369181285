import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-import-master-product',
  templateUrl: './import-master-product.component.html',
  styleUrls: ['./import-master-product.component.scss']
})
export class ImportMasterProductComponent implements OnInit {

  constructor(public db:DatabaseService) { }

  uploadurl:any='';
  DbUrl:any="";
  ngOnInit() {
    this.uploadurl = this.db.UploadUrl;
    this.DbUrl = this.db.DbUrl;
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
  selector: 'app-master-product-level-dialog',
  templateUrl: './master-product-level-dialog.component.html',
  styleUrls: ['./master-product-level-dialog.component.scss']
})
export class MasterProductLevelDialogComponent implements OnInit {

  product_level:any=[];
  role_com_cat_id:any;
  role_id:any;
  constructor(public db:DatabaseService,public toast: SnackBarOverview,public dialogbox:DialogComponent,private dialogRef:MatDialogRef<MasterProductLevelDialogComponent>,@Inject(MAT_DIALOG_DATA)data) {
    this.product_level = data.product_level;
    this.role_id = data.role_id;
    this.role_com_cat_id = data.role_com_cat_id;

    console.log(this.role_id);
    console.log(this.role_com_cat_id);
    console.log(this.product_level);
   }
  ngOnInit() {
  }

  delete_master_role_product_level(index,level_id)
  {
    console.log(index);
    
    this.dialogbox.delete('Product Level !').then((result) => {
      console.log(result);
      if(result)
      {
        let data = {'role_id':this.role_id,'comp_category_id':this.role_com_cat_id ,'level_id':level_id};
        console.log(data);
        
        this.db.FetchData({'data': data},'master/delete_master_role_product_level')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            this.product_level.splice(index,1);
            this.toast.openSucess('Product Level Deleted Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-branch-tabs',
  templateUrl: './branch-tabs.component.html'
})
export class BranchTabsComponent implements OnInit {
  branch_name:string = '';
  encrypt_id:any = '';
  detail: any = {};

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
   }

   ngOnInit() {
    if(!this.encrypt_id)
    {
    this.encrypt_id = this.db.branch_id;
    }
    this.GetBranchDetail();
  }

  GetBranchDetail() 
  {
    this.db.FetchData({'branch_id': this.encrypt_id }, 'branch/branch_detail')
    .subscribe((result: any) => {
        console.log(result);
        this.detail = result['data']['detail'];
        this.branch_name = this.detail['name'];
        this.db.branch_id = this.encrypt_id;
        this.db.branch_name = this.branch_name;
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
    });
  }
}

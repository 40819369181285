import { Component, OnInit } from '@angular/core';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';
import { MatDialog } from '@angular/material';
import { DealerMainComplaintCancelModalComponent } from '../dealer-main-complaint-cancel-modal/dealer-main-complaint-cancel-modal.component';

@Component({
  selector: 'app-dealer-main-complaint-list',
  templateUrl: './dealer-main-complaint-list.component.html',
  styleUrls: ['./dealer-main-complaint-list.component.scss']
})
export class DealerMainComplaintListComponent implements OnInit {
  
  complaint:any;
  start:any = 0;
  pagelimit:any = 100;
  search:any = {};
  rqst_count:any;
  total_page:any ;
  pagenumber:any;
  complaintcount: any=[];
  loader:any = '';  
  tokenInfo:any={};
  length:any;
  disp_running:any=[];
  complaint_category_list:any=[];
  branchlist:any=[];
  sendRequest:any=true;
  tab: any=0;
  status_name: any;
  status:any = '';
  filter_arr:any=[];
  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent,public matdialog: MatDialog) { }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    console.log(this.tokenInfo);
    this.status_name = 'Action';
    if(this.db.complaint_active_tab)
    {
      this.status_name = this.db.complaint_active_tab;
    }
    this.getComplaintList('','','',this.status_name);
    this.getcomplaint_category();
  }
  
  validate(event:any)
  {
    const pattern = /^[0-9 ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  search_comp_category(cat:any)
  { 
    console.log(cat);
    this.search.complaint_category = cat; 
    console.log(this.search.complaint_category);
    this.getComplaintList('','','',this.status_name);
  }
  
  search_list_status(list_status:any)
  { 
    console.log(list_status);
    this.search.list_status = list_status; 
    console.log(this.search.list_status);
    this.getComplaintList('','','',this.status_name);
  }
  
  search_customer(customer:any)
  { 
    console.log(customer);
    this.search.customer = customer; 
    console.log(this.search.customer);
    this.getComplaintList('','','',this.status_name);
  }
  
  search_comp_number(comp_no:any)
  { 
    // console.log(comp_no);
    this.search.comp_no = comp_no; 
    console.log(this.search.comp_no);
    console.log(this.sendRequest);
    
    this.getComplaintList('','','',this.status_name);
  }
  
  getcomplaint_category()
  {
    this.db.FetchData({},'master/complaint_category_list')
    .subscribe((resp:any)=>{
      console.log(resp);
      this.complaint_category_list = resp['data'];
    });
  }
  
  
  OpenDialog(complaint_id,index,type) 
  {
    console.log(complaint_id);
    console.log(type);
    
    const dialogRef=this.matdialog.open(DealerMainComplaintCancelModalComponent,
      {
        width: '768px',
        data:
        {
          complaint_id: complaint_id,
          type
        }
      });
      dialogRef.afterClosed().subscribe(result => 
        {
          console.log(result);
          if(result =='Cancel')
          {
            this.toast.openSucess('Complaint Cancelled Successfully!!','');
            this.filter_arr[index].status='Cancel';
            this.getComplaintList('','','',this.status_name);
          }
          else if(result =='Reject'){
            this.toast.openError('Complaint Already Rejected By BCH!!','');
          }
    
          else if(result =='InProgress'){
            this.toast.openError('Complaint Is InProgress!!','');
          }

         
        });
      }
      
      getComplaintList(pagelimit:any=100,start:any=0,action:any='',status)
      {
        start=0;
        this.loader = "1";
        this.pagelimit = pagelimit;
        this.start = start;
        if(action == "refresh")
        {
          this.search = {};
          this.start = 0;
          this.db.complaint_active_tab='';

        }
        console.log(this.search);
        this.search.status = status;
        this.db.complaint_active_tab = status;
        
        this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'complaint/complaint_list')
        .subscribe((result:any)=>{
          console.log(result);
          this.sendRequest=false;
          this.complaint = result['data']['list'];
          this.status_name=status;

          if(status=='All'){
            this.filter_arr = result['data']['list'];
          }

          else if(status=='InProgress'){
            console.log(status);
            this.filter_arr = this.complaint.filter(x=>x.status!='Open' && x.status!='Close' && x.status!='Cancel' && x.status!='Reject' && x.status!= "User Dependency");
            console.log(this.filter_arr);
          }

          else if(status=='Action'){
            console.log(status);
            this.filter_arr = this.complaint.filter(x=>x.status=="User Dependency");
            console.log(this.filter_arr);
          }

          else if(status=='Cancel' || status=='Reject'){
            console.log(status);
            this.filter_arr = this.complaint.filter(x=>x.status=='Cancel' || x.status=='Reject');
            console.log(this.filter_arr);
          }

          else{
            console.log(status);
            this.filter_arr = this.complaint.filter(x=>x.status == status);
            console.log(this.filter_arr);
  
          }

          this.rqst_count = result['data']['count'];
          this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
          this.pagenumber = (this.start/this.pagelimit)+1;
          this.complaintcount = result['data']['complaint_count'];
          this.loader = '';
        },error=>{
          console.log(error);
          this.toast.openError('Something went wrong !!! Try Again...','');   
          this.loader = '';
        });
      }

    }
    
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth_service';
import { DatabaseService } from '../_services/database.service';

@Injectable()
export class AuthGuardLog implements CanActivate {
  
  bch_user:any = [];
  constructor(private router: Router, public db: DatabaseService,public auth: AuthService) {
    console.log('Auth Guard Log');
  }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    this.router.navigate(['login']);
    return false;
    
    // if (localStorage.getItem('access_token')) {
    //   if(state.url != '/')   {          
    //   }else{
    //     this.router.navigate(['/dealer-list']);
        
    //   }
    //   return false;
      
    // }else{
    //   return true;
    // }
    
    
    // if (localStorage.getItem('access_token')) {
    //   this.db.token = localStorage.getItem('access_token');
    //   this.auth.auth.token = this.db.token;
    //   return true;
    // }
    
    // this.router.navigate(['login']);
    // return false;
  }
  
  
  
}


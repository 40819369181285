import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-system-user-add',
  templateUrl: './system-user-add.component.html'
})
export class SystemUserAddComponent implements OnInit {
  
  addUserForm: FormGroup;
  submitted = false; //for submit button
  department:any=[];
  flag:any;
  isDisabled: boolean;
  complaint_category_list:any=[];
  cart_list_data:any=[];
  // ProductSearchForm: FormGroup;
  // isProductLoading = false;
  // filteredProduct: any=[];
  img=0;
  role_list:any=[];
  flag1:any;
  tokenInfo:any={};

  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) {
    this.GetDepartment();
    this.getcomplaint_category();
    this.GetRolelist();
    this.tokenInfo = this.db.tokenInfo;
  }
  
  ngOnInit() {
      this.addUserForm = this.formBuilder.group({
        name: ['', Validators.required],
        mobile_no: ['', Validators.required],
        email: ['',Validators.email],
        department: ['', Validators.required],
        role_id: ['', Validators.required],
        user_type: ['',Validators.required],
        user_code: ['',Validators.required],
        username:['',Validators.required],
      
      });

      console.log(this.tokenInfo);
      

      if(this.tokenInfo && this.tokenInfo.role_name!="Administrator")
      {
        this.addUserForm.controls['user_type'].setValue('MEMBER');
      }
      else
      {
        this.addUserForm.controls['user_type'].setValue('');
      }
  }
      
      
      get d() { return this.addUserForm.controls; }
      
      getcomplaint_category()
      {
        this.db.FetchData({},'master/complaint_category_list')
        .subscribe((resp:any)=>{
          console.log(resp);
          this.complaint_category_list = resp['data'];
        });
      }
      
      delete(index)
      {
        console.log("i am in delete function");
        this.cart_list_data.splice(index,1);
        console.log(this.cart_list_data);
      }
      
      MobileNumber2(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) {event.preventDefault();
        } 
      }
      
      validate(event:any)
      {
        const pattern = /^[0-9a-zA-Z]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
      }
      
      charonly(event: any){
        const pattern =/^[a-zA-Z ]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
      }
      
      isusernameLoading:any;
      UserCode(user_code: any)
      {
        this.isusernameLoading=1;
        console.log(user_code.value);
        this.db.FetchData({'user_code':user_code.value},'user/check_user_code_existence')
        .subscribe((result:any)=>{
          console.log(result);
          console.log(result['message']);
          console.log(this.isusernameLoading);

            
          if(result['message']=="Success" && result['data'] && result['data']['exist_data'] && result['data']['exist_data']['user_code'])
          {
            this.isusernameLoading=0;
            this.flag=true;
            this.addUserForm.controls['username'].setValue('');
          }

          else if(result['data'] && result['data']['emp_master_found_row'] )
          {
            let exist_row = result['data']['emp_master_found_row'];
            this.addUserForm.controls['name'].setValue(exist_row['name']);
            this.addUserForm.controls['mobile_no'].setValue(exist_row['mobile_no']);
            this.addUserForm.controls['department'].setValue(exist_row['dept_name']);
            this.addUserForm.controls['email'].setValue(exist_row['email']);
            this.addUserForm.controls['user_code'].setValue(exist_row['emp_code']);

            let temp_UserName=this.addUserForm.value.name.substring(0,3).concat(this.addUserForm.value.user_code);
            this.addUserForm.controls['username'].setValue(temp_UserName);
            this.isusernameLoading=0;


          }
          else{
            this.flag=false;
            this.isusernameLoading=0;
            let temp_UserName=this.addUserForm.value.name.substring(0,3).concat(this.addUserForm.value.user_code);
            this.addUserForm.controls['username'].setValue(temp_UserName);
          }
        },error=>{
          console.log(error);
        });
             
      }
      
      GetDepartment()
      {
        this.db.get_rqst('master/department_list')
        .subscribe((result:any)=>{
          console.log(result);
          this.department = result['data'];
          console.log(this.department);
          // if(this.tokenInfo && this.tokenInfo.role_name!="Administrator")
          // {
          //   this.addUserForm.controls['department'].setValue(this.tokenInfo.department);
          // }
          // else
          // {
          //   this.addUserForm.controls['department'].setValue('');
          // }

        },error=>{
          console.log(error);
        });
      }
      
      GetRolelist()
      {
        
        this.db.get_rqst('master/get_master_roles')
        .subscribe((result:any)=>{
          console.log(result);
          this.role_list = result['data'];
          console.log(this.role_list);
        },error=>{
          console.log(error);
        });
      }
      selectedFile:any=[];
      urls = new Array<string>();
      selectvalue(data)
      {
        this.img=1;
        console.log("i am in new function");
        console.log(data);
        //to display image 
        let files = data.target.files[0];
        console.log(files);
        let reader = new FileReader();
        reader.readAsDataURL(data.target.files[0]);
        reader.onload =(e: any)=>{
          this.urls=e.target.result;
        }  
        this.selectedFile.push(data.target.files[0]);
        console.log(this.selectedFile);
      }
      
      
      formData=new FormData(); // for file uploading  
      submitUser() 
      {
        console.log("i am in function");
        
        this.submitted = true; 
        // stop here if form is invalid
        if (this.addUserForm.invalid || this.flag==true) {
          console.log("i am in Invalid");
          return;
        }
        else
        { //if valid value display in console
          console.log("i am in else");
          console.log(this.addUserForm.value);
          this.isDisabled=true;
          this.db.FetchData({'data':this.addUserForm.value},'user/save_user')
          .subscribe((result)=>{
            console.log(result);
            if(result['message'] == "Success")
            {
              var insert_id = result['insert_id'];
              if(insert_id)
              {
                if(this.selectedFile && this.selectedFile.length)
                {
                  this.formData.append("image" ,this.selectedFile[0],this.selectedFile[0].name);
                  console.log(this.formData);
                  this.formData.append("id",insert_id);
                  this.db.FileData(this.formData,"user/upload_user_profile_image")
                  .subscribe((result:any) =>{
                    console.log(result);
                  },error=>{
                    console.log(error);
                  });
                }
              }
              this.toast.openSucess('User Created Successfully','');
              this.router.navigate(['/system-user-list']);
            }
          },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
          });
        }
      }
      // Get_UserName(username:any)
      // {
      //   console.log(username);
      //   console.log(username);
      //   this.db.FetchData({'username':username},'master/check_username_existence')
      //   .subscribe((result:any)=>{
      //     console.log(result);
      //     if(result['message']=="Success")
      //     {
      //       this.flag1=true;
      //     }
      //     else{
      //       this.flag1=false;
      //     }
          
      //   },error=>{
      //     console.log(error);
      //   }); 
      // }
    }
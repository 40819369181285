import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DialogComponent } from '../../DialogComponent.js';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Select2OptionData } from 'ng2-select2';



@Component({
  selector: 'app-add-product-level-dialog',
  templateUrl: './add-product-level-dialog.component.html',
  styleUrls: ['./add-product-level-dialog.component.scss']
})
export class AddProductLevelDialogComponent implements OnInit {
  role_comp_category_id:any;
  role_id:any;
  product_level_array:any=[];
  selected_product_level:any=[];
  public exampleData: Array<Select2OptionData>;
  public options: Select2Options;
  public value: string[];


  constructor(public db:DatabaseService,public toast: SnackBarOverview,public dialogbox:DialogComponent,private dialogRef:MatDialogRef<AddProductLevelDialogComponent>,@Inject(MAT_DIALOG_DATA)data) {
    
    this.role_id = data.role_id;
    this.role_comp_category_id = data.role_comp_category_id;
    this.product_level_array = data.product_level_array;

    console.log(this.role_id);
    console.log(this.role_comp_category_id);
    console.log(this.product_level_array);

  }
  ngOnInit() {

    // product levels
    this.value = []; 
    this.options = {
      multiple: true
    }
    
  }
    

  changed_product_level(event)
  {
    console.log(event);
    this.selected_product_level=[];
    for(var i=0;i<event.data.length;i++)
    {
      this.selected_product_level.push({'level_id': event.data[i]['id'],'level_name':event.data[i]['text']});
    }
    console.log(this.selected_product_level);
  }

  save()
  {
    
    if (!this.selected_product_level.length) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { 
      let temp_data = {'role_id':this.role_id,'role_comp_category_id':this.role_comp_category_id,'product_levels':this.selected_product_level};
      this.db.FetchData({'data':temp_data},'master/add_master_product_level')
      .subscribe((result:any)=>{
        console.log("first");
        console.log(result);
        this.dialogRef.close(result);
      },error=>{console.log(error);
      });
    }
  }
  
}

import { Component, OnInit,Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-add-master-dialog',
  templateUrl: './add-master-dialog.component.html',
  styleUrls: ['./add-master-dialog.component.scss']
})
export class AddMasterDialogComponent implements OnInit {
  addMasterForm: FormGroup;
  submitted = false;
  flag:any = false;
  flag1:any = false;
  master:any={};
  category_list: any=[];
  cat_code:any;
  complaint_type_list:any=[];
  product_levels:any=[];

  loader:any='';
  
  
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router, private dialogRef: MatDialogRef<AddMasterDialogComponent>) { 
    this.GetCategory();
    this.GetComplaint_types();
    this.Getproduct_levels();

    
  }
  
  ngOnInit() {
    this.addMasterForm = this.formBuilder.group({
      master_type: ['',Validators.required],
      region_name: [''],
      region_code: [''],
      department_name: [''],
      comp_category_name: [''],
      part_name: [''],
      cat_code: [''],
      category: [''],
      part_category_name:[''],
      issue_complaint_type:[''],
      issue_type:[''],
      level_wise_pro_id:['']

      
    });
  }
  
  get f() { return this.addMasterForm.controls; }
  
  formControlValueChanged() {
    // Region form field
    const region_name = this.addMasterForm.get('region_name');
    const region_code = this.addMasterForm.get('region_code');
    
    // department form field
    const department_name = this.addMasterForm.get('department_name');
    
    // part form field
    const part_name = this.addMasterForm.get('part_name');
    const cat_code = this.addMasterForm.get('cat_code');
    const category = this.addMasterForm.get('category');
    const part_category_name = this.addMasterForm.get('part_category_name');
    
    
    // Complaint Category form field
    const comp_category_name = this.addMasterForm.get('comp_category_name');
    
    // Complaint issue form  field
    const issue_complaint_type = this.addMasterForm.get('part_category_name');
    const issue_type = this.addMasterForm.get('part_category_name');
    const level_wise_pro_id = this.addMasterForm.get('level_wise_pro_id');
    
    
    
    
    this.addMasterForm.get('master_type').valueChanges.subscribe(
      (mode: string) => {
        console.log(mode); 
        if (mode === 'Region') {
          region_name.setValidators([Validators.required]);
          region_code.setValidators([Validators.required]);
          department_name.clearValidators();
          part_name.clearValidators();
          cat_code.clearValidators();
          category.clearValidators();
          part_category_name.clearValidators();
          comp_category_name.clearValidators();
          issue_complaint_type.clearValidators();
          issue_type.clearValidators();
          level_wise_pro_id.clearValidators();

          
        }
        
        else if(mode === 'Department')
        {
          department_name.setValidators([Validators.required]);
          region_name.clearValidators();
          region_code.clearValidators();
          part_name.clearValidators();
          cat_code.clearValidators();
          category.clearValidators();
          part_category_name.clearValidators();
          comp_category_name.clearValidators();
          issue_complaint_type.clearValidators();
          issue_type.clearValidators();
          level_wise_pro_id.clearValidators();

          
        }
        
        else if(mode === 'Spare parts')
        {
          part_name.setValidators([Validators.required]); 
          cat_code.setValidators([Validators.required]);
          category.setValidators([Validators.required]);
          if(part_category_name)
          part_category_name.setValidators([Validators.required]);
          else
          part_category_name.clearValidators();
          
          region_name.clearValidators();
          region_code.clearValidators();
          department_name.clearValidators();
          comp_category_name.clearValidators();
          issue_complaint_type.clearValidators();
          issue_type.clearValidators();
          level_wise_pro_id.clearValidators();

        }
        
        else if(mode === 'Complaint category')
        {
          comp_category_name.setValidators([Validators.required]);
          
          issue_complaint_type.clearValidators();
          issue_type.clearValidators();
          level_wise_pro_id.clearValidators();
          part_name.clearValidators();
          cat_code.clearValidators();
          category.clearValidators();
          part_category_name.setValidators([Validators.required]);
          region_name.clearValidators();
          region_code.clearValidators();
          department_name.clearValidators();
        }
        
        else if(mode === 'Issue')
        {
          issue_complaint_type.setValidators([Validators.required]);
          issue_type.setValidators([Validators.required]);
          level_wise_pro_id.setValidators([Validators.required]);

          
          part_name.clearValidators();
          category.clearValidators();
          cat_code.clearValidators();
          part_category_name.setValidators([Validators.required]);
          comp_category_name.clearValidators();
          region_name.clearValidators();
          region_code.clearValidators();
          department_name.clearValidators();
        }
        
        region_name.updateValueAndValidity();
        region_code.updateValueAndValidity();
        department_name.updateValueAndValidity();
        part_name.updateValueAndValidity();
        cat_code.updateValueAndValidity();
        category.updateValueAndValidity();
        part_category_name.updateValueAndValidity();
        comp_category_name.updateValueAndValidity();
        issue_complaint_type.updateValueAndValidity();
        issue_type.updateValueAndValidity();
        level_wise_pro_id.updateValueAndValidity();
        
      });
      
    }
    
    validate(event:any)
    {
      const pattern = /^[0-9]+$/;
      let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
      {event.preventDefault();}
    }
    
    charonly(event: any){
      const pattern =/^[a-zA-Z ]+$/;
      let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
      {event.preventDefault();}
    }
    
    IssueName(issue_name,comp_type)
    {
      console.log(issue_name);
      this.db.FetchData({'complaint_type':comp_type,'issue_name':issue_name,'level_wise_pro_id':this.addMasterForm.get('level_wise_pro_id').value},'master/check_issuename_existence')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag1=true;
        }
        else{
          this.flag1=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    RegionName(region_name: any)
    {
      console.log(region_name);
      this.db.FetchData({'region_name':region_name},'master/check_region_existence')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag1=true;
        }
        else{
          this.flag1=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    
    
    RegionCode(region_code:any){
      console.log(region_code);
      this.db.FetchData({'region_code':region_code},'master/check_region_existence')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag=true;
        }
        else{
          this.flag=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    DepartmentName(department_name:any){
      console.log(department_name);
      this.db.FetchData({'department_name':department_name},'master/check_department_existence')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag=true;
        }
        else{
          this.flag=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    ComplaintCategoryName(comp_category_name:any){
      console.log(comp_category_name);
      this.db.FetchData({'comp_category_name':comp_category_name},'master/check_comp_category_existence	')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag=true;
        }
        else{
          this.flag=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    GetComplaint_types()
    {
      this.db.FetchData({},'master/complaint_category_list')
      .subscribe((result:any)=>{
        console.log(result);
        this.complaint_type_list = result['data'];
        console.log(this.complaint_type_list);
      },error=>{
        console.log(error);
      });
    }
    
    Getproduct_levels()
    {
      this.db.FetchData({},'master/get_product_level')
      .subscribe((result:any)=>{
        console.log(result);
        this.product_levels = result['data'];
        console.log(this.product_levels);
      },error=>{
        console.log(error);
      });
    }
    
    
    GetCategory()
    {
      this.db.FetchData({},'master/get_part_category')
      .subscribe((result:any)=>{
        console.log(result);
        this.category_list = result['data'];
        console.log(this.category_list);
      },error=>{
        console.log(error);
      });
    }
    
    GetParts(category:any)
    {
      console.log(category);
      if(category=='Other')
      {
        this.addMasterForm.controls['cat_code'].setValue('');
      }
      else
      {
        let filterRow1 = this.category_list.filter(x => x.category == category)[0];
        console.log(filterRow1);
        if(filterRow1 && filterRow1.cat_code)
        {
          this.addMasterForm.controls['cat_code'].setValue(filterRow1.cat_code);
        }
        else{
          this.addMasterForm.controls['cat_code'].setValue('');
        }
        console.log(this.cat_code);
      }
      
    }
    
    PartName(part_name:any){
      console.log(part_name);
      this.db.FetchData({'part_name':part_name},'master/check_part_existence')
      .subscribe((result:any)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          this.flag=true;
        }
        else{
          this.flag=false;
        }
      },error=>{
        console.log(error);
      });
    }
    
    AddMaster(){
      this.loader='1';
      this.submitted = true; 
      console.log(this.addMasterForm.value);
      if (this.addMasterForm.invalid || this.flag1 || this.flag) {
        console.log("*****invalid data*****");
        this.loader='';
        
        return;
      }
      else
      { 
        console.log(this.addMasterForm.value);
        this.db.FetchData({'data':this.addMasterForm.value},'master/add_master')
        .subscribe((result)=>{
          console.log(result);
          if(result['message']=="Success")
          {
            console.log("Success!!@#");
            this.loader='';
            
            this.dialogRef.close();
          }
        },error=>{
          console.log("error");
          console.log(error);
          this.loader='';
          
        });
      }
    }
  }
import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../../DialogComponent.js';
import { MatDialog,MatDialogConfig } from '@angular/material';
import {TooltipPosition} from '@angular/material/tooltip';
import { DealerMainComplaintCancelModalComponent } from '../dealer-main-complaint-cancel-modal/dealer-main-complaint-cancel-modal.component';


import {FormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';

import {default as _rollupMoment} from 'moment';
import { ShortcutInput, ShortcutEventOutput } from "ng-keyboard-shortcuts";



const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};



@Component({
    selector: 'app-complaints-list',
    templateUrl: './complaints-list.component.html',
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})



export class ComplaintsListComponent implements OnInit {
    positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
    date = new FormControl(moment());
    @ViewChild('table') table: ElementRef;
    complaint:any=[];
    start:any = 0;
    pagelimit:any = 50;
    search:any = {};
    rqst_count:any = '';
    total_page:any = ''
    pagenumber:any = '';
    complaintcount: any=[];
    loader:any = '1';  
    tokenInfo:any={};
    length:any;
    disp_running:any=[];
    complaint_category_list:any=[];
    branchlist:any=[];
    sendRequest:any=true;
    all_complaint:any=[];
    status_name:any='';
    today_date;
    complaint_excel:any = [];
    public listing;
    
    constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent,public matdialog: MatDialog,public route:ActivatedRoute)
    {  
        this.listing = new Array(8);
        
        this.route.params.subscribe(params=>{
            console.log(params);
            if(params.dashboard=="true")
            {
                this.search.dashboard = params.dashboard;
                this.search.dashboard_from = params.dashboard_from;
                this.search.dashboard_product_level1 = params.product_level1;
                this.search.dashboard_search_status = params.status;
                this.search.dashboard_branch_code = params.branch_code;
                
                this.search.dashboard_complaint_type = params.complaint_type;
                this.search.dashboard_date = params.date;
            }
            else
            {
                this.search={};
            }
            console.log(this.search);
        });
        this.search = JSON.parse(localStorage.getItem("filter"));
        if(this.search==null)
        {
            this.search={};
        }
        console.log(this.search);
    }
    
    ngOnInit() {
        
        this.today_date = new Date().toISOString().slice(0,10);
        console.log(this.today_date)
        
        this.tokenInfo = this.db.tokenInfo;
        console.log(this.tokenInfo);
        this.getcomplaint_category();
        this.get_branch_list();
        this.get_complaint_status();
        this.get_status_list();
        
        if((this.tokenInfo.logged_in_type=='Branch_User' && this.tokenInfo.user_type=='BM' )|| this.tokenInfo.logged_in_type =='Customer_Care')
        {
            this.status_name = 'Open';
        }
        
        else if((this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.user_type=='HEAD' && this.tokenInfo.role_name!='Administrator'))
        {
            this.status_name = 'action-reqr';
        }
        
        else if(this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.user_type=='MEMBER')
        {
            this.status_name = 'action-reqr-mem';
        }
        else
        {
            this.status_name = 'All';
        }
        
        if(this.db.complaint_active_tab)
        {
            this.status_name = this.db.complaint_active_tab;
        }
        console.log(this.status_name);
        
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
        
    }
    
    validate(event:any)
    {
        const pattern = /^[0-9 ]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
        {event.preventDefault();}
    }
    
    get_branch_list()
    {
        this.db.FetchData({},'master/branch_list_forsearch').subscribe(resp=>{
            console.log(resp);
            this.branchlist=resp['data'];
            console.log(this.branchlist);
        });
    }
    
    onRightClick(enc_id)
    {
        window.open(this.db.siteUrl+'complaints-detail/'+enc_id+'/list',"_blank");
    }
    
    status_list:any=[]; 
    get_status_list()
    {
        this.db.FetchData({},'complaint/get_status_list')
        .subscribe(resp=>{
            console.log(resp);
            this.status_list = resp['data'];
        });
    }
    
    search_comp_status(status:any)
    { 
        console.log(status);
        this.search.source = status; 
        console.log(this.search.status);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_comp_category(cat:any)
    { 
        console.log(cat);
        this.search.complaint_category = cat; 
        console.log(this.search.complaint_category);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    search_dealer(dealer:any)
    { 
        console.log(dealer);
        this.search.dealer_name = dealer; 
        console.log(this.search.dealer);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_customer(customer:any)
    { 
        console.log(customer);
        this.search.customer = customer; 
        console.log(this.search.customer);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_branch(branch_name:any)
    { 
        console.log(branch_name);
        this.search.branch_name = branch_name; 
        console.log(this.search.branch_name);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_comp_number(comp_no:any)
    { 
        this.search.comp_no = comp_no; 
        console.log(this.search.comp_no);
        console.log(this.sendRequest);
        
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_list_status(list_status:any)
    { 
        this.search.list_status = list_status; 
        console.log(this.search.list_status);
        console.log(this.sendRequest);
        
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_created_by(created_by:any)
    {
        this.search.created_by = created_by; 
        console.log(this.search.created_by);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    search_by_date(date_to:any,date_from:any)
    {
        this.search.to=date_to;
        console.log(this.search.to);
        this.search.from=date_from;
        console.log(this.search.from);
        this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
    }
    
    getcomplaint_category()
    {
        this.db.FetchData({},'master/complaint_category_list')
        .subscribe((resp:any)=>{
            console.log(resp);
            this.complaint_category_list = resp['data'];
        });
    }
    
    
    OpenDialog(complaint_id,index,type) 
    {
        console.log(complaint_id);
        console.log(type);
        
        const dialogRef=this.matdialog.open(DealerMainComplaintCancelModalComponent,
            {
                width: '420px',
                data:
                {
                    complaint_id: complaint_id,
                    type
                }
            });
            dialogRef.afterClosed().subscribe(result => 
                {
                    console.log(result);
                    this.getComplaintList(this.pagelimit,this.start,'',this.status_name);
                });
            }
            
            getComplaintList(pagelimit:any=50,start:any=0,action:any='',status)
            {
                this.complaint_excel=[];
                this.loader = "1";
                this.pagelimit = pagelimit;
                console.log(this.pagelimit);
                
                this.start = start;
                console.log(this.start);
                if(action == "refresh")
                {
                    this.search = {};
                    this.start = 0;
                    this.db.complaint_active_tab='';
                }
                console.log(status);
                
                if(this.search)
                {
                    localStorage.setItem("filter",JSON.stringify(this.search));
                    // this.session.setItem
                }
                
                console.log(this.search);
                this.status_name = status;
                this.db.complaint_active_tab = status;
                console.log(this.status_name);
                this.search.status = status;
                console.log('****** SEARCH *****');
                console.log(this.search);
                
                this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'complaint/complaint_list')
                .subscribe((result:any)=>{
                    console.log(result);
                    this.sendRequest=false;
                    this.all_complaint=result['data']['list'];
                    
                    if((this.tokenInfo.logged_in_type=='Branch_User' && this.tokenInfo.user_type=='BM' )|| this.tokenInfo.logged_in_type =='Customer_Care')
                    {
                        if(status=='All')
                        {
                            this.complaint=this.all_complaint;
                            console.log(this.complaint);
                        }
                        
                        else if(status=='User Dependency' && status!='' )
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status=='User Dependency' && x.created_by==this.tokenInfo.id && (x.created_by_type=="Branch_User" || x.created_by_type=="Customer_Care"));        
                        }
                        
                        else if(status=='Open' && status!='' && this.tokenInfo.logged_in_type =='Customer_Care')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status==status || x.system_user_id==this.tokenInfo.id);        
                        }
                        
                        else if(status=='Open' && status!='' && this.tokenInfo.logged_in_type =='Branch_User')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status==status || x.bm_id==this.tokenInfo.id);        
                        }
                        
                        else if(status!='All' && status!='')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status==status);        
                        }
                        
                        else if(status=='' && this.tokenInfo.logged_in_type =='Customer_Care')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status!='Open' && x.status!='Close' && x.status!='Cancel' &&x.status!='Reject' && x.system_user_id!=this.tokenInfo.id);      
                        }
                        
                        else if(status=='' && this.tokenInfo.logged_in_type =='Branch_User')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.status!='Open' && x.status!='Close' && x.status!='Cancel' &&x.status!='Reject' && x.bm_id!=this.tokenInfo.id);      
                        }
                    }
                    
                    else if((this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.user_type=='HEAD' && this.tokenInfo.role_name!='Administrator'))
                    {
                        if(status=='All')
                        {
                            this.complaint=this.all_complaint;
                            
                        }
                        else if(status=='action-reqr')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.system_user_id==this.tokenInfo.id && x.status!='Close' && x.status!='Cancel' && x.status!='Reject' && x.status!='User Dependency' && x.assigned_by_id!=this.tokenInfo.id && x.system_user_member_id==0);    
                        }
                        
                        else if(status=='assigned')
                        {
                            this.complaint=this.all_complaint.filter(x=>x.assigned_by_id==this.tokenInfo.id);     
                        }
                    }
                    
                    else if(this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.user_type=='MEMBER')
                    {
                        if(status=='All')
                        {
                            this.complaint=this.all_complaint;
                            
                        }
                        else if(status=='action-reqr-mem')
                        {
                            this.complaint= this.all_complaint.filter(x=>x.system_user_member_id==this.tokenInfo.id && x.status!='Close');   
                        }
                    }
                    
                    else if(this.tokenInfo.role_name=='Administrator')
                    {
                        
                        this.complaint=this.all_complaint;
                    }
                    
                    this.rqst_count = result['data']['count'];
                    console.log(this.rqst_count);
                    console.log(this.pagelimit);
                    this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
                    console.log(this.total_page);
                    this.pagenumber = (this.start/this.pagelimit)+1;
                    this.complaintcount = result['data']['complaint_count'];
                    console.log(this.complaintcount);
                    this.loader = '';
                },error=>{
                    console.log(error);
                    this.toast.openError('Something went wrong !!! Try Again...','');   
                    this.loader = '';
                });
            }
            
            
            
            GetRunningList(){
                this.complaint= this.complaint.filter(x=>x.status=='Open');
            }
            GetPendingList(){
                this.complaint= this.complaint.filter(x=>x.status=='Pending');
            }
            
            delete_complaint(id:any,index:any)
            {
                console.log(id);    
                this.dialog.delete('Complaint !').then((result) => {
                    console.log(result);
                    if(result)
                    {
                        this.db.FetchData({},'complaint/delete_complaint/'+id).subscribe((response)=>{
                            console.log(response);
                            this.complaint.splice(index,1);
                            this.toast.openSucess('Complaint deleted successfully !!','');
                            
                        },err=>{
                            console.log(err);
                            this.toast.openError('Something went wrong !!! Try Again...','');
                        });
                    }
                });
            }
            comp_status_list:any=[];
            get_complaint_status()
            {
                this.db.get_rqst('master/get_complaint_status')
                .subscribe((result: any) => {
                    console.log(result);
                    this.comp_status_list=result['data'];
                }, error => {
                    console.log(error);
                    this.toast.openError('Something went wrong !!! Try again... ','');
                });
            }
            
            filterByStatus(status:any)
            {
                this.status_name = status;
                this.complaint=this.all_complaint.filter(x=>x.status==status)
            }
            filterfrInprogress()
            {
                this.complaint=this.all_complaint.filter(x=>x.status!='Open' && x.status!='Close' && x.status!='Cancel' &&x.status!='Reject')
                console.log(this.complaint);
                
            }
            fiterTab(type:any)
            {
                if(type=='action-reqr')
                {
                    this.complaint=this.all_complaint.filter(x=>x.system_user_id==this.tokenInfo.id && x.assigned_by_id==0 )
                }
                else if(type=='assigned')
                {
                    this.complaint=this.all_complaint.filter(x=>x.assigned_by_id==this.tokenInfo.id)
                }
                else if('action-reqr-mem')
                {
                    this.complaint= this.all_complaint.filter(x=>x.system_user_member_id==this.tokenInfo.id)
                }
            }
            
            // export excel start
            circle_loader:any=false;
            downloadFile(status:any)
            {
                this.circle_loader = true;
                console.log(this.start);
                console.log(status);
                
                console.log(this.search);
                this.status_name = status;
                console.log(this.status_name);
                this.search.status = status;
                console.log('****** SEARCH *****');
                console.log(this.search);
                
                this.db.FetchData({'search':this.search},'complaint/complaint_list_excel')
                .subscribe((result:any)=>{ 
                    console.log(result);
                    this.circle_loader = false;
                    this.db.exportAsExcelFile(result.data.list, 'COMPLAINT SHEET');
                });
            }
            
            allow:any = true;
            get_all_data()
            {
                if(this.allow)
                {
                    this.allow = false;
                    this.db.FetchData({},'master/update_comp')
                    .subscribe((result:any)=>{ 
                        console.log(result);
                    });
                }
            }
            
        }
        
import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';


@Component({
  selector: 'app-ticket-action',
  templateUrl: './ticket-action.component.html',
  styleUrls: ['./ticket-action.component.scss']
})
export class TicketActionComponent implements OnInit {
  ActionForm: FormGroup;
  submitted = false;
  detail: any ='';
  ticket_id: any;
  coming_from:any;
  complaint_id:any;
  complaint_type:any;
  ticket_status:any=[];
  status_remark_access:any;
  tokenInfo:any={};
  
  constructor(public toast: SnackBarOverview,private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<TicketActionComponent>,@Inject(MAT_DIALOG_DATA)data) { 
    this.ticket_id = data.ticket_id;
    this.coming_from = data.from;
    this.complaint_id = data.complaint_id;
    this.complaint_type = data.complaint_type;
    
    console.log(this.complaint_id); 
    console.log(this.ticket_id); 
    console.log(this.coming_from); 
    
    this.get_ticket_status();
    this.status_remark_access=0;
    this.tokenInfo = this.db.tokenInfo;
  }
  
  get_ticket_status()
  {
    this.db.get_rqst('master/get_ticket_status')
    .subscribe((result: any) => {
      console.log(result);
      this.ticket_status=result['data'];
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
    });
  }
  
  ngOnInit() {
    
    this.ActionForm = this.formBuilder.group({
      coming_from:[''],
      // remark: [''],
      // reason_reject: [''],
      description: [''],
      department: [''],
      user_id: [''],
      user_name: [''],
      status: [''],
      status_remark:[''],
      ticket_id: [ this.ticket_id,Validators.required ],
      complaint_id: [ this.complaint_id,Validators.required ],
      
    });
    
    console.log(this.coming_from);
    
    if(this.coming_from=='Close')
    {
      this.ActionForm.controls["remark"].setValidators([Validators.required]);
      this.ActionForm.controls['remark'].updateValueAndValidity();
      
      this.ActionForm.controls['reason_reject'].clearValidators();
      this.ActionForm.controls['reason_reject'].updateValueAndValidity();
      
      
      this.ActionForm.controls['description'].clearValidators();
      this.ActionForm.controls['description'].updateValueAndValidity();
      
    }
    else if(this.coming_from=='Reject')
    {
      this.ActionForm.controls["reason_reject"].setValidators([Validators.required]);
      this.ActionForm.controls['reason_reject'].updateValueAndValidity();
      
      this.ActionForm.controls['remark'].clearValidators();
      this.ActionForm.controls['remark'].updateValueAndValidity();
      
      
      this.ActionForm.controls['description'].clearValidators();
      this.ActionForm.controls['description'].updateValueAndValidity();
    }
    
    else if(this.coming_from=='Remark')
    {
      this.ActionForm.controls["description"].setValidators([Validators.required]);
      this.ActionForm.controls['description'].updateValueAndValidity();
      
      this.ActionForm.controls['department'].clearValidators();
      this.ActionForm.controls['department'].updateValueAndValidity();
      
      this.ActionForm.controls['user_id'].clearValidators();
      this.ActionForm.controls['user_id'].updateValueAndValidity();
      
      this.ActionForm.controls['user_name'].clearValidators();
      this.ActionForm.controls['user_name'].updateValueAndValidity();
      
      this.ActionForm.controls['status'].clearValidators();
      this.ActionForm.controls['status'].updateValueAndValidity();
      
      this.ActionForm.controls['status_remark'].clearValidators();
      this.ActionForm.controls['status_remark'].updateValueAndValidity();
    }
    
    
    else if(this.coming_from=='ChangeStatus' || this.coming_from=='Reopen')
    {
      
      if(this.coming_from=='Reopen')
      {
        this.ActionForm.controls['status'].setValue('ReOpen'); 
      }

      
      this.ActionForm.controls["status"].setValidators([Validators.required]);
      this.ActionForm.controls['status'].updateValueAndValidity();
      
      
      
      this.ActionForm.controls["status_remark"].setValidators([Validators.required]);
      this.ActionForm.controls['status_remark'].updateValueAndValidity();
      
      this.ActionForm.controls['description'].clearValidators();
      this.ActionForm.controls['description'].updateValueAndValidity();
      
      this.ActionForm.controls['department'].clearValidators();
      this.ActionForm.controls['department'].updateValueAndValidity();
      
      this.ActionForm.controls['user_id'].clearValidators();
      this.ActionForm.controls['user_id'].updateValueAndValidity();
      
      this.ActionForm.controls['user_name'].clearValidators();
      this.ActionForm.controls['user_name'].updateValueAndValidity();
    }
    
    else if(this.coming_from=='Forward' || this.coming_from=='Assign')
    {
      this.GetDepartment();
      
      this.ActionForm.controls["department"].setValidators([Validators.required]);
      this.ActionForm.controls['department'].updateValueAndValidity();
      
      this.ActionForm.controls["user_id"].setValidators([Validators.required]);
      this.ActionForm.controls['user_id'].updateValueAndValidity();
      
      this.ActionForm.controls["user_name"].setValidators([Validators.required]);
      this.ActionForm.controls['user_name'].updateValueAndValidity();
      
      this.ActionForm.controls['status_remark'].clearValidators();
      this.ActionForm.controls['status_remark'].updateValueAndValidity();
      
      this.ActionForm.controls['status'].clearValidators();
      this.ActionForm.controls['status'].updateValueAndValidity();
      
      this.ActionForm.controls['description'].clearValidators();
      this.ActionForm.controls['description'].updateValueAndValidity();
    }
  }
  
  get f() { return this.ActionForm.controls; }
  
  department_list:any=[];
  GetDepartment()
  {
    this.db.FetchData({'complaint_type':this.complaint_type},'complaint/department_list')
    .subscribe((result:any)=>{
      console.log(result);
      
      if(this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.department=='INFORMATION TECHNOLOGY')
      {
        this.department_list = result['data'];
        
      }
      else if(this.tokenInfo.logged_in_type=='System_User' && this.tokenInfo.department!='INFORMATION TECHNOLOGY')
      {
        this.ActionForm.controls['department'].setValue(this.tokenInfo.department); 
        this.department_list=[{'department_name':this.tokenInfo.department}];
        this.getUser(this.tokenInfo.department);
      }
      else
      {
        this.department_list = result['data'];
      }
      
      console.log(this.department_list);
    },error=>{
      console.log(error);
    });
  }
  
  user_list:any=[];
  getUser(department_name:any)
  {
    console.log(department_name);
    this.db.FetchData({'department_name':department_name,'coming_from':this.coming_from},'complaint/get_complaint_user_head')
    .subscribe((result:any) =>{
      console.log(result);
      this.user_list = result['data'];
      if(this.user_list && this.user_list.length==1)
      {
        this.ActionForm.controls['user_id'].setValue(this.user_list[0].id); 
        this.ActionForm.controls['user_name'].setValue(this.user_list[0].name); 
      }
      else
      {
        this.ActionForm.controls['user_id'].setValue(0); 
        this.ActionForm.controls['user_name'].setValue(''); 
      }
      console.log(this.ActionForm.controls.value);
      console.log(this.user_list);
    },error=>{
      console.log(error);
    });
  }
  
  getUserName(id)
  {
    console.log(id);
    let filterRow = this.user_list.filter(x => x.id == id)[0];
    console.log(filterRow);
    console.log(filterRow['name']);
    this.ActionForm.controls['user_name'].setValue(filterRow['name']); 
  } 
  
  getremark_access(index)
  {
    console.log(index);
    console.log(this.ticket_status);
    
    let accessdata = this.ticket_status[index];
    this.status_remark_access = accessdata.remark_access;
    console.log(this.status_remark_access);

    if(this.status_remark_access)
    {
      this.ActionForm.controls["status_remark"].setValidators([Validators.required]);
      this.ActionForm.controls['status_remark'].updateValueAndValidity();
      
    }
    else
    {
      this.ActionForm.controls['status_remark'].clearValidators();
      this.ActionForm.controls['status_remark'].updateValueAndValidity();
    }
  }
  
  selectedFile:any=[];
  select_file(event)
  {
    let total_files_size=0;
    console.log(event.target.files.length);
    
    for(var i=0; i<event.target.files.length; i++)
    {
      const file = event.target.files[i];
      console.log('size', file.size);
      console.log('type', file.type);
      let extension  = file.type.split('/').pop(); 
      console.log(extension);
      console.log(typeof(file.size));
      
      total_files_size = total_files_size + parseInt(file.size);
      if(parseInt(file.size)<=5242880)
      {
        if(extension=='png' || extension=='jpeg' || extension=='jpg' || extension=='gif' || extension=='tif' || extension=='pdf')
        {
          this.selectedFile.push(event.target.files[i]);
        }

        else if(extension!='png' && extension!='jpeg' && extension!='jpg' && extension!='gif' && extension!='tif' && extension!='pdf')
        {
          console.log("not pdf");
          
          if(extension=='vnd.openxmlformats-officedocument.wordprocessingml.document' || extension=='vnd.ms-powerpoint')
          {
            console.log("allow file");
            this.selectedFile.push(event.target.files[i]);
          }
          else
          {
            console.log("error");

            this.toast.openError('The file type not allowed!!! Try Again...','');
          }
          
        }
        else
        {
          this.toast.openError('The file type not allowed!!! Try Again...','');
        }
      }

      else
      {
        this.toast.openError('The file size not allowed more than 5mb !!! Try Again...','');
      }
    }

    console.log(total_files_size);
    
    if(total_files_size>5242880)
    {
      this.toast.openError('The files size not allowed more than 5mb !!! Try Again...','');
      this.selectedFile=[];
    }
    
    console.log(this.selectedFile);
  }
  
  
  delFile(name:any,index:any){
    console.log(name); 
    console.log(index);
    this.selectedFile.splice(index,1);
    console.log(this.selectedFile);
  }
  
  formData=new FormData(); // for file uploading  
  Create() 
  {
    console.log(this.ActionForm.value);
    
    this.submitted = true; 
    if (this.ActionForm.invalid) {
      return;
    }
    else
    { 
      console.log(this.ActionForm.value);
      this.ActionForm.controls['coming_from'].setValue(this.coming_from);
      console.log(this.ActionForm.value);      
      
      if(this.coming_from=='Reopen')
      {
        this.db.FetchData({'data':this.ActionForm.value},'complaint/ticket_reopen/'+this.ticket_id)
        .subscribe((response)=>{
          console.log(response);
          this.dialogRef.close(response);
          
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
      
      else
      {
        
        // file content
        for(var i=0; i<this.selectedFile.length; i++)
        {
          this.formData.append("image"+i ,this.selectedFile[i],this.selectedFile[i].name);
        }
        
        console.log(this.formData);
        //  end
        
        this.db.FetchData({'data':this.ActionForm.value},'complaint/ticket_action')
        .subscribe((result)=>{
          console.log(result);
          console.log("Success!!@#");
          
          if(result['message']=="Success")
          {
            var insert_id = result['data']['insert_id'];
            if(insert_id && this.selectedFile && this.selectedFile.length)
            {
              this.formData.append("insert_id",insert_id);
              this.formData.append("ticket_id",this.ticket_id);
              
              this.formData.append("created_by",this.tokenInfo.id);
              this.formData.append("created_by_type",this.tokenInfo.logged_in_type);
              
              this.db.FileData(this.formData,"complaint/ticket_remark_file_upload")
              .subscribe((fileresult:any) =>{
                console.log(fileresult);
                // this.remark_data = result['data'];
                // console.log(this.remark_data);
              },error=>{
                console.log(error);
              });
            }
          }
          
          this.dialogRef.close(result);
        },error=>{
          console.log("error");
          console.log(error);
        });
      }
      
    }
  }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { MatDialog ,MatDialogConfig} from '@angular/material';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-branch-installed-part-logs-list',
  templateUrl: './branch-installed-part-logs-list.component.html'
})
export class BranchInstalledPartLogsListComponent implements OnInit {
  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  
  LogData:any=[];
  data:any=[];
  branch_name:any='';
  branch_id:any='';
  
  tokenInfo:any={};
  
  // *ngIf="tokenInfo.logged_in_type!='Branch_User'"
  constructor(public db:DatabaseService,public router:Router,public dialog: MatDialog,public toast: SnackBarOverview,public dialogbox:DialogComponent) {
    
    this.branch_name = this.db.branch_name;
    this.tokenInfo = this.db.tokenInfo;
    console.log("ID Token info: ");
    console.log(this.tokenInfo.id);
    
    if(this.tokenInfo.logged_in_type=='Branch_User')
    {
      console.log("Logged in TYPE: ");
      console.log(this.tokenInfo.logged_in_type);
      console.log(this.tokenInfo.loggedin_user_branch);
      this.branch_id=this.tokenInfo.loggedin_user_branch;
      this.db.branch_id= this.branch_id;
      console.log("branch id of IF");
      console.log(this.branch_id);
    }
    else
    {
      
      this.branch_id=this.db.branch_id;
      console.log("branch id of ELSE");
      console.log(this.branch_id);
    }
    
    console.log(this.branch_id);
  }
  
  ngOnInit() {
    this.getStockList(this.branch_id);
  }
  
  search_source(source:any)
  { 
    console.log(status);
    this.search.source = source; 
    console.log(this.search.source);
    this.getStockList(this.branch_id);
  }
  
  getStockList(branch_id , pagelimit:any=100,start:any=0,action:any='')
  {
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    console.log(branch_id);
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'branch/get_installed_parts_logs/' +branch_id).subscribe((result)=>{
      console.log(result);  
      this.LogData=result['data']['installed_parts'];
      this.rqst_count = result['data']['count'];
      console.log(this.rqst_count);
      console.log(this.pagelimit);
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      console.log(this.total_page);
      this.pagenumber = (this.start/this.pagelimit)+1;
      
      console.log("success");
      console.log(this.LogData);
      this.loader = '';
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');      
      this.loader = '';
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';

@Component({
    selector: 'app-import-issue-type',
    templateUrl: './import-issue-type.component.html',
    styleUrls: ['./import-issue-type.component.scss']
})
export class ImportIssueTypeComponent implements OnInit {
    
    constructor(public db:DatabaseService) { }
    
    uploadurl:any="";
    DbUrl:any="";
    ngOnInit() {
        this.uploadurl = this.db.UploadUrl;
        this.DbUrl = this.db.DbUrl;
    }
    
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html'
})
export class AddCustomerComponent implements OnInit {
  addCustomerForm: FormGroup;
  submitted = false; //for login button
  detail: any = [];
  state_list: any=[];
  dealer_list:any[];
  district_list:any=[];
  city_list:any=[];
  flag=0;
  isDisabled: boolean;
  tokenInfo:any={};
  dealer_code: any;
  
  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) {
    this.GetStates();
    this.GetDealer();
  }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    
    this.addCustomerForm = this.formBuilder.group({
      cust_name: ['', Validators.required],
      customer_code:[''],
      contact: ['', Validators.required],
      email:[''],
      state_name: ['', Validators.required],
      district_name: ['', Validators.required],
      city: [''],
      pincode: [''],
      shipping_street:[''],
      billing_street:['',Validators.required],
      dealer_code:['',Validators.required]
    });
    
    if(this.tokenInfo.logged_in_type=='Dealer')
    {
      console.log("logged in dealer code");
      console.log(this.tokenInfo.logged_in_code);
      this.addCustomerForm.controls['dealer_code'].setValue(this.tokenInfo.logged_in_code);
      this.dealer_code = this.tokenInfo.logged_in_code;
    }
  }
  get e() { return this.addCustomerForm.controls; }
  
  MobileNumber2(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) {event.preventDefault();
    } }
    
    validate(event:any)
    {
      const pattern = /^[0-9a-zA-Z]+$/;
      let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
      {event.preventDefault();}
    }
    
    charonly(event: any){
      const pattern =/^[a-zA-Z ]+$/;
      let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
      {event.preventDefault();}
    }
    
    getDealerCode(dealer_code){
      console.log(dealer_code.value);
      this.dealer_code=dealer_code.value;
      console.log(this.dealer_code);
    }

    getcust_no(mobile)
    {
      console.log(mobile.value);
      
      this.db.FetchData({'mobile':mobile.value,'dealer_code':this.dealer_code},'dealer/check_customer_mobile_existence')
      .subscribe((result:any)=>{
        console.log("first");
        console.log(result);
        if(result['message']=="Success")
        {
          console.log('Customer already exists!');
          this.flag=1;
        }
        else
        {
          console.log("do not Exists!");
          this.flag=0;
        }
      },error=>{console.log(error);
      }
      );
    }
    
    GetDealer()
    {
      this.db.FetchData({},'dealer/dealer_list')
      .subscribe((result:any)=>{
        console.log(result);
        this.dealer_list = result['data']['list'];
        console.log(this.dealer_list);
      },error=>{
        console.log(error);
      });
    }
    
    GetStates()
    {
      this.db.FetchData({},'branch/get_states')
      .subscribe((result:any)=>{
        console.log(result);
        this.state_list = result['data'];
        console.log(this.state_list);
      },error=>{
        console.log(error);
      });
    }
    
    GetDistrict(state_name:any)
    {
      console.log(state_name.value);
      
      this.db.FetchData({'state_name':state_name.value},'branch/get_state_district')
      .subscribe(result =>{
        console.log(result);
        this.district_list = result['data'];
      },error=>{
        console.log(error);
        this.toast.openError('Something went wrong !!! Try Again...','');
      });
    }
    
   
    submitCustomer()
    {
      this.submitted = true;
      console.log(this.flag);
  
      
      if (this.addCustomerForm.invalid)
      {
        console.log("**invalid data***");
        return;
      }
      else
      { 
        this.isDisabled=true;
        console.log(this.addCustomerForm.value);
        this.db.FetchData({'data':this.addCustomerForm.value},'dealer/save_customer')
        .subscribe((result)=>{
          console.log(result);
          if(result['message']=="Success")
          {
            console.log("Success!");
            this.toast.openSucess('Customer Submitted Successfully','');
            if(this.tokenInfo.logged_in_type=='Dealer')
            {
              this.router.navigate(['/dealer/customer-list']);
            }
            else
            {
              this.router.navigate(['/dealer-list']);
              
            }
          }
        },error=>{
          console.log("error");
          console.log(error);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    }
  }
  
  
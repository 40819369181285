import { Component, OnInit,ElementRef, HostListener, Input  } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import {Location} from '@angular/common';


@Component({
  selector: 'app-dealer-detail', 
  templateUrl: './dealer-detail.component.html'
})
export class DealerDetailComponent implements OnInit {
  public text: String;
  addDealerForm: FormGroup;
  ecrpt_id:any='';
  active: any = {}; 
  detail_data:any={};
  branch_detail:any={};
  loader:any;
  hide = true;
  logs: any=[];
  tokenInfo: any={};
  state_list: any=[];
  district_list: any=[];
  constructor(private formBuilder: FormBuilder,public db:DatabaseService, public router:Router,public toast:SnackBarOverview, public route:ActivatedRoute,private eRef: ElementRef, private _location: Location) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
    this.GetStates();

  }
  
  @HostListener('click') onClick() {
  }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    console.log(this.tokenInfo);
    this.addDealerForm = this.formBuilder.group({
      name: [''],
      mobile: [''],
      email:[''],
      username:[''],
      password:[''],
      dealer_code:[''],
      landline:[''],
      street:[''],
      state:[''],
      city:[''],
      pincode:[''],
      country:[''],
      district:[''],
    });
    this.getDealerDetail();
  }
  get e() { return this.addDealerForm.controls; }
  
  backClicked() {
    console.log( 'goBack()...' );
    this._location.back();
  }
  
  edit_street()  {   this.active = {}; this.active.street="1" ; }
  edit_landline(){  this.active = {}; this.active.landline="1"; }
  edit_mobile() {  this.active = {};  this.active.mobile="1"; }
  edit_email() { this.active = {};  this.active.email="1";  }
  edit_username()  { this.active = {};this.active.username="1" ; }
  edit_password() { this.active = {};  this.active.password="1" ;}
  edit_state()   {   this.active = {}; this.active.state="1";}
  edit_district() {  this.active = {}; this.active.district="1";}
  edit_fax_no(){  this.active = {}; this.active.fax_no="1"; }
  edit_city(){  this.active = {}; this.active.city="1";}
  edit_pincode(){  this.active = {};  this.active.pincode="1";}
  
  
  // close(){
  //   console.log("i am in close");
  //   this.active.street="0" ; this.active.landline="0"; this.active.mobile="0"; this.active.email="0"; this.active.username="0"; this.active.password="0" ;
  // }
  
  getDealerDetail()
  {
    this.loader = '1';
    console.log("testing Phase");
    this.db.FetchData({'dealer_id':this.ecrpt_id},'dealer/dealer_detail')
    .subscribe((result)=>{
      console.log(result);
      this.detail_data=result['data']['detail'];
      this.branch_detail=result['data']['branch'];
      this.logs = result['data']['logs'];
      console.log(this.detail_data);
      console.log(this.branch_detail);
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';
    });
  }
  
  updateDealerDetail()
  {
    if (this.addDealerForm.invalid)
    {
      return;
    }
    else
    { 
      this.loader = '1';
      console.log(this.detail_data);
      this.db.FetchData({'data': this.detail_data},'dealer/update_dealer')
      .subscribe((result)=>{
        console.log(result);
        if (result['message'] == "Success") {
          console.log("Updated!");
          this.toast.openSucess('Updated! ','');
          this.getDealerDetail();
        }
        else {
          console.log("X something went wrong! ");
          this.toast.openError('Something went wrong !!! Try again... ','');
        }
      }, error => {
        console.log(error);
      }); 
      this.active = {};
    }
  }
  
  GetStates()
  {   
    this.db.FetchData({},'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
        this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }

  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  Landline(event: any) {
    const pattern = /[0-9-\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  //function for name: it should not contain any number
  charonly(event: any){
    const pattern =/^[a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }

}
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { MatDialog ,MatDialogConfig} from '@angular/material';
import { StockAssignModalComponent } from '../stock-assign-modal/stock-assign-modal.component';
import { AddStockModalComponent } from '../add-stock-modal/add-stock-modal.component';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';
import { BranchStockLogsModalComponent } from '../branch-stock-logs-modal/branch-stock-logs-modal.component';
import { BranchUsedQuantityModalComponent } from '../branch-used-quantity-modal/branch-used-quantity-modal.component';

@Component({
  selector: 'app-branch-sparepart-list',
  templateUrl: './branch-sparepart-list.component.html'
})
export class BranchSparepartListComponent implements OnInit {
  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';

  spareData:any=[];
  data:any=[];
  branch_name:any='';
  branch_id:any='';

  tokenInfo:any={};
  
  // *ngIf="tokenInfo.logged_in_type!='Branch_User'"
  constructor(public db:DatabaseService,public router:Router,public dialog: MatDialog,public toast: SnackBarOverview,public dialogbox:DialogComponent) {
   
    this.branch_name = this.db.branch_name;
    this.tokenInfo = this.db.tokenInfo;
    console.log("ID Token info: ");
    console.log(this.tokenInfo.id);

    if(this.tokenInfo.logged_in_type=='Branch_User')
    {
      console.log("Logged in TYPE: ");
      console.log(this.tokenInfo.logged_in_type);
      console.log(this.tokenInfo.loggedin_user_branch);
      this.branch_id=this.tokenInfo.loggedin_user_branch;
      this.db.branch_id= this.branch_id;
      console.log("branch id of IF");
      console.log(this.branch_id);
    }
    else
    {

      this.branch_id=this.db.branch_id;
      console.log("branch id of ELSE");
      console.log(this.branch_id);
    }

    console.log(this.branch_id);
   }

  ngOnInit() {
    this.getStockList(this.branch_id);
  }

 
  branchStockLogs(part_id,branch_id,cat_code) 
  {
    console.log(part_id);
    console.log(branch_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {part_id:part_id,branch_id:branch_id,cat_code:cat_code};

    const dialogRef = this.dialog.open(BranchStockLogsModalComponent,dialogConfig);
    console.log("Dialog closed");
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      // this.getStockList(this.branch_id);
    });
  }

  UsedQty(part_id,branch_id,cat_code){
    console.log(" i am in used qty");
    console.log(part_id);
    console.log(branch_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {part_id:part_id,branch_id:branch_id,cat_code:cat_code};
    dialogConfig.width='768px';

    const dialogRef = this.dialog.open(BranchUsedQuantityModalComponent,dialogConfig);

    console.log("Dialog closed");
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      // this.getStockList(this.branch_id);
    });
  }

  openAddStockDialog() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { branch_id: this.branch_id};
    dialogConfig.width="900px";
    const dialogRef = this.dialog.open(AddStockModalComponent, dialogConfig);

    console.log("Dialog closed");
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result=="Success")
      {
        this.toast.openSucess('Stock Added Successfully','');
        this.getStockList(this.branch_id);
      }
      else if(result && result!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');

      }
    });
  }


  openStockAssignDialog() 
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      branch_id: this.branch_id,
    };
    const dialogRef = this.dialog.open(StockAssignModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result && result=="Success")
      {
        this.toast.openSucess('Stock Assigned Successfully','');
        this.getStockList(this.branch_id);
      }
      else if(result && result!="Success")
      {
        this.toast.openError('Something went wrong !!! Try again... ','');
      }
    });
  }

  getStockList(branch_id , pagelimit:any=20,start:any=0,action:any='')
  {
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    
    if(action == "refresh")
    {
        this.search = {};
        this.start = 0;
    }
    console.log(branch_id);
    this.db.get_rqst('branch/get_branch_spare_parts/' +branch_id).subscribe((result)=>{
    console.log(result);  
    this.spareData=result['data'];
    console.log("success");
    console.log(this.spareData);
    this.loader = '';
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');      
      this.loader = '';
    });
  }

  deleteSparePart(id,index:any)
  {
    console.log("delete function activate ");
    this.dialogbox.delete('Part !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({id:id,branch_id:this.branch_id},'branch/delete_branch_assigned_part')
        .subscribe(result=>{
          console.log(result);
          if(result['message']=="Success")
          {
            this.toast.openSucess('Part Deleted Successfully...','');
            this.spareData.splice(index,1);
          }
        },error=>{
          console.log(error);
          this.toast.openError('Something went wrong !!! Try Again...','');      
        });
      }
    });

   
  }
}
import { Component, OnInit ,Inject} from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-add-stock-modal',
  templateUrl: './add-stock-modal.component.html',
  styleUrls: ['./add-stock-modal.component.scss']
})
export class AddStockModalComponent implements OnInit {
  assignForm: FormGroup;
  submitted = false;
  category_list: any=[];
  part_list:any=[];
  toast: any;
  filterRow:any;
  detail: any;
  cat_code: any;
  branch_id:any;
  loader: any='';

  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<AddStockModalComponent>,@Inject(MAT_DIALOG_DATA)data) {
    this.branch_id = data.branch_id;
    this.GetCategory();
   }

  
  ngOnInit() {
    this.assignForm = this.formBuilder.group({
      category: ['', Validators.required],
      part_id: ['', Validators.required],
      part_name: ['', Validators.required],
      qty: ['', Validators.required],
      cat_code:['',Validators.required],
      so_no:['',Validators.required],
      minimum_alert: [''],
      current_stock: ['']
    });
  }

  get f() { return this.assignForm.controls; }

  quantity(event: any) 
  {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) 
    {event.preventDefault();}
  }

  GetCategory()
  {
    this.db.FetchData({},'master/get_part_category')
    .subscribe((result:any)=>{
      console.log(result);
      this.category_list = result['data'];
      console.log(this.category_list);
    },error=>{
      console.log(error);
    });
  }

  catcode_list:any=[];
  GetCatCode(category:any)
  {
    console.log(category.value);
      this.db.FetchData({'category_name':category.value},'master/get_spare_parts')
      .subscribe(result =>{
      console.log(result);
      if(result['message']=='Success')
      {
        this.catcode_list = result['data'];
      }
      else
      {
        this.catcode_list =[];
      }
    },error=>{
      console.log(error);
    });
  }
  
  getOtherInfo(cat_code: any){
    console.log(cat_code.value);
    let filterRow = this.catcode_list.filter(x => x.cat_code == cat_code.value)[0];
    console.log(filterRow);
    this.assignForm.controls['part_name'].setValue(filterRow.part_name);
    this.assignForm.controls['part_id'].setValue(filterRow.id);


    this.db.FetchData({'part_id':filterRow.id,'branch_id':this.branch_id},'branch/get_branch_existing_part_info')
    .subscribe(result =>{
      console.log(result);
      if(result['message']=='Success')
      {
        this.assignForm.controls['current_stock'].setValue(result['data']['qty']);
        this.assignForm.controls['minimum_alert'].setValue(result['data']['min_alert']);
      }
      else
      {
        this.assignForm.controls['current_stock'].setValue(0);
        this.assignForm.controls['minimum_alert'].setValue(0);
      }
    },error=>{
      console.log(error);
    });
  }


  Assign() 
  {
    this.loader='1';
    this.submitted = true; 
    if (this.assignForm.invalid) {
      console.log("*****invalid data*****");
      this.loader='';
      return;
    }
    else
    { 
      console.log(this.db.branch_id);
      console.log(this.assignForm.value);
       this.db.FetchData({'data':this.assignForm.value},'branch/save_parts/' +this.db.branch_id)
       .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!!@#");
        this.dialogRef.close(result['message']);
         this.loader='';
        }

      },error=>{
        console.log("error");
        console.log(error);
        this.loader='';

       });
    }
  }
}
import { Component, OnInit,Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'app-branch-stock-logs-modal',
  templateUrl: './branch-stock-logs-modal.component.html',
  styleUrls: ['./branch-stock-logs-modal.component.scss']
})
export class BranchStockLogsModalComponent implements OnInit {
  loader:any = '';  
  stock_logs:any=[];
  assigned_logs:any=[];
  branch_id:any='';
  part_id:any='';
  part_name: any;

  constructor(public db:DatabaseService,public toast: SnackBarOverview,private dialogRef: MatDialogRef<BranchStockLogsModalComponent>,@Inject(MAT_DIALOG_DATA) data) {

    this.branch_id = data.branch_id;
    this.part_id = data.part_id;
    this.part_name = data.cat_code;


    console.log(this.branch_id);
    console.log(this.part_id);

   }

  ngOnInit() {
    this.getStockLogs(this.branch_id,this.part_id);
  }

  getStockLogs(branch_id,part_id)
  {
    this.loader = "1";
    console.log(branch_id);
    this.db.FetchData({'branch_id':branch_id , 'part_id':part_id },'branch/get_branch_part_logs')
    .subscribe((result:any)=>{
      console.log(result);
    this.loader = '';
    this.stock_logs = result['data']['stock'];
    this.assigned_logs = result['data']['assign_stock'];

    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');      
      this.loader = '';
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-customer-tabs',
  templateUrl: './customer-tabs.component.html'
})
export class CustomerTabsComponent implements OnInit {
  encrypt_id:any='';
  cust_detail: any;
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
   }

  ngOnInit() {
    if(!this.encrypt_id)
    {
      this.encrypt_id = this.db.customer_id;
    }
    this.getCustomerDetail();
  }
  
  getCustomerDetail()
  {
    console.log("testing Phase");
    console.log(this.encrypt_id);
    this.db.FetchData({'customer_id':this.encrypt_id},'dealer/customer_detail')
    .subscribe((result)=>{
      console.log(result);
        this.cust_detail=result['data']['detail'];
        this.db.customer_id= this.encrypt_id;
        this.db.customer_name = result['data']['detail']['name'];
      }, error => {
        console.log(error);
        this.toast.openError('Something went wrong !!! Try again... ','');
      });
  }


}

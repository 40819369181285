import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';


@Component({
  selector: 'app-branch-used-quantity-modal',
  templateUrl: './branch-used-quantity-modal.component.html',
  styleUrls: ['./branch-used-quantity-modal.component.scss']
})
export class BranchUsedQuantityModalComponent implements OnInit {
  loader:any = '';  
  branch_id:any='';
  part_id:any='';
  cat_code: any;
  cnsume_qty_data:any=[];
  constructor(public db:DatabaseService,private dialogRef: MatDialogRef<BranchUsedQuantityModalComponent>,@Inject(MAT_DIALOG_DATA) data) { 
    this.branch_id = data.branch_id;
    this.part_id = data.part_id;
    this.cat_code = data.cat_code;
    console.log(this.branch_id);
    console.log(this.part_id);
    console.log(this.cat_code);
    
    
  }

  ngOnInit() {
    this.get_consumed_item_qty_detail();
  }

  get_consumed_item_qty_detail()
  {
    let data={'part_id':this.part_id,'branch_id':this.branch_id,'cat_code':this.cat_code};
    console.log("delete function activate ");
    this.db.FetchData({data:data},'branch/get_consumed_item_qty_detail')
    .subscribe(result=>{
      console.log(result);
      this.cnsume_qty_data= result['data'];
      
    },error=>{
      console.log(error);
    });
      
    

   
  }

}

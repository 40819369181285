import {Component,OnInit} from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder} from '@angular/forms';
import { DatabaseService } from '../_services/database.service';
import { AuthService } from '../_services/auth_service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  
  loginForm: FormGroup;
  public error: string;
  submitted = false; //for login button
  loader:any='';
  
  hide = true;
  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public auth: AuthService,private router: Router) { }
  
  ngOnInit() {
    
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    console.log(this.auth.wrongValues);
    
  }
  get f() { return this.loginForm.controls; }
  public login() 
  {
    this.submitted = true; 
    this.loader=1;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loader='';
      return;
    }
    else
    {
      this.auth.login(this.loginForm.value.username, this.loginForm.value.password)
      .pipe(first())
      .subscribe((result) => {
          console.log(result);
          if(result==true)
          {
            setTimeout(() => {
              this.loader='';
              this.auth.wrongValues=0;
              console.log(this.db.tokenInfo);
              if(this.db.tokenInfo.role_name=='Administrator')
              {
                this.router.navigate(['dashboard']);
              }
              else if(this.db.tokenInfo.user_type=='HEAD' && this.db.tokenInfo.role_name!='Administrator')
              {
                this.router.navigate(['dashboard']);
              }   
              else if(this.db.tokenInfo.user_type=='MEMBER' && this.db.tokenInfo.role_name!='Administrator')
              {
                this.router.navigate(['complaints-list']);
              } 
              else if(this.db.tokenInfo.logged_in_type=='Branch_User')
              {
                this.router.navigate(['dashboard']);
              } 
              else if(this.db.tokenInfo.logged_in_type=='Dealer')
              {
                this.router.navigate(['dealer-main-complaint-list']);
              }  
              else if(this.db.tokenInfo.logged_in_type=='Customer_Care')
              {
                this.router.navigate(['dashboard']);
              }   
            }, 500);
          }
          else{
            setTimeout(() => {
              console.log('aaaaaaa');
              this.loader='';
              console.log("Wrong Username & password ");
              this.auth.wrongValues=1;
            }, 1500);
            
          }
        },err => this.error = 'Could not authenticate'
        );
      }
    }
  }
import { Component, HostListener } from '@angular/core';
import { AuthService } from './_services/auth_service';
import { Router } from '@angular/router';
import * as $ from 'jquery';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bch';
  
  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '90%';
    }
    
    return 850;
  }
  constructor(public auth: AuthService,private router: Router)
  {
    console.log(this.auth.auth);    
    
    setTimeout(() => {
      
      console.log('aaaaaaa');
      $('body').attr('oncontextmenu', 'return false');
    }, 2000);
    
  }
  
  // commented
  
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);
    
    //for f12 restrict
    if (event.keyCode === 123) { 
      console.log("rigth key qrrow press");
      return false;
    }
    
    //for ctrl+shift+i restrict
    if(event.ctrlKey && event.shiftKey && event.keyCode == 'I'.charCodeAt(0))
    {
      console.log("ctrl+shift+i");
      return false;
    }
  }
  
  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }
}

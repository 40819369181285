import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { PushNotificationOptions, PushNotificationsService } from 'ngx-push-notifications';
import { PushNotificationsModule, PushNotificationsService } from 'ng-push';

import { retry } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  
  DbUrl:string = "https://ccrp.bchindia.in/ccrp_api/index.php/";
  UploadUrl:string = "https://ccrp.bchindia.in/ccrp_api/uploads";
  siteUrl:string = "https://ccrp.bchindia.in/ccrp_api/";
  

  // DbUrl:string = "http://localhost/ccrp_api/index.php/";
  // UploadUrl:string = "http://localhost/ccrp_api/uploads";
  // siteUrl:string = "http://localhost/";

  // DbUrl:string = "https://ccrp.bchindia.in/yogesh_ccrp/index.php/";
  // UploadUrl:string = "https://ccrp.bchindia.in/yogesh_ccrp/uploads";
  // siteUrl:string = "https://ccrp.bchindia.in/";

  // DbUrl:string = "https://68.183.246.121/ccrp_api/index.php/";
  // UploadUrl:string = "https://68.183.246.121/ccrp_api/uploads";
  // siteUrl:string = "https://68.183.246.121/";
  

  token:string = '';
  branch_id:any;
  branch_name:string='';
  branchuser_name:string='';
  branchuser_id:any;
  
  
  sys_user_id:any;
  sys_user_name:string='';
  dealer_name: string;
  dealer_id: any;
  tokenInfo:any;
  user_id: any;
  customer_id: any;
  customer_name: any;
  set_data:any={};
  dbinvoice_list=[];
  search_data: any=[];
  
  complaint_type:any;
  coming_from:any;

  complaint_active_tab:any='';
  ticket_active_tab:any='';

  constructor(public http:HttpClient,private _pushNotifications: PushNotificationsService) { }

  ngOnInit() {
    this._pushNotifications.requestPermission();
  }
  
  

  header:any = new HttpHeaders();
  
  FetchData(request_data:any, fn:any)
  {    
    this.noificaton();
    let header = new HttpHeaders();
    header = header.append('Authorization','Bearer ' + this.token);
    header = header.append("Content-Type", "application/json");
    console.log(request_data);
    console.log(this.token);
    console.log(request_data['search']);
    if(request_data['search'])
    {
      this.search_data = request_data['search'];
    }
    return this.http.post(this.DbUrl+fn, JSON.stringify(request_data), {headers:header});
  }
  
  DealerVerification(request_data:any, fn:any)
  {    
    let header = new HttpHeaders();
    header = header.append('Authorization','Bearer ' + this.token);
    header = header.append("Content-Type", "application/json");
    console.log(request_data);
    console.log(this.token);
    return this.http.post(this.DbUrl+fn, JSON.stringify(request_data), {headers:header});
  }
  
  get_rqst(fn: any) 
  {
    let header = new HttpHeaders();
    header = header.append('Authorization','Bearer ' + this.token);
    header = header.append("Content-Type", "application/json");
    console.log(this.token);
    return this.http.get(this.DbUrl + fn, {headers: header});
  }
  
  FileData(request_data:any, fn:any)
  {
    let header = new HttpHeaders();
    header.append('Content-Type',undefined);
    console.log(request_data);
    return this.http.post(this.DbUrl+fn, request_data, {headers : header});
  }
  
  noificaton_rqst():any 
  {
    let header = new HttpHeaders();
    header = header.append('Authorization','Bearer ' + this.token);
    header = header.append("Content-Type", "application/json");
    return this.http.post(this.DbUrl + 'login/getNotification', JSON.stringify({'login_id': this.tokenInfo.id}), {headers: header}).
    pipe(
      retry(3), 
      );
    }
    
    notifications:any = [];
    all_notifications:any = [];
    
    offFlag:any = false;
    offNotifiy:any = false;
    
    noificaton()
    {
      this.noificaton_rqst().subscribe(d => { 
        console.log(d);
        d = d.data;
        console.log(d);
        this.all_notifications =  d.notifications;
        if( (d.notify && d.notify.length > 0) && !this.offNotifiy){
          console.log("in send notify uppr");
          this.offFlag = false;
          this.notifications = d.notify;
          this.sendNotify(0);
        }
      }); 
    }
    sendNotify( index ) {
      if(this.offFlag)return;
      var e = this.notifications[ index ];
      console.log(index);
      
      console.log(e);
      if(!e)return;
      
      const title = e.title;
      
      let options = {
        body : e.message,
        icon : 'favicon.ico'
      }
      
      this._pushNotifications.create(title, options).subscribe((notif) => {
        if (notif.event.type === 'show') {
          console.log('onshow');
          setTimeout(() => {
            notif.notification.close();
            this.sendNotify(++index);
            console.log(index);
            
          }, 3000);
        }
        if (notif.event.type === 'click') {
          console.log('click');
          this.offFlag = true;
          notif.notification.close();
          
        }
        if (notif.event.type === 'close') {
          console.log('close');
        }
      },
      (err) => {
        console.log(err);
      });
    }
    setSearchValue(search){
      console.log(search);
      this.search_data=search;
      
    }
    getSearchValue()
    {
      console.log(this.search_data);
      return this.search_data;
    }


    // export excel start

    public exportAsExcelFile(json: any[], excelFileName: string): void {
      
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      console.log('worksheet',worksheet);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  
    private saveAsExcelFile(buffer: any, fileName: string): void {
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
    
    
  }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatabaseService } from './database.service';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";



@Injectable()
export class AuthService {

  // live
  DbUrl:string = "https://ccrp.bchindia.in/ccrp_api/index.php/";

  // Test live
  // DbUrl:string = "https://ccrp.bchindia.in/yogesh_ccrp/index.php/";

  // test
  // DbUrl:string = "https://68.183.246.121/ccrp_api/index.php/";
  wrongValues=0;

  constructor(private http: HttpClient,public db:DatabaseService,public router: Router) { }

  auth:any = {};
  
  ngOnInit()
  {
    this.auth.token='';
    console.log(this.auth);
  }

  login(username: string, password: string): Observable<boolean> {
    console.log(username);
    console.log(password);
    let request_data={username:username,password:password};
    
    console.log(request_data);
    return this.http.post<{token: string}>(this.DbUrl+'login/submitnew', JSON.stringify(request_data))
      .pipe(
        map(result => {
          console.log(result);
          if(result['message']=='Failed'){
          this.wrongValues=1;
          return false;
          }
          // this.wrongValues=0;
          this.auth.token = result.token;
          this.db.token = result.token;
          localStorage.setItem('access_token', result.token);
          console.log(localStorage);
          let tokenInfo = this.getDecodedAccessToken(this.db.token); // decode token
          let expireDate = tokenInfo.exp; // get token expiration dateTime
          this.db.tokenInfo = tokenInfo;
          this.db.dbinvoice_list=[];
          this.db.complaint_active_tab='';
          this.db.ticket_active_tab='';
          return true;
        })

      );

  }

  logout() {
    localStorage.removeItem('access_token');
    this.db.token = '';
    this.db.tokenInfo={};
    this.auth.token = '';
    this.db.dbinvoice_list=[];

    this.router.navigate(['']);
  }


  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }
}
import { Component, OnInit,Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-part-assign-modal',
  templateUrl: './part-assign-modal.component.html',
  styleUrls: ['./part-assign-modal.component.scss']
})
export class PartAssignModalComponent implements OnInit {
  assignForm: FormGroup;
  submitted = false;
  part_list:any=[];
  serv_engg_list:any=[];
  dealer_list:any=[];
  
  toast: any;
  filterRow:any;
  batch_code: any;
  branch_id:any;
  complaint_id:any;
  complaint_type:any;


  extra_source_field:any;
  issue_from_name: any;
  status: any;
  loader:any='';
  branch_stock:any=1;

  
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router, private dialogRef: MatDialogRef<PartAssignModalComponent>,@Inject(MAT_DIALOG_DATA) data) {
    this.branch_id = data.branch_id;
    this.complaint_id = data.complaint_id;
    this.complaint_type = data.complaint_type;

    console.log(this.branch_id);
    
    this.GetPartlist();
    this.GetEngg();
  }
  
  ngOnInit() 
  {
    this.assignForm = this.formBuilder.group({
      service_engg: ['', Validators.required],
      complaint_id: [this.complaint_id, Validators.required],
      serv_engg_assigned_stock: [''],
      branch_id: [this.branch_id],
      part_name: ['', Validators.required],
      part_id: ['', Validators.required],
      qty: ['', Validators.required],
      issue_from:[''],
      extra_issue_from:[''],
      complaint_status:[''],
      close_remark:[''],
      dealer: ['']
    });
  }
  
  get f() { return this.assignForm.controls; }
  
  quantity(event: any) 
  {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) 
    {event.preventDefault();}
  }
  
  GetPartlist()
  {
    this.db.FetchData({'complaint_type':this.complaint_type,'complaint_id':this.complaint_id},'complaint/get_spare_part_list_comp_wise')
    .subscribe((result:any)=>{
      console.log(result);
      this.part_list = result['data'];
      console.log(this.part_list);
    },error=>{
      console.log(error);
    });
  }
  
  
  GetEngg()
  {
    this.db.FetchData({'branch_id':this.branch_id},'complaint/service_engg_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.serv_engg_list = result['data']['engg_list'];
      console.log(this.serv_engg_list);
    },error=>{
      console.log(error);
    });
  }
  
  getpart_id(name)
  {
    console.log(name.value);
    let filterRow = this.part_list.filter(x => x.part_name == name.value)[0];
    console.log(filterRow);
    if(filterRow)
    {
      this.assignForm.controls['part_id'].setValue(filterRow.id);   
      this.get_assigned_part_info(this.assignForm.value.service_engg);
    }
    else
    {
      this.assignForm.controls['part_id'].setValue(0);   
    }
  }
  
  getissue_info(issue_from)
  {
    console.log(issue_from.value);
    this.issue_from_name=issue_from.value;
    console.log(this.issue_from_name);
    
    this.extra_source_field=true;
    if(this.issue_from_name=='Dealer')
    {
      this.loader='1';
      this.db.FetchData({},'dealer/dealer_list')
      .subscribe((result:any)=>{
        console.log(result);
        this.dealer_list = result['data']['list'];
        console.log(this.dealer_list);
       this.loader='';

      },error=>{
        console.log(error);
      });
    }
  
    else if(this.issue_from_name=='Branch')
    {
      
      this.db.FetchData({'part_id':this.assignForm.get('part_id').value,'service_engineer':this.assignForm.get('service_engg').value,'branch_id':this.branch_id},'BranchUser/check_branch_stock')
      .subscribe(result =>{
        console.log(result);
        
        // if(result['message']=='Success')
        // {
        //   this.assignForm.controls['serv_engg_assigned_stock'].setValue(result['data']['qty']);
        // }
        // else
        // {
        //   this.assignForm.controls['serv_engg_assigned_stock'].setValue(0);
        // }

        if(result && result['message']=='Success')
        {
          this.branch_stock = result['qty'];
          let temp_qty = parseInt(this.assignForm.get('qty').value) - parseInt(this.assignForm.get('serv_engg_assigned_stock').value);

          if(temp_qty > parseInt(this.branch_stock))
          {
            this.branch_stock=0;
          }
        }

        else if(result && result['message']=='Failed')
        {
          this.branch_stock=0;
        }

        else
        {
          this.branch_stock=0;
        }
        console.log(this.branch_stock);
        
      },error=>{
        console.log(error);
      });
    }

    else
    {
      this.assignForm.controls['dealer'].setValue(0);   
    }
    
  }

  get_assigned_part_info(engg_id)
  {
    console.log(engg_id);

    
    let filterRow = this.serv_engg_list.filter(x => x.id == engg_id)[0];
    console.log(filterRow);

    if(filterRow)
    {
      if(this.assignForm.value.part_id && filterRow.part_id == this.assignForm.value.part_id)
      {
        this.assignForm.controls['serv_engg_assigned_stock'].setValue(parseInt(filterRow.total_assigned_part));
        console.log(this.assignForm.value.serv_engg_assigned_stock);

      }
      else
      {
        this.assignForm.controls['serv_engg_assigned_stock'].setValue(0);
        console.log(this.assignForm.value.serv_engg_assigned_stock);

      }
    }
    else
    {
      this.assignForm.controls['serv_engg_assigned_stock'].setValue(0);
    }

    console.log(filterRow);
    console.log(this.assignForm.value.serv_engg_assigned_stock);

  }

  
  check_qty(qty)
  {
    console.log(qty.value);
    console.log(this.assignForm.value);
    console.log(this.assignForm.value.serv_engg_assigned_stock);
    if(qty.value)
    {
      if(parseInt(qty.value)>parseInt(this.assignForm.value.serv_engg_assigned_stock))
      {
        this.extra_source_field=true;
      }
      else
      {
        this.extra_source_field=false;
      }
    }
    console.log(this.extra_source_field);
    
  }

  getcomp_status(status)
  {
    console.log(status.value);
    if(status.value=='Close')
    {
      this.status=status.value;
      console.log(this.status);
      this.assignForm.controls["close_remark"].setValidators([Validators.required]);
      this.assignForm.controls['close_remark'].updateValueAndValidity();
    }
    else
    {
      this.assignForm.controls['close_remark'].clearValidators();
      this.assignForm.controls['close_remark'].updateValueAndValidity();
    }

  }
  
  Assign() 
  {
    this.submitted = true; 
    console.log(this.assignForm.value);

    // stop here if form is invalid
    if (this.assignForm.invalid) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { 
      //if valid value display in console
      console.log(this.assignForm.value);
       this.db.FetchData({'data':this.assignForm.value},'complaint/assign_spare_parts')
       .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!!@#");
          this.dialogRef.close(result);
        }
      },error=>{
        console.log("error");
        console.log(error);
       });
    }
  }
}
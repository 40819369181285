import {Component,OnInit} from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder} from '@angular/forms';
import { DatabaseService } from '../_services/database.service';
import { AuthService } from '../_services/auth_service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { SnackBarOverview } from 'src/app/Toast';




@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  
  loginForm: FormGroup;
  public error: string;
  submitted = false; //for login button
  loader:any=0;

  
  hide = true;
  
  constructor(private formBuilder: FormBuilder,public db:DatabaseService,private auth: AuthService,private router: Router,private route: ActivatedRoute,public toast: SnackBarOverview) { }
  
  data:any={};
  ngOnInit() {

    this.route.params.subscribe(resp=>{
      console.log(resp);
      this.data=this.db.set_data;
      console.log(this.data);
      
    })
    
    this.loginForm = this.formBuilder.group({
      username: [this.data.username, Validators.required],
      logged_id: [this.data.logged_id, Validators.required],
      dealer_code: [this.data.dealer_code, Validators.required],
      email: [this.data.email, Validators.required],
      password: ['', Validators.required],
    });
    // received parameter
  //   this.route.queryParams.subscribe(params => {
  //     let recv = params["id"];
  //     console.log(recv);
  // });
  }
  get f() { return this.loginForm.controls; }
  login() 
  {
    this.loader=1;
    this.submitted = true; 
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log("*****invalid data*****");
      this.loader=0;
      return;
    }
    else
    {
      this.loader = 1;
      this.db.DealerVerification({'data':this.loginForm.value},'login/forget_password')
      .subscribe((result)=>{
        console.log(result);  
        if(result['message']=='Success' && result['data'])      
        {

          this.submitlogin(result['data']['username'],result['data']['password']);
        }

        else
        {
          this.toast.openError('Something went wrong !!! Try Again...','');
        }
        this.loader = 0;
      },
      error=>{
        console.log(error);
        this.loader = '';
      });
  }
}

public submitlogin(username,password) 
  {
    this.auth.login(username, password)
      .pipe(first())
      .subscribe(
        (result) => {
          if(result)
          {
            if(this.db.tokenInfo.logged_in_type=='Dealer')
            {
              this.router.navigate(['dealer-main-complaint-list']);
            }    
          }
        },err => this.error = 'Could not authenticate'
      );
  }
}
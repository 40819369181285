import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-service-engg-list',
  templateUrl: './service-engg-list.component.html'
})
export class ServiceEnggListComponent implements OnInit {
  service_engg: any;
  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';

  serv_engg_count:any;

  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview) { }

  ngOnInit() {
    this.GetEnggList();

  }

  GetEnggList(pagelimit:any=3,start:any=0,action:any=''){
    this.loader = "1";
    this.pagelimit = pagelimit
    this.start = start;
    console.log(this.start);
    console.log(action);

    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'Service_Engg/service_engg_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.service_engg = result['data']['list'];
      this.toast.openSucess('Success ','');
      this.serv_engg_count = result['data']['branch_count']
      this.rqst_count = result['data']['count'];
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';

    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');      
    });
  }

  delete(index ,engg_id){
    console.log(index);
    console.log(engg_id);
    this.db.get_rqst('Service_Engg/delete_service_engg/'+engg_id)
    .subscribe(res => {
      console.log(res);
      this.service_engg.splice(index,1);
      this.toast.openSucess('Branch Deleted Successfully','');     
    },err=>{
      console.log(err);
      this.toast.openError('Something went wrong !!! Try Again...','');
      
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';


@Component({
  selector: 'app-system-user-complaint-list',
  templateUrl: './system-user-complaint-list.component.html'
})
export class SystemUserComplaintListComponent implements OnInit {
  loader:any = '';  
  user_name:any='';
  comp_data:any=[];
  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute,public dialog:DialogComponent) { 
    this.user_name = this.db.sys_user_name;

  }

  ngOnInit() {
    this.getCompList(this.db.sys_user_id);

  }

  getCompList(user_id)
  {
    this.loader = "1";
    console.log(user_id);
    this.db.get_rqst('user/get_user_complaints/' +user_id).subscribe((result)=>{
    console.log(result);  
    this.comp_data=result['data'];
    console.log("success");
    console.log(this.comp_data);
    this.loader = '';
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');      
      this.loader = '';
    });
  }

  delete_comp(id:any,index:any)
  {
    console.log(id);    
    this.dialog.delete('Complaint !').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.FetchData({},'user/delete_complaint/'+id).subscribe((response)=>{
          console.log(response);
          if(response['message']=='Success'){
            this.comp_data.splice(index,1);
            this.toast.openSucess('Complaint Deleted Sucessfully ','');    
          }
           

        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
    console.log(this.comp_data);
  }

}

import { Component, OnInit } from '@angular/core';
import {FormControl, Validators,FormGroup,FormBuilder, Form} from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { HttpClient , HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-add-dealer',
  templateUrl: './add-dealer.component.html'
})

export class AddDealerComponent implements OnInit {
  data:any={};
  form:any={};
  state_list=[];
  branch_list=[];
  district_list:any=[];
  user:any={};
  branch_name:any={};
  flag:any;
  isDisabled: boolean;
  tokenInfo:any={};
  img=0;
  flag1:any;
  
  constructor(public db:DatabaseService,public formbuilder:FormBuilder,public router:Router,public toast: SnackBarOverview , public http:HttpClient)
  {
    this.getstate();
    this.getBranch();
  }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;
    console.log(this.tokenInfo);
    this.form.country = 'India';
    this.isDisabled=false;
    
  }
  
  onFileSelected(event){
    console.log(event);
    this.selectedFile=<File>event.target.files[0];
    console.log(this.selectedFile);
    
  }
  
  selectedFile:any=[];
  urls = new Array<string>();
  selectvalue(data)
  {
    this.img=1;
    console.log("i am in new function");
    console.log(data);
    //to display image 
    let files = data.target.files[0];
    console.log(files);
    let reader = new FileReader();
    reader.readAsDataURL(data.target.files[0]);
    reader.onload =(e: any)=>{
      this.urls=e.target.result;
    }  
    this.selectedFile.push(data.target.files[0]);
    console.log(this.selectedFile);
  }
  
  getstate()
  {   
    this.db.FetchData({}, 'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
    this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }
  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validate(event:any)
  {
    const pattern = /^[0-9a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  charonly(event: any){
    const pattern =/^[a-zA-Z ]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) 
    {
      event.preventDefault();
    } 
  }
  
  formData=new FormData(); // for file uploading  
  submit()
  {
   
    console.log(this.formData);
    
    this.isDisabled=true;
    console.log(this.form);
    this.db.FetchData({'data':this.form},'dealer/submit')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message'] == "Success")
      {
        var insert_id = result['insert_id'];
        if(insert_id)
        {
          if(this.selectedFile && this.selectedFile.length)
          {
            this.formData.append("image" ,this.selectedFile[0],this.selectedFile[0].name);
            this.formData.append("id",insert_id);
            this.db.FileData(this.formData,"dealer/upload_dealer_profile_image")
            .subscribe((result:any) =>{
              console.log(result);
            },error=>{
              console.log(error);
            });
    
          }
        }
        this.toast.openSucess('Dealer Created Successfully','');
      }
      this.router.navigate(['/dealer-list']);
    });
  }
  
  getBranch()
  {
    this.db.FetchData({},'branch/branch_list').subscribe((result)=>{
      console.log(result);
      console.log("shushant");
      // this.district_list=result['data'];
      this.branch_list=result['data']['list'];
      
    });
  }
  username:any;
  isusernameLoading:any;

  getdealerCode(dealer_code)
  {
    console.log("first block");
    this.isusernameLoading=1;
    
    this.db.FetchData({'dealer_code':dealer_code},'dealer/check_dealer_code_existence')
    .subscribe((result:any)=>{
      console.log("first");
      console.log(result);
      if(result['message']=="Success")
      {
        this.flag=true;
        this.form.username='';
        this.isusernameLoading=0;

      }
      else{
        this.flag=false;
        // username code start
        console.log(this.form);
        this.form.username=this.form.name.substring(0,3).concat(this.form.dealer_code);
        console.log(this.username);
        // end
        this.isusernameLoading=0;

      }
    },error=>{console.log(error);
    });
   
  }
  UserName(username:any)
  {
    console.log(username);
    console.log(username);
    this.db.FetchData({'username':username},'master/check_username_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flag1=true;
        console.log(this.flag1);
        
      }
      else{
        this.flag1=false;
      }
      
    },error=>{
      console.log(error);
    }); 
  }
  
}
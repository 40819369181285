import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';



@Component({
    selector: 'app-complaint-item-action',
    templateUrl: './complaint-item-action.component.html',
    styleUrls: ['./complaint-item-action.component.scss']
})
export class ComplaintItemActionComponent implements OnInit {
    submitted = false;
    order_qty: any;
    item_id:any;
    item_name:any;
    cat_code:any;
    issue_type:any=[];
    error:any = [];
    checkedItems:any=[];
    exist_issue:any=[];
    complaint_type:any='';
    level3_code:any;
    level3: any;
    item_issue:any=[];
    total_fault_qty:any=0;
    constructor(public toast: SnackBarOverview,private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<ComplaintItemActionComponent>,@Inject(MAT_DIALOG_DATA) data)
    { 
        console.log(data);
        this.order_qty = data.order_qty;
        this.item_id = data.item_id;
        this.item_name = data.item_name;
        this.cat_code = data.cat_code;
        this.level3_code = data.level3_code;
        this.level3 = data.level3;
        this.item_issue = data.item_issue;
        this.exist_issue = data.exist_issue;
        this.complaint_type = data.complaint_type;
        console.log(this.order_qty); 
        console.log(this.item_issue); 
        
        if(this.item_issue && this.item_issue.length > 0)
        {
            this.item_issue.forEach(element => {
                console.log(element);
                this.total_fault_qty+=parseInt(element.fault_qty);
            });
            console.log(this.total_fault_qty);
            
            this.order_qty=parseInt(this.total_fault_qty);
        }

        this.current_ord_qty = this.order_qty;
        
        console.log(this.total_fault_qty);        
        console.log(this.order_qty); 
        console.log(this.item_id); 
        console.log(this.item_name); 
        console.log(this.cat_code); 
        console.log(this.exist_issue); 
        console.log(this.level3_code); 
        console.log(this.level3); 
        console.log(this.item_issue); 
    }
    
    ngOnInit() {
        this.get_all_issues();
        console.log(this.issue_type);
    }  
    
    get_all_issues()
    {
        this.db.FetchData({'complaint_type':this.complaint_type,'cat_code':this.cat_code,'level3_code':this.level3_code,'level3':this.level3},'complaint/get_comp_type_issues')
        .subscribe((result)=>{
            console.log(result);
            this.issue_type=[];
            if(result['message']=='Success')
            {
                var result_data = result['data'];
                for(var i=0;i<result_data.length;i++)
                {
                    this.issue_type.push({name:result_data[i].issue_type,fault_qty:'',remark:'',checked:false,item_id:this.item_id,order_qty:this.order_qty});
                }
            }
            console.log(this.issue_type);
            if(this.exist_issue.length>0 && this.issue_type.length>0)
            {
                for(var i=0;i<this.exist_issue.length;i++)
                {
                    let temp_index = this.issue_type.findIndex(({ name }) => name == this.exist_issue[i].name); 
                    console.log(temp_index);
                    if(temp_index!=-1)
                    {
                        this.issue_type[temp_index].checked=true;
                        if(this.complaint_type=='Logistic')
                        {
                            this.issue_type[temp_index].fault_qty=this.exist_issue[i].fault_qty;
                        }
                        this.issue_type[temp_index].fault_qty=this.exist_issue[i].fault_qty;
                        this.issue_type[temp_index].remark=this.exist_issue[i].remark;
                        this.issue_type[temp_index].item_id=this.exist_issue[i].item_id;
                        this.checkedItems.push(this.issue_type[temp_index]);
                    }
                }
            }
        },error=>{
            console.log("error");
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
        });
    }
    
    validate_fault_qty(event:any,index:any)
    {
        const pattern = /^[0-9]+$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar))
        {
            event.preventDefault();
        }
        console.log(this.issue_type);
    }
    
    getCheckedRow(event,index)
    {
        console.log(index);
        console.log(this.issue_type[index]);
        console.log(this.issue_type);
        
        this.issue_type[index] = Object.assign({},this.issue_type[index]);
        this.issue_type[index]['checked'] = !this.issue_type[index]['checked'];   
        console.log(this.issue_type[index]['checked']);
        console.log(this.issue_type[index]);
        console.log(this.checkedItems);
        
        
        
        if(this.issue_type[index]['checked']==true)
        {
            console.log("CheckedTrue");      
            console.log('fault qty' + this.issue_type[index]['fault_qty']);
            console.log('order qty' + this.issue_type[index]['order_qty']);
            
            if(this.issue_type[index]['fault_qty'] && (this.issue_type[index]['fault_qty'] <= this.issue_type[index]['order_qty']))
            {
                console.log("fault qty r8");
                console.log('fault qty' + this.issue_type[index]['fault_qty']);
                console.log('order qty' + this.issue_type[index]['order_qty']);
                console.log(this.issue_type[index]);
                this.checkedItems.push(this.issue_type[index]);
            }
            else
            {
                console.log("fault qty wrng");
                let existindex = this.checkedItems.findIndex(({ name }) => name == this.issue_type[index].name); 
                console.log(existindex);
                if(existindex!=-1)
                this.checkedItems.splice(existindex,1);
                this.issue_type[index]['checked']=false;
                this.toast.openError('Fault Qty Mandotry','');
            }
        }
        
        else if(this.issue_type[index]['checked']==false)
        {
            console.log("fault qty wrng");
            console.log("CheckedFalse");
            let existindex = this.checkedItems.findIndex(({ name }) => name == this.issue_type[index].name); 
            console.log(existindex);
            if(existindex!=-1)
            this.checkedItems.splice(existindex,1);
            this.issue_type[index]['checked']=false;
        }    
        console.log(this.checkedItems);
    }
    
    
    data:any = {};
    qty_error:any = [];
    CheckQty(index:any)
    {
        console.log(this.issue_type[index]);
        this.data = Object.assign({},this.issue_type[index]);
        
        console.log(this.data);
        console.log(this.data.fault_qty);
        
        if((this.data.fault_qty <= 0) || ((this.data.fault_qty) > this.data.order_qty) || this.data.fault_qty==null)
        {
            console.log("hello");
            this.error[index] = Object.assign({'qty' : 1});
            this.issue_type[index].checked=false;
            this.data.checked=false;
            let existindex = this.checkedItems.findIndex(({ name }) => name == this.data.name); 
            console.log(existindex);
            if(existindex!=-1)
            {
                this.checkedItems.splice(existindex,1);
            }
        }
        else if(this.data.fault_qty!=null)
        {
            console.log("hey");
            
            this.error[index] = Object.assign({'qty' : 0});
            this.issue_type[index].checked=true;
            this.data.checked=true;
            console.log(this.checkedItems);
            console.log(this.data.name);
            let exist_row = this.checkedItems.filter(x=>x.name===this.data.name)[0];
            console.log(exist_row);
            if(!exist_row)
            {
                this.checkedItems.push(this.data);
            }
        }
        
        this.qty_error = this.error.filter((x) => x.qty == 1);//for future use
        this.calculate_order_qty(index);
        
        console.log(this.error);
        console.log(this.data);
        console.log(this.qty_error);
        console.log(this.checkedItems);
    }
    
    current_ord_qty:any=0;
    calculate_order_qty(index)
    {
        var tmp_order_qty = this.order_qty;
        this.current_ord_qty = this.order_qty;

        if(this.issue_type && this.issue_type.length > 0)
        {
            var tmp_fault_qty=0;
            console.log(this.issue_type);
            this.issue_type.forEach(element => {
                console.log(element);
                if(element.fault_qty != "")
                {
                    tmp_fault_qty+=parseInt(element.fault_qty);
                }
            });
            
            if(tmp_fault_qty)
            {
                tmp_order_qty-=tmp_fault_qty;
                if(tmp_order_qty < 0)
                {
                    this.toast.openError("Invalid Quantity","Error");
                    console.log(this.order_qty);
                    console.log(tmp_order_qty);
                    tmp_order_qty = this.order_qty;
                }
            }
            else
            {
                tmp_order_qty = this.order_qty;
            }
            this.current_ord_qty = tmp_order_qty;
        }
        console.log(this.order_qty);
        
    }
    
    create() 
    {
        var j=0;
        console.log(this.checkedItems);
        for(var i=0;i<this.issue_type.length;i++)
        {
            console.log(this.issue_type[i].remark);
            
            if(this.issue_type[i].checked==true)
            {
                this.checkedItems[j] = Object.assign({},this.issue_type[i]);
                j++;
            }
        }
        console.log(this.checkedItems);    
        console.log(this.issue_type);    
        this.dialogRef.close(this.checkedItems);
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})

export class CreateTicketComponent implements OnInit {
  createTicketForm: FormGroup;
  submitted = false;
  toast: any;
  department_list: any=[];
  user_list:any=[];
  assign_type: any=[];
  detail: any ='';
  complaint_id: any;
loader:any='';
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,private dialogRef:MatDialogRef<CreateTicketComponent>,@Inject(MAT_DIALOG_DATA)data) { 
    this.GetDepartment();
     this.complaint_id = data.complaint_id;
     console.log(this.complaint_id); 
  }

  ngOnInit() {
    this.createTicketForm = this.formBuilder.group({
      assign_type: ['', Validators.required],
      assign_to: ['', Validators.required],
      // ticket_type: [''],
      description: ['', Validators.required],
      complaint_id: [ this.complaint_id,Validators.required ],
      assign_to_name: ['',Validators.required]
    });
  }

  get f() { return this.createTicketForm.controls; }
 
  GetDepartment()
  {
    this.db.FetchData({},'master/department_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.department_list = result['data'];
      console.log(this.department_list);
    },error=>{
      console.log(error);
    });
  }

  getUser(department_name:any)
  {
    console.log(department_name.value);
    this.db.FetchData({'department_name':department_name.value},'master/department_userhead_list')
      .subscribe((result:any) =>{
        console.log(result);
        this.user_list = result['data'];
        console.log(this.user_list);
      },error=>{
        console.log(error);
      });
  }

  getUserName(id)
  {
    console.log(id.value);
    
    let forward_name = this.user_list.filter(x=>x.id==id.value)[0].name;
    this.createTicketForm.controls['assign_to_name'].setValue(forward_name);
  }


  Create() 
  {
    this.loader='1';
    this.submitted = true; 
    if (this.createTicketForm.invalid) 
    {
      this.loader='';
      return;
    }
    else
    { 
      console.log(this.createTicketForm.value);
       this.db.FetchData({'data':this.createTicketForm.value},'complaint/create_internal_ticket')
       .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!!@#");
          this.loader='';
          this.dialogRef.close(result);
        }
      },error=>{
        console.log("error");
        console.log(error);
        this.loader='';
       });
    }
  }
}
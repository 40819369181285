import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { SnackBarOverview } from 'src/app/Toast';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../DialogComponent.js';
import {Location} from '@angular/common';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-dashboard-complaints-list',
    templateUrl: './dashboard-complaints-list.component.html',
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})

export class DashboardComplaintsListComponent implements OnInit {
    date = new FormControl(moment());
    @ViewChild('table') table: ElementRef;
    complaint:any;
    start:any = 0;
    pagelimit:any = 100;
    search:any = {};
    rqst_count:any = '';
    total_page:any = ''
    pagenumber:any = '';
    loader:any = '';  
    tokenInfo:any={};
    length:any;
    disp_running:any=[];
    complaint_category_list:any=[];
    branchlist:any=[];
    sendRequest:any=true;
    all_complaint:any=[];
    status_name:any;
    today_date;
    complaint_excel:any = [];
    
    constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent,public route:ActivatedRoute,private _location: Location)
    {
        this.route.params.subscribe(params=>{
            console.log(params);
            if(params.dashboard=="true")
            {
                this.search.dashboard = params.dashboard;
                this.search.dashboard_from = params.dashboard_from;
                this.search.dashboard_product_level1 = params.product_level1;
                this.search.dashboard_search_status = params.status;
                this.search.dashboard_branch_code = params.branch_code;
                this.search.dashboard_complaint_type = params.complaint_type;
                this.search.dashboard_date = params.date;
                this.search.chart_type = params.chart_type;
            }
            else
            {
                this.search={};
            }
            console.log(this.search);
        });
    }
    
    ngOnInit()
    {
        this.today_date = new Date().toISOString().slice(0,10);
        console.log(this.today_date)
        this.tokenInfo = this.db.tokenInfo;
        console.log(this.tokenInfo);
        this.getcomplaint_category();
        this.get_branch_list();
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    backClicked() {
        console.log( 'goBack()...' );
        this._location.back();
    }
    validate(event:any)
    {
        const pattern = /^[0-9 ]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
        {event.preventDefault();}
    }
    get_branch_list()
    {
        this.db.FetchData({},'master/branch_list_forsearch').subscribe(resp=>{
            console.log(resp);
            this.branchlist=resp['data'];
            console.log(this.branchlist);
        });
    }
    search_comp_status(status:any)
    { 
        console.log(status);
        this.search.source = status; 
        console.log(this.search.status);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    search_comp_category(cat:any)
    { 
        console.log(cat);
        this.search.complaint_category = cat; 
        console.log(this.search.complaint_category);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    search_dealer(dealer:any)
    { 
        console.log(dealer);
        this.search.dealer_name = dealer; 
        console.log(this.search.dealer);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    search_customer(customer:any)
    { 
        console.log(customer);
        this.search.customer = customer; 
        console.log(this.search.customer);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    search_branch(branch_name:any)
    { 
        console.log(branch_name);
        this.search.branch_name = branch_name; 
        console.log(this.search.branch_name);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    search_comp_number(comp_no:any)
    { 
        this.search.comp_no = comp_no; 
        console.log(this.search.comp_no);
        console.log(this.sendRequest);
        
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    search_created_by(created_by:any)
    {
        this.search.created_by = created_by; 
        console.log(this.search.created_by);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    search_by_date(date_to:any,date_from:any)
    {
        this.search.to=date_to;
        console.log(this.search.to);
        this.search.from=date_from;
        console.log(this.search.from);
        this.getComplaintList(this.pagelimit,this.start,'');
    }
    
    getcomplaint_category()
    {
        this.db.FetchData({},'master/complaint_category_list')
        .subscribe((resp:any)=>{
            console.log(resp);
            this.complaint_category_list = resp['data'];
        });
    }
    
    getComplaintList(pagelimit:any=100,start:any=0,action:any=''){
        
        this.complaint_excel=[];
        this.loader = "1";
        this.pagelimit = pagelimit;
        console.log(this.pagelimit);
        
        this.start = start;
        console.log(this.start);
        if(action == "refresh")
        {
            this.search = {};
            this.start = 0;
            this.db.complaint_active_tab='';
        }
        console.log(status);
        
        console.log(this.search);
        this.status_name = status;
        this.db.complaint_active_tab = status;
        console.log(this.status_name);
        this.search.status = status;
        console.log('****** SEARCH *****');
        console.log(this.search);
        
        this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'dashboard/complaint_list')
        .subscribe((result:any)=>{
            console.log(result);
            this.sendRequest=false;
            this.all_complaint=result['data']['list'];
            this.complaint=this.all_complaint;
            
            this.rqst_count = result['data']['count'];
            console.log(this.rqst_count);
            console.log(this.pagelimit);
            this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
            console.log(this.total_page);
            this.pagenumber = (this.start/this.pagelimit)+1;
            this.loader = '';
        },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');   
            this.loader = '';
        });
    }
    
    // export excel start
    downloadFile(status:any)
    {
        this.loader = "1";
        console.log(this.start);
        console.log(status);
        
        console.log(this.search);
        this.status_name = status;
        console.log(this.status_name);
        this.search.status = status;
        console.log('****** SEARCH *****');
        console.log(this.search);
        
        this.db.FetchData({'search':this.search},'complaint/complaint_list_excel')
        .subscribe((result:any)=>{ 
            console.log(result);
            this.db.exportAsExcelFile(result.data.list, 'COMPLAINT SHEET');
            this.loader = '';
        });
    }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return value.replace(/_/g, " ");
    }
}

import { Component, OnInit,ElementRef, HostListener, Input  } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import {Location} from '@angular/common';


@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
})
export class CustomerDetailComponent implements OnInit {
  ecrpt_id:any='';
  active: any = {}; 
  loader:any;
  hide = true;
  logs: any=[];
  cust_detail: any=[];
  dealer_detail: any=[];
  tokenInfo:any={};
  state_list: any=[];
  district_list: any=[];
  
  constructor(public db:DatabaseService, public router:Router,public toast:SnackBarOverview, public route:ActivatedRoute, private _location: Location) { 
    this.route.params.subscribe(params=>{
      console.log(params);
      this.ecrpt_id = params.id;
      console.log(this.ecrpt_id);
    });
    this.GetStates();

  }
  
  ngOnInit() {
    this.tokenInfo = this.db.tokenInfo;

    this.getCustomerDetail();
  }


  @HostListener('click') onClick() {
    
  }
  // this.active.name="0"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0"; 
  // edit_name()    {  this.active.name="1"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0";  }
  edit_mobile()  { this.active.contact="1"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0"; }
  edit_email()   { this.active.email="1"; this.active.contact="0";this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0";   }
  edit_shipping_street(){ this.active.shipping_street="1";  this.active.contact="0"; this.active.email="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0"; }
  edit_billing_street(){  this.active.billing_street="1"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.city="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0";  }
  edit_city(){this.active.city="1";this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0"; this.active.pincode="0";this.active.state="0"; this.active.district="0";   }
  edit_pincode(){this.active.pincode="1"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0";this.active.state="0"; this.active.district="0";  }
  edit_state(){this.active.state="1"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0"; this.active.district="0";  }
  edit_district(){this.active.district="1"; this.active.contact="0"; this.active.email="0"; this.active.shipping_street="0"; this.active.billing_street="0";this.active.city="0"; this.active.pincode="0";this.active.state="0";  }

  backClicked() {
    console.log( 'goBack()...' );
    this._location.back();
  }
  
  getCustomerDetail()
  {
    this.loader = '1';
    console.log("testing Phase");
    console.log(this.ecrpt_id);
    this.db.FetchData({'customer_id':this.ecrpt_id},'dealer/customer_detail')
    .subscribe((result)=>{
      console.log(result);
       this.cust_detail=result['data']['detail'];
       this.dealer_detail=result['data']['dealer'];
       this.logs = result['data']['logs'];
       this.loader = '';
      }, error => {
        console.log(error);
        this.toast.openError('Something went wrong !!! Try again... ','');
        this.loader = '';
      });
  }

  updateCustomerDetail()
  {
    this.loader = '1';
    console.log(this.cust_detail);
    this.db.FetchData({'data': this.cust_detail},'dealer/update_customer')
    .subscribe((result)=>{
      console.log(result);
      if (result['message'] == "Success") {
        console.log("Updated!");
        this.toast.openSucess('Updated! ','');
        this.getCustomerDetail();
      }
      else {
        console.log("X something went wrong! ");
        this.toast.openError('Something went wrong !!! Try again... ','');
      }
    }, error => {
      console.log(error);
    }); 
    this.active = {};
  }
  
  GetStates()
  {   
    this.db.FetchData({},'branch/get_states')
    .subscribe(result=>{
      console.log(result);
      this.state_list=result['data']; 
    });
  }
  
  getdistrict(state_name:any)
  {    
    console.log(state_name);    
    console.log('branch/get_state_district/'+state_name);
        this.db.FetchData({'state_name':state_name},'branch/get_state_district')
    .subscribe(result=>{
      console.log(result);
      this.district_list=result['data'];
    });
  }

  MobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  charonly(event: any){
    const pattern =/^[a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
}
import { Component, OnInit } from '@angular/core';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { Select2OptionData } from 'ng2-select2';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  addRoleForm: FormGroup;
  complaint_category_list:any=[];
  cart_list_data:any=[];
  public exampleData: Array<Select2OptionData>;
  public options: Select2Options;
  public value: string[];
  isDisabled: boolean;
  submitted = false; //for submit button
  complaint_action_list:any=[];
  product_levels:any=[];
  category_id: any;
  per_arr_for_disable:any=[];
  action_id: any;
  product_level_id: any=[];
  selected_product_level:any=[];
  PermissionSearchForm: FormGroup;
  isPerLoading = false;
  filteredPermission: any=[];
  cat_name: any;
  category_name: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  permission_array_selectall: any=[];

  constructor(private formBuilder: FormBuilder,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) { 
    this.getcomplaint_category();
    this.getComplaintAction(); //permission array
    this.getProductLevel();
  }
  
  ngOnInit() 
  {
    this.addRoleForm = this.formBuilder.group({
      role_name: ['', Validators.required],
      complaint_category_array: [[]],
    });
    // product levels
    this.value = []; 
    this.options = {
      multiple: true
    }
    
    /******permission suggestive search  */
    this.PermissionSearchForm = this.formBuilder.group({
      search_permission: null,
      // search_permission: ['']
    })
    
    this.PermissionSearchForm
    .get('search_permission')
    .valueChanges
    .pipe(
      debounceTime(300),
      tap(() => this.isPerLoading = true),
      switchMap(value => this.db.FetchData({per_value_search: value}, 'master/complaint_permission_suggestive_search')
      .pipe(
        finalize(() => this.isPerLoading = false),
        )
        )
        )
        .subscribe(permission => {
          console.log(permission);
          this.filteredPermission = permission;
          this.filteredPermission = this.filteredPermission.data;
          console.log(this.filteredPermission);
        });   

        
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'name',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
        };
      }
       

      get d() { return this.addRoleForm.controls; }
      
      getcomplaint_category()
      {
        this.db.FetchData({},'master/complaint_category_list')
        .subscribe((resp:any)=>{
          console.log(resp);
          this.complaint_category_list = resp['data'];
          console.log(this.complaint_category_list);
        });
      }
      getProductLevel()
      {
        this.db.FetchData({},'master/get_product_level')
        .subscribe((resp:any)=>{
          console.log(resp);
          this.product_levels = resp['data'];
        });
      }
      getComplaintAction()
      {
        this.db.FetchData({},'master/get_complaint_action')
        .subscribe((resp:any)=>{
          console.log(resp);
          this.complaint_action_list = resp['data'];
        });
      }
      changed_product_level(event)
      {
        console.log(event);
        this.selected_product_level=[];
        for(var i=0;i<event.data.length;i++)
        {
          this.selected_product_level.push({'level_id': event.data[i]['id'],'level_name':event.data[i]['text']});
        }
        console.log(this.selected_product_level);
      }
      

      onItemSelect(item: any) {
        console.log(" i am in item select");
        
        console.log(item);
      }
      name:any;
      onSelectAll(items: any) {
        console.log(" i am in select all");
        console.log(items); 
        this.per_arr_for_disable=items;
       
      }

      //for disable button
      getpermissionvalue(permission)
      {
        console.log(permission);
        if(permission){
          this.per_arr_for_disable=permission;
        }
      }
      
      selected_cat_name:any=0;
      Get_selected_cat_name(cat_name)
      {
        console.log(cat_name);
        
        if(cat_name=='Product')
        {
          this.selected_cat_name=1;
        }
        else
        {
          this.selected_cat_name=0;
        }

        console.log(this.selected_cat_name);
        

      }
      addToList(category,permission_array)
      {
        console.log("PERMISSION ARRAY : ");
        console.log(category.value);
        console.log(this.complaint_action_list);
        console.log(this.complaint_action_list.length);

        // for(var i=0;i<this.complaint_action_list.length;i++)
        //   {
        //     delete (this.complaint_action_list[i].id);
        //   }  
        // console.log(this.complaint_action_list);
        
        // for(var i=0;i<this.complaint_action_list.length;i++)
        //   {
        //     this.complaint_action_list.push({'id': i});
        //   }
          
        console.log(this.complaint_action_list);
        
        
        console.log(permission_array);
        console.log(permission_array.length);


        console.log(this.cart_list_data);
        let existindex = this.cart_list_data.findIndex(({ category_name }) => category_name == category.value); 
        console.log(existindex);
        if(existindex!=-1)
        {
          // let temp_perm = [];

          let temp_perm = [{'action_id':'', 'action_name': 'View'}  ];
          console.log(temp_perm);

          for(var i=0;i<permission_array.length;i++)
          {
            let filterRowPermission = this.complaint_action_list.filter(x => x.name == permission_array[i].name)[0]['id'];
            temp_perm.push({'action_id': filterRowPermission,'action_name':permission_array[i].name});
            console.log(temp_perm);
          }
          this.cart_list_data[existindex]['permission_array']=temp_perm;
        }
        else
        {
          console.log("new push");
          // console.log(permission_array.value);
          let temp_perm = [{'action_id':'', 'action_name': 'View'}  ];
          console.log(temp_perm);

          let filterRowCat = this.complaint_category_list.filter(x => x.category_name == category.value)[0];
          this.category_id=filterRowCat.id;
          // let temp_perm = [];
          for(var i=0;i<permission_array.length;i++)
          {
            let filterRowPermission = this.complaint_action_list.filter(x => x.name == permission_array[i].name)[0]['id'];
            temp_perm.push({'action_id': filterRowPermission,'action_name':permission_array[i].name});
          }
          let data = {'category_name':category.value,'category_id':this.category_id,'permission_array':temp_perm,'product_level':this.selected_product_level};
          
          if(data['category_name']!='Product'){
            data['product_level']=[];
          }

          this.cart_list_data.push(data);
        }
        // this.addRoleForm['category']='';
        // this.PermissionSearchForm['search_permission']='';
        // this.addRoleForm.reset();
        // this.PermissionSearchForm.reset();
      }
      
      delete(index)
      {
        console.log("i am in delete function");
        this.cart_list_data.splice(index,1);
        console.log(this.cart_list_data);
      }

      charonly(event: any){
        const pattern =/^[a-zA-Z_0-9]+$/;
        let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
        {event.preventDefault();}
      }
      
      submitUser() 
      {
        console.log("i am in function");
        this.addRoleForm.controls['complaint_category_array'].setValue(this.cart_list_data);
        console.log(this.addRoleForm.value);
        this.submitted = true; 
        // stop here if form is invalid
        if (this.addRoleForm.invalid)
        {
          console.log("i am in Invalid");
          return;
        }
        else
        { //if valid value display in console
          console.log("i am in else");
          console.log(this.addRoleForm.value);
          this.isDisabled=true;
          this.db.FetchData({'data':this.addRoleForm.value},'master/save_role')
          .subscribe((result)=>{
            console.log(result);
            if(true)
            {
              this.toast.openSucess('Role Created Successfully','');
              this.router.navigate(['/master-list']);
            }
          },error=>{
            console.log(error);
            this.toast.openError('Something went wrong !!! Try Again...','');
          });
        }
      }
    }
    
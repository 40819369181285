import { Component, OnInit,Inject } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-stock-assign-modal',
  templateUrl: './stock-assign-modal.component.html',
  styleUrls: ['./stock-assign-modal.component.scss']
})
export class StockAssignModalComponent implements OnInit {
  assignForm: FormGroup;
  submitted = false;
  part_list:any=[];
  serv_engg_list:any=[];
  toast: any;
  filterRow:any;
  batch_code: any;
  branch_id:any;
  loader: any='';
  
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router, private dialogRef: MatDialogRef<StockAssignModalComponent>,@Inject(MAT_DIALOG_DATA) data) {
    this.branch_id = data.branch_id;
    console.log(this.branch_id);
    this.GetPartlist();
    this.GetEngg();
  }
  
  ngOnInit() 
  {
    this.assignForm = this.formBuilder.group({
      service_engg: ['', Validators.required],
      complaint_id: [''],
      branch_id: [this.branch_id],
      part_name: ['', Validators.required],
      part_id: ['', Validators.required],
      qty: ['', Validators.required],
      issue_from:['Branch'],
      current_stock:[''],
    });
  }
  
  get f() { return this.assignForm.controls; }
  
  quantity(event: any) 
  {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar)) 
    {event.preventDefault();}
  }
  
  GetPartlist()
  {
    this.db.get_rqst('branch/get_branch_spare_parts/'+this.branch_id)
    .subscribe((result:any)=>{
      console.log(result);
      this.part_list = result['data'];
      console.log(this.part_list);
    },error=>{
      console.log(error);
    });
  }
  
  
  GetEngg()
  {
    this.db.FetchData({'branch_id':this.branch_id},'complaint/service_engg_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.serv_engg_list = result['data']['engg_list'];
      console.log(this.serv_engg_list);
    },error=>{
      console.log(error);
    });
  }
  
  max_qty_error:any=false;
  max_qty:any='';
  
  getOtherpart_info(part_id){
    console.log(part_id.value);
    let filterRow = this.part_list.filter(x => x.part_id == part_id.value)[0];
    console.log(filterRow);
    this.loader = "1";

    if(filterRow)
    this.assignForm.controls['part_name'].setValue(filterRow.part_name);   
    
    this.db.FetchData({'part_id':part_id.value,'branch_id':this.branch_id},'branch/get_branch_existing_part_info')
    .subscribe(result =>{
      console.log(result);
      if(result['message']=='Success')
      {
        this.assignForm.controls['current_stock'].setValue(result['data']['qty']);
        this.max_qty = result['data']['qty'];

      }
      else
      {
        this.assignForm.controls['current_stock'].setValue(0);
        this.max_qty = 0;
        
      }
      this.loader = '';

      
    },error=>{
      console.log(error);
      this.loader = '';

    });
  }
  
  get_qty()
  {
    console.log("qty check");
    
    if(this.assignForm.controls['qty'].value > this.assignForm.controls['current_stock'].value)
    {
      this.max_qty_error=true;
    }
    else
    {
      this.max_qty_error=false;
      
    }
    console.log(this.max_qty_error);
    
  }
  
  Assign() 
  {
    this.loader='1';
    this.submitted = true; 
    console.log(this.assignForm.value);
    
    // stop here if form is invalid
    console.log(this.max_qty_error);
    
    if (this.assignForm.invalid || this.max_qty_error) {
      console.log("*****invalid data*****");
      this.loader='';
      
      return;
    }
    else
    { 
      //if valid value display in console
      console.log(this.assignForm.value);
      this.db.FetchData({'data':this.assignForm.value},'branch/assign_spare_parts')
      .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!!@#");
          this.dialogRef.close(result['message']);
          this.loader='';
        }
        else
        {
          this.dialogRef.close(result['message']);
          this.loader='';
        }
      },error=>{
        console.log("error");
        console.log(error);
        this.loader='';
      });
    }
  }
}
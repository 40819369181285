import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';
import { DialogComponent } from '../../DialogComponent.js';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html'
})
export class BranchListComponent implements OnInit {
  status: string;
  loader:any = '';  
  start:any = "0";
  pagelimit:any = "20";
  search:any = {};
  rqst_count:any = '';
  total_page:any = ''
  pagenumber:any = '';
  branch: any=[];
  total_complaints:any;
  total_open_complaints:any;
  branch_comp_count:any = [];
  branch_name: any=[];
  print_totalcomp:any=[];  
  sendRequest:any=true;
  token_info: any={};
  regionlist:any=[];


  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public dialog:DialogComponent) { }
  ngOnInit() {
    this.GetBranchList();
    this.GetRegionList();

    this.token_info=this.db.tokenInfo;

  }

  statusChange(status,id,index){
    console.log(status);
    console.log(id);
    this.dialog.statusChangeConfirmation(' status').then((result) => {
      console.log(result); //true
      if(result)
      {
        this.db.FetchData({'branch_id': id,'status':status},'branch/change_status')
        .subscribe(res => {
          console.log(res);
          if(res['message']=='Success')
          {
            console.log(this.branch[index]['status']);
            if(this.branch[index]['status']=='Active'){
              this.branch[index]['status']='Deactive';
            }
            else{
              this.branch[index]['status']='Active';
            }
            this.toast.openSucess('Status Changed Sucessfully ','');      
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try again... ','');      
        });
      }
    });
  }

  public toggle(event,status,index,id) 
  {
    console.log('toggle', event.checked);
    // this.useDefault = event.checked;
    this.dialog.confirmation('Status!').then((result) => {
      console.log(result);
      console.log(id);
      if(result==true)
      {
        console.log("if");
        if(event.checked==true)
        this.branch[index]['status'] = 'Active';
        else if(event.checked==false)
        this.branch[index]['status'] = 'Deactive';
        this.db.FetchData({'branch_id':id,'status':this.branch[index]['status']},'branch/change_status').subscribe((response)=>{
          console.log(response);
          if(response['message']=='Success')
          {
            this.toast.openSucess('Status'+' '+ this.branch[index]['status']+ ' '+ 'Successfully','');
          }
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
      else
      {
        console.log("else");
        console.log(status);
        this.branch[index]['status'] = status;
        if(status=='Active')
        event.checked = true;
        else
        event.checked = false;
        console.log('toggle', event.checked);
        console.log(this.branch[index]['status']);
      }
    });
  }

  GetRegionList()
  {
    this.db.FetchData({},'master/region_list').subscribe(resp=>{
      console.log(resp);
      this.regionlist=resp['data'];
      console.log(this.regionlist);
    });
  }


  validate(event:any)
  {
    const pattern = /^[0-9 ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.key>this.total_page || !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  search_branch_name(branch_name:any)
  {
    this.search.branch_name=branch_name;
    console.log(this.search.branch_name);
    this.GetBranchList();    
  }
  search_branch_code(branch_code:any)
  {
    this.search.branch_code=branch_code;
    console.log(this.search.branch_code);
    this.GetBranchList(); 
  }

  search_region(region:any)
  {
    this.search.region=region;
    console.log(this.search.region);
    this.GetBranchList(); 
  }
  search_phone(phone:any)
  {
    this.search.phone=phone;
    console.log(this.search.phone);
    this.GetBranchList(); 
  }
  search_address(address:any)
  {
    this.search.address=address;
    console.log(this.search.address);
    this.GetBranchList(); 
  }
  
  GetBranchList(pagelimit:any=20,start:any=0,action:any=''){
    this.loader = "1";
    this.pagelimit = pagelimit;
    this.start = start;
    console.log(this.start);
    console.log(action);
    if(action == "refresh")
    {
      this.search = {};
      this.start = 0;
    }
    this.db.FetchData({'search':this.search,'start':this.start,'pagelimit':this.pagelimit},'branch/branch_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.sendRequest=false;

      this.branch = result['data']['list'];
      this.branch_comp_count = result['data']['branch_comp_count'];
      this.rqst_count = result['data']['count'];
      if(result['data']['branch_comp_count'])
      {
        this.total_complaints = result['data']['branch_comp_count']['total_complaints'];
        this.total_open_complaints = result['data']['branch_comp_count']['total_open_complaints'];
      }
      else
      { 
        this.total_complaints = 0;
        this.total_open_complaints = 0;
      }
     
      this.total_page = Math.ceil(this.rqst_count/this.pagelimit);
      this.pagenumber = (this.start/this.pagelimit)+1;
      this.loader = '';
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');    
      this.loader = '';
    });
  }
  
  GetComplaintList(){
    this.branch = this.branch.filter(x=>x.total_complaint>=1);
  }
  GetOpenComplaintList(){
    this.branch=this.branch.filter(x=>x.total_runn_complaint>=1);
  }
  
 
  
 
  delete(index ,branch_id){
    console.log(index);
    console.log(branch_id);
    this.dialog.delete('Branch!').then((result) => {
      console.log(result);
      if(result)
      {
        this.db.get_rqst('branch/delete_branch/'+branch_id)
        .subscribe(res => {
          console.log(res);
          this.branch.splice(index,1);
          this.toast.openSucess('Branch Deleted Successfully','');     
        },err=>{
          console.log(err);
          this.toast.openError('Something went wrong !!! Try Again...','');
        });
      }
    });
  }
  
}

import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import {Validators,FormGroup,FormBuilder} from '@angular/forms';
import { SnackBarOverview } from 'src/app/Toast';
@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html'
})
export class AddBranchComponent implements OnInit {
  
  addBranchForm: FormGroup;
  submitted = false; //for login button
  
  detail: any = [];
  bch_user:any = {};
  state_list: any=[];
  district_list:any=[];
  city_list:any=[];
  region_list:any = [];
  user:any = [];
  flag:any;
  isDisabled: boolean;
  flag1: any;
  
  constructor(private formBuilder: FormBuilder ,public db:DatabaseService,public router:Router,public toast: SnackBarOverview) {
    this.GetStates();
    this.GetRegion();
  }
  
  ngOnInit() {
    this.addBranchForm = this.formBuilder.group({
      name: ['', Validators.required],
      branch_code:['', Validators.required],
      region_code:['', Validators.required],
      phone: [''],
      street: ['', Validators.required],
      state_name: ['', Validators.required],
      district_name: ['', Validators.required],
      country: ['India'],
      city: [''],
      pincode: ['']
    });
  }
  
  get e() { return this.addBranchForm.controls; }
  
  BranchCode(branch_code: any)
  {
    console.log(branch_code);
    console.log(branch_code.value);
    this.db.FetchData({'branch_code':branch_code.value},'branch/check_branch_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
      {
        this.flag=true;
      }
      else{
        this.flag=false;
      }
      
    },error=>{
      console.log(error);
    });
  }

  Branch_name(name:any)
  {
   console.log(name);
   this.db.FetchData({'name':name},'branch/check_branch_name_existence')
    .subscribe((result:any)=>{
      console.log(result);
      if(result['message']=="Success")
         {
         this.flag1=true;
         }
      else
        {
        this.flag1=false;
        }
      
     },error=>{
      console.log(error);
     });
  }


  validate(event:any)
  {
    const pattern = /^[0-9a-zA-Z]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar))
    {
      event.preventDefault();
    }
  }
  
  charonly(event: any){
    const pattern =/^[a-zA-Z ]+$/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  
  MobileNumber2(event: any)
  {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);if (event.keyCode != 8 && !pattern.test(inputChar))
    {event.preventDefault();}
  }
  
  
  GetRegion()
  {
    this.db.FetchData({},'master/region_list')
    .subscribe((result:any)=>{
      console.log(result);
      this.region_list = result['data'];
      console.log("Region list");
      console.log(this.region_list);
    },error=>{
      console.log(error);
    });
  }
  
  GetStates()
  {
    this.db.FetchData({},'branch/get_states')
    .subscribe((result:any)=>{
      console.log(result);
      this.state_list = result['data'];
      console.log(this.state_list);
    },error=>{
      console.log(error);
    });
  }
  
  GetDistrict(state_name:any)
  {
    console.log(state_name.value);
    this.db.FetchData({'state_name':state_name.value},'branch/get_state_district')
    .subscribe(result =>{
      console.log(result);
      this.district_list = result['data'];
    },error=>{
      console.log(error);
      this.toast.openError('Something went wrong !!! Try Again...','');
      
    });
  }
  
  submitBranch()
  {
    this.submitted = true;
    // stop here if form is invalid
    console.log(this.flag);
    
    if (this.addBranchForm.invalid || this.flag==true) {
      console.log("*****invalid data*****");
      return;
    }
    else
    { //if valid value display in console
      this.isDisabled=true;
      console.log(this.addBranchForm.value);
      this.db.FetchData({'data':this.addBranchForm.value},'branch/save_branch')
      .subscribe((result)=>{
        console.log(result);
        if(result['message']=="Success")
        {
          console.log("Success!");
          this.toast.openSucess('Branch Submitted Succesfully','');
          this.router.navigate(['/branch-list']);
        }
      },error=>{
        console.log("error");
        console.log(error);
        this.toast.openError('Something went wrong !!! Try Again...','');
      });
    }
  }
}
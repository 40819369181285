import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { SnackBarOverview } from 'src/app/Toast';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-dealer-tabs',
  templateUrl: './dealer-tabs.component.html'
})
export class DealerTabsComponent implements OnInit {
  
  dealer_name:string = '';
  detail_data:any=''; 
  encrypt_id:any='';
  // dealer_id:any = '';
  
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview,public route:ActivatedRoute) {
    this.route.params.subscribe(params=>{
      console.log(params);
      this.encrypt_id = params.id;
      console.log(this.encrypt_id);
    });
  }
  
  ngOnInit() {
    if(!this.encrypt_id)
    {
      this.encrypt_id = this.db.dealer_id;
    }
    this.getDealerDetail();
  }
  
  getDealerDetail()
  {
    console.log("testing Phase");
    this.db.FetchData({'dealer_id':this.encrypt_id},'dealer/dealer_detail')
    .subscribe((result)=>{
      console.log(result);

      this.detail_data=result['data']['detail'];
      this.dealer_name=this.detail_data['name'];
      this.db.dealer_id= this.encrypt_id;
      this.db.dealer_name = this.dealer_name;

    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
    });
  }
  
}

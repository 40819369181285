import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/_services/database.service';
import { Router } from '@angular/router';
import { SnackBarOverview } from 'src/app/Toast';

@Component({
  selector: 'app-customer-complaint-list',
  templateUrl: './customer-complaint-list.component.html',
  styleUrls: ['./customer-complaint-list.component.scss']
})
export class CustomerComplaintListComponent implements OnInit {
  loader:any = '';  
  Comp_Data:any=[];
  data:any=[];
  constructor(public db:DatabaseService,public router:Router,public toast: SnackBarOverview) { }

  ngOnInit() {
    this.getComplaintList(this.db.customer_id);
  }

  getComplaintList(customer_id)
  {
    this.loader = '1';
    this.db.FetchData({'customer_id':customer_id},'dealer/get_customer_complaints')
    .subscribe((result: any) => {
      console.log(result);
      this.Comp_Data = result['data']['complaints'];
      console.log(this.Comp_Data);
      
      this.loader = '';
    }, error => {
      console.log(error);
      this.toast.openError('Something went wrong !!! Try again... ','');
      this.loader = '';      
    });
  }


}
